import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { BuyerRetainsOwnership, IntentionWithGoods } from '@tecex-api/data';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { assertNever } from '../../../../helpers/assert-never.helper';
import { InputDataVM } from '../../../../interfaces/input-data.vm';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { BeneficialOwnerDialogPayload } from './beneficial-owner-dialog-payload.interface';
import { BeneficialOwnerStep } from './beneficial-owner-step.enum';
import { StepTextPipe } from './pipes/step-text.pipe';
import { ShowStepsPipe } from './pipes/show-steps.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '../../../tooltip/tooltip.directive';
import { MatButtonModule } from '@angular/material/button';
import { SelectionComponent } from '../../../selection/selection.component';
import { TextareaAutosizeDirective } from '../../../../directives/textarea-autosize.directive';
import { DropdownComponent } from '../../../dropdown/dropdown.component';
import { FormControlInputDirective } from '../../../form-control/directives/form-control-input.directive';
import { SelectComponent } from '../../../select/select.component';
import { FormControlLabelDirective } from '../../../form-control/directives/form-control-label.directive';
import { FormControlComponent } from '../../../form-control/components/form-control/form-control.component';
import { ShipmentSummaryComponent } from '../shipment-summary/shipment-summary.component';
import { NgIf, NgSwitch, NgSwitchCase, AsyncPipe } from '@angular/common';
import { MessageButtonComponent } from '../../../message-button/message-button.component';

@Component({
  selector: 'app-beneficial-owner-dialog',
  templateUrl: './beneficial-owner-dialog.component.html',
  styleUrls: ['./beneficial-owner-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MessageButtonComponent,
    NgIf,
    ShipmentSummaryComponent,
    NgSwitch,
    NgSwitchCase,
    FormControlComponent,
    FormControlLabelDirective,
    SelectComponent,
    FormControlInputDirective,
    FormsModule,
    ReactiveFormsModule,
    DropdownComponent,
    TextareaAutosizeDirective,
    SelectionComponent,
    MatButtonModule,
    TooltipDirective,
    AsyncPipe,
    TranslateModule,
    ShowStepsPipe,
    StepTextPipe,
  ],
})
export class BeneficialOwnerDialogComponent implements OnDestroy {
  public step: BeneficialOwnerStep;

  public readonly BeneficialOwnerStep = BeneficialOwnerStep;
  public readonly IntentionWithGoods = IntentionWithGoods;
  public readonly BuyerRetainsOwnership = BuyerRetainsOwnership;
  public readonly intentionWithGoodsControl = new FormControl<IntentionWithGoods>(undefined, [Validators.required]);
  public readonly companyDetailsForm = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    country: new FormControl<InputDataVM<string, string>>(undefined, [Validators.required]),
    address: new FormControl<string>('', [Validators.required]),
    postalCode: new FormControl<string>('', [Validators.required]),
    city: new FormControl<string>('', [Validators.required]),
  });
  public readonly companyNameControl = this.companyDetailsForm.controls.name as FormControl<string>;
  public readonly companyCountryControl = this.companyDetailsForm.controls.country as FormControl<InputDataVM<string, string>>;
  public readonly companyPostalCodeControl = this.companyDetailsForm.controls.postalCode as FormControl<string>;
  public readonly companyCityControl = this.companyDetailsForm.controls.city as FormControl<string>;
  public readonly companyAddressControl = this.companyDetailsForm.controls.address as FormControl<string>;
  public readonly otherDescriptionControl = new FormControl<string>('', [Validators.required]);
  public readonly vatNumberControl = new FormControl<string>('', [Validators.required]);
  public readonly ownershipControl = new FormControl<BuyerRetainsOwnership>(undefined, [Validators.required]);
  public readonly countries = this.data.payload.countries.map(
    (country): InputDataVM<string, string> => ({ value: country.value, viewValue: country.label })
  );

  private readonly destroyed$ = new Subject<void>();

  constructor(@Inject(DIALOG_DATA) public readonly data: DialogData<BeneficialOwnerDialogPayload>) {
    this.data.payload.step$.pipe(takeUntil(this.destroyed$)).subscribe((step) => (this.step = step));
  }

  public get canProceed(): boolean {
    switch (this.step) {
      case BeneficialOwnerStep.IntetionWithGoods: {
        return this.intentionWithGoodsControl.valid;
      }
      case BeneficialOwnerStep.CompanyDetails: {
        return this.companyDetailsForm.valid;
      }
      case BeneficialOwnerStep.OtherDetails: {
        return this.otherDescriptionControl.valid;
      }
      case BeneficialOwnerStep.VatNumber: {
        return this.vatNumberControl.valid;
      }
      case BeneficialOwnerStep.Ownership: {
        return this.ownershipControl.valid;
      }
      case BeneficialOwnerStep.Confirmation: {
        return true;
      }
      default: {
        return assertNever(this.step);
      }
    }
  }

  public onBackClick(): void {
    this.data.payload.handleBack();
  }

  public onNextClick(): void | Subscription {
    this.data.payload
      .handleNext$({
        intentionWithGoods: this.intentionWithGoodsControl.value,
        companyDetails: {
          name: this.companyNameControl.value,
          country: this.companyCountryControl.value?.value,
          address: this.companyAddressControl.value,
          postalCode: this.companyPostalCodeControl.value,
          city: this.companyCityControl.value,
        },
        otherDetails: this.otherDescriptionControl.value,
        vatNumber: this.vatNumberControl.value,
        ownership: this.ownershipControl.value,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  public onMessageClick(): void {
    this.data.payload.onMsgClickHandler();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
