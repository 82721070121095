import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, Type, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { Subject } from 'rxjs';
import { InputDataVM } from '../../interfaces/input-data.vm';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): Type<SelectComponent> => SelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [MatSelectModule, NgFor, MatOptionModule, SvgIconComponent],
})
export class SelectComponent implements ControlValueAccessor, OnDestroy {
  @ViewChild(MatSelect) public matSelect: MatSelect;
  @Input() public data: InputDataVM[];
  @Input() public placeholder?: string;
  public value: string;

  private controlValueAccessorChangeFn: (value: string) => void;
  private controlValueAccessorTouchFn: () => void;
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public writeValue(value: string): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.controlValueAccessorChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.controlValueAccessorTouchFn = fn;
  }

  public onOpenedChange(): void {
    if (this.controlValueAccessorTouchFn) {
      this.controlValueAccessorTouchFn();
    }
  }

  public onSelectionChange(event: MatSelectChange): void {
    this.value = event.value;

    if (this.controlValueAccessorChangeFn) {
      this.controlValueAccessorChangeFn(this.value);
    }
  }

  public getViewValue(): string | number {
    const selectedItem = this.data.find((item) => item.value === this.value);

    if (selectedItem) {
      return selectedItem.viewValue;
    }

    return void 0;
  }

  public onArrowClick(): void {
    this.matSelect.open();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
