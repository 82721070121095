import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormArray, FormControl } from '@ngneat/reactive-forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { from } from 'rxjs';
import { map, switchMap, toArray } from 'rxjs/operators';
import { LineItemsColumn } from '../../../../enums/line-items-column.enum';
import { BlockComponent } from '../block/block.component';
import { PartSubCategoryTableBlockPayload } from './part-sub-category-table-block-payload.interface';
import { SelectComponent } from '../../../select/select.component';
import { NgFor, AsyncPipe } from '@angular/common';

export const NOT_PROVIDED_CATEGORY = `I don't know`;
export const OTHER_CATEGORY = 'other';

@Component({
  selector: 'app-part-sub-category-table-block',
  templateUrl: './part-sub-category-table-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, SelectComponent, FormsModule, ReactiveFormsModule, AsyncPipe, TranslateModule],
})
export class PartSubCategoryTableBlockComponent extends BlockComponent<PartSubCategoryTableBlockPayload, string[]> implements OnInit {
  public productCategoriesControl = new FormArray([]);

  // tslint:disable no-magic-numbers
  public readonly productCategories$ = from([
    ['DONT_HAVE_INFORMATION', NOT_PROVIDED_CATEGORY],
    ['AUDIO_DEVICES', 'Audio devices'],
    ['CAMERAS', 'Cameras'],
    ['CELLPHONES', 'Cellphones'],
    ['GAMING_AND_VIRTUAL_REALITY', 'Gaming and virtual reality'],
    ['POWER_EQUIPMENT', 'Power equipment'],
    ['PRINTERS_SCANNERS_AND_COPIERS', 'Printers, scanners, copiers'],
    ['RACK_BRACKETS_AND_TOOLS', 'Rack (cabinet only), brackets and tools'],
    ['SERVER_CONTROLLERS_AND_PARTS', 'Servers, controllers and parts'],
    ['STORAGE_DEVICES', 'Storage devices'],
    ['VISUAL_DEVICES', 'Visual devices'],
    ['ROUTERS_SWITCHES_AND_TRANSCEIVERS', 'Routers, switches and transceivers'],
    ['LAPTOPS_AND_PCS', 'Laptops & PCs'],
    ['FIREWALLS', 'Firewalls'],
    ['OTHER', OTHER_CATEGORY],
  ]).pipe(
    switchMap(([translationKey, hsCode]) =>
      this.translateService.get(`TASKS.PART_SUB_CATEGORY_TABLE.PRODUCT_CATEGORIES.${translationKey}`).pipe(
        map((translation) => ({
          value: hsCode,
          viewValue: translation,
        }))
      )
    ),
    toArray()
  );
  // tslint:enable no-magic-numbers

  public readonly LineItemsColumn = LineItemsColumn;

  constructor(private readonly translateService: TranslateService) {
    super();
  }

  public ngOnInit(): void {
    this.payload.lineItems.forEach(() => this.productCategoriesControl.push(new FormControl(undefined, [Validators.required])));
    this.register.next(this.productCategoriesControl);
  }
}
