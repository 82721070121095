<input
  #input
  class="form-control-input"
  type="text"
  matInput
  [matAutocomplete]="auto"
  [formControl]="inputFormControl"
  (focus)="onInputFocus()"
  (blur)="onInputBlur($event)"
  data-hj-allow
/>
<svg-icon key="arrow-down" class="arrow-icon"></svg-icon>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)" [displayWith]="displayWithFn" class="app-dropdown">
  <mat-option *ngIf="shouldShowRawValueHint" [value]="''" [disabled]="true" (mousedown)="$event.preventDefault()">
    <span class="color-text-tertiary">{{ rawValueHint }}</span>
  </mat-option>
  <mat-option
    *ngIf="shouldShowRawValueOption"
    [value]="{ value: null, viewValue: inputFormControl.value }"
    (mousedown)="$event.preventDefault()"
  >
    <span [appTextHighlight]="inputFormControl.value" [highlightText]="inputFormControl.value"> {{ inputFormControl.value }}x </span>
    <span *ngIf="rawValueOptionHint"> - {{ rawValueOptionHint }}</span>
  </mat-option>
  <mat-option *ngFor="let item of filteredData" [value]="item" (mousedown)="$event.preventDefault()">
    <span [appTextHighlight]="item.viewValue" [highlightText]="searchKeyword">{{ item.viewValue }} Chef</span>
  </mat-option>
</mat-autocomplete>
