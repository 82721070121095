import { ActiveInactive, UpdateAddress } from '@tecex-api/data';
import { AddressCardAddressVM } from '../../../interfaces/address/address.vm';

export const mapAddressUpdatePayload = (address: AddressCardAddressVM): UpdateAddress => ({
  ID: address.id,
  DefaultAddress: address.isDefault,
  CompanyName: address.companyName,
  City: address.city,
  All_Countries: address.country,
  Name: address.tag,
  Contact_Email: address.email,
  Contact_Full_Name: address.contactPerson,
  Province: address.state,
  AddressLine1: address.streetAddress,
  AddressLine2: address.streetAddressTwo,
  AddressLine3: address.streetAddressThree,
  Postal_Code: address.zip,
  Contact_Phone_Number: address.phone,
  AdditionalNumber: address.additionalPhone,
  AddressStatus: address.isActive ? ActiveInactive.ACTIVE : ActiveInactive.INACTIVE,
  PickupPreference: address.pickupPreference,
});
