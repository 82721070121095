import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { bytesPerMB } from '../../../../constants/global.constants';
import { BlockComponent } from '../block/block.component';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileInputComponent } from '../../../file-input/file-input.component';
import { NgIf, NgFor, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
  selector: 'app-upload-block',
  templateUrl: './upload-block.component.html',
  styleUrls: ['./upload-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FileInputComponent,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    MatButtonModule,
    SvgIconComponent,
    TranslateModule,
  ],
})
export class UploadBlockComponent extends BlockComponent<{ text: string }, File | File[]> implements OnInit, OnDestroy {
  public readonly formControl = new FormControl<File | File[]>();
  public readonly maxFileSizeInMb = 4;

  public invalidFiles: { fileName: string; reason: string }[] = [];

  private attachedFiles: File[] = [];
  private readonly destroyed$ = new Subject<void>();

  public get files(): File[] {
    if (isNil(this.formControl.value)) {
      return [];
    }

    if (Array.isArray(this.formControl.value)) {
      return this.formControl.value;
    }

    return [this.formControl.value];
  }

  public ngOnInit(): void {
    this.register.next(this.formControl);

    // allows to upload files subsequently in multiple batches
    this.formControl.valueChanges
      .pipe(
        filter((value) => !isEmpty(value) && value !== this.attachedFiles),
        takeUntil(this.destroyed$)
      )
      .subscribe((value) => {
        const files = Array.isArray(value) ? value : [value];
        const filesExceededSizeLimit = files.filter((file) => file.size >= this.maxFileSizeInMb * bytesPerMB);
        const validFiles = files.filter((file) => file.size < this.maxFileSizeInMb * bytesPerMB);
        this.attachedFiles = this.attachedFiles.concat(validFiles);
        this.formControl.setValue(this.attachedFiles, { emitEvent: false });

        if (filesExceededSizeLimit.length > 0) {
          this.invalidFiles = this.invalidFiles.concat(
            filesExceededSizeLimit.map((file) => ({ fileName: file.name, reason: 'fileSizeLimit' }))
          );
          this.formControl.setErrors({ fileSizeLimitExceeded: true });
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onRemoveFileIconClick(fileToRemove: File): void {
    this.attachedFiles = this.files.filter((file) => file !== fileToRemove);
    this.formControl.setValue(this.attachedFiles);
  }

  public removeInvalidFileClick(fileName: string): void {
    this.invalidFiles = this.invalidFiles.filter((f) => f.fileName !== fileName);
    if (this.invalidFiles.length > 0) {
      this.formControl.setErrors({ fileSizeLimitExceeded: true });
    } else {
      this.formControl.removeError('fileSizeLimitExceeded');
    }
  }
}
