import { Country } from '@tecex-api/data';
import { InputDataVM } from '../interfaces/input-data.vm';

export class CountryHelper {
  public static mapToInputDataVM(countries: Country[]): InputDataVM<string, string>[] {
    if (!countries) {
      return [];
    }

    return countries.map(({ value, label }) => ({
      value,
      viewValue: label,
    }));
  }
}
