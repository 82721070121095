import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { ChargeableWeightDialogResult } from '../../../../interfaces/chargeable-weight-dialog.vm';
import { PackageGroupsComponent } from '../../../packages/components/package-groups/package-groups.component';
import { PackagesBlockPayload } from '../../types/packages-block.payload.interface';
import { BlockComponent } from '../block/block.component';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-packages-block',
  templateUrl: './packages-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PackageGroupsComponent, MatButtonModule, SvgIconComponent, TranslateModule],
})
export class PackagesBlockComponent extends BlockComponent<PackagesBlockPayload, any> implements OnInit {
  @ViewChild('packageGroups') public packageGroups: PackageGroupsComponent;

  private isValid = false;
  private formControl: FormControl<ChargeableWeightDialogResult>;

  public ngOnInit(): void {
    this.formControl = new FormControl({
      weightUnit: this.payload.weightUnit,
      lengthUnit: this.payload.lengthUnit,
      packages: [],
    });

    this.register.next(this.formControl);
  }

  public onValidityChange(valid: boolean): void {
    this.isValid = valid;
    this.updateValidity();
  }

  public onValueChange(value: ChargeableWeightDialogResult): void {
    this.formControl.setValue(value);
    this.updateValidity();
  }

  public onAddPackageClick(): void {
    this.packageGroups.addPackage();
  }

  private updateValidity() {
    // tslint:disable-next-line: no-null-keyword
    this.formControl.setErrors(this.isValid ? null : { required: true });
  }
}
