import { QuoteDataService } from '@tecex-api/data';
import { GetPartsDetailsResponse } from '@tecex-api/data/model/models';
import { Observable } from 'rxjs';
import { LineItem } from '../../common-quote/interfaces/line-item.interface';
import { LineItemsData } from '../../common-quote/interfaces/line-items-data.interface';

export abstract class BaseLineItemsDataService {
  protected readonly POLL_INTERVAL = 2000;
  protected readonly POLL_TIMEOUT = 150; // seconds / 2
  public abstract saveNewLineItems$(data: LineItemsData): Observable<LineItem[] | string>;
  public abstract editLineItems$(data: LineItemsData): Observable<LineItem[]>;
  public abstract getLineItems$(SOID: string, accessToken: string): Observable<LineItem[]>;

  constructor(protected readonly quoteDataService: QuoteDataService) {}

  protected _getLineItems$(SOID: string, accessToken: string): Observable<GetPartsDetailsResponse> {
    return this.quoteDataService.getPartsDetails({
      SOID,
      Accesstoken: accessToken,
    });
  }
}
