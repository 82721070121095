import { Pipe, PipeTransform } from '@angular/core';
import { BeneficialOwnerStep } from '../beneficial-owner-step.enum';

@Pipe({
  name: 'appStepText',
  standalone: true,
})
export class StepTextPipe implements PipeTransform {
  // @ts-ignore
  public transform(value: BeneficialOwnerStep): string {
    switch (value) {
      case BeneficialOwnerStep.OtherDetails: {
        return this.getStepText(2, 2);
      }
      case BeneficialOwnerStep.CompanyDetails: {
        return this.getStepText(2, 3);
      }
      case BeneficialOwnerStep.Ownership:
      case BeneficialOwnerStep.VatNumber: {
        return this.getStepText(3, 3);
      }
    }
  }

  private getStepText(currentStep: number, allStepsCount: number) {
    return `<b>${currentStep}</b> of ${allStepsCount} tasks`;
  }
}
