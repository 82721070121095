import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, Optional, Type } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { of } from 'rxjs';
import { FormControlService } from '../form-control/components/form-control/form-control.service';
import { SelectionItemVM } from './selection-item.vm';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgFor, AsyncPipe } from '@angular/common';

type Item = SelectionItemVM | string;

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): Type<SelectionComponent> => SelectionComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgFor, SvgIconComponent, AsyncPipe],
})
export class SelectionComponent implements ControlValueAccessor {
  private controlValueAccessorChangeFn: (value: unknown) => void;
  private controlValueAccessorTouchFn: () => void;
  public readonly hasError$ = this.formControlService?.hasError$ || of(false);
  public selectedItem: Item | undefined;
  public isDisabled = false;

  // tslint:disable-next-line:variable-name
  private _items: Item[];
  @Input() public set items(items: Item[]) {
    this._items = items;

    if (this.selectedItem) {
      this.selectedItem = items.find((item) => this.getValue(item) === this.getValue(this.selectedItem));
    }
  }
  public get items(): Item[] {
    return this._items;
  }

  @Input() public isHighlighted = false;

  constructor(
    @Optional() private readonly formControlService: FormControlService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public writeValue(value: unknown): void {
    this.selectedItem = this.items.find((item) => this.getValue(item) === value);
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: unknown) => void): void {
    this.controlValueAccessorChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.controlValueAccessorTouchFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cdr.markForCheck();
  }

  public onItemClick(item: Item): void {
    if (this.isDisabled) {
      return;
    }

    if (this.selectedItem === item) {
      return;
    }

    this.selectedItem = item;

    if (this.controlValueAccessorChangeFn) {
      this.controlValueAccessorChangeFn(this.getValue(this.selectedItem));
    }

    if (this.controlValueAccessorTouchFn) {
      this.controlValueAccessorTouchFn();
    }
  }

  public getValue(item: Item): string | unknown {
    if (typeof item === 'string') {
      return item;
    }

    return item.value;
  }

  public getLabel(item: Item): string | unknown {
    if (typeof item === 'string') {
      return item;
    }

    return item.viewValue || item.value;
  }
}
