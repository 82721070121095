import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ShipmentStatus } from '../../enums/shipment-status.enum';
import { TaskState } from '../../enums/task-state.enum';
import { ShipmentTaskStates } from '../../interfaces/shipment-task-states.vm';
import { StateIndicatorCircleState } from '../state-indicator-circle/state.enum';
import { TooltipColor } from '../tooltip/tooltip-color.type';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '../tooltip/tooltip.directive';
import { StateIndicatorCircleComponent } from '../state-indicator-circle/state-indicator-circle.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-shipment-state-indicator-circles',
  templateUrl: './shipment-state-indicator-circles.component.html',
  styleUrls: ['./shipment-state-indicator-circles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, StateIndicatorCircleComponent, TooltipDirective, NgIf, TranslateModule],
})
export class ShipmentStateIndicatorCirclesComponent implements OnInit, OnChanges {
  @Input() public shipmentTaskStates: ShipmentTaskStates;
  @Input() public dotSize = 12;
  @Input() public hasTitle = false;
  @Input() public isCancelled = false;
  public readonly ShipmentStatus = ShipmentStatus;
  public readonly StateIndicatorCircleState = StateIndicatorCircleState;

  public shiptmentTasks: {
    title: string;
    color: StateIndicatorCircleState;
    status: string;
    tooltipColor: string;
  }[];

  public ngOnInit(): void {
    this.shiptmentTasks = this.mapTasks();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.shipmentTaskStates) {
      this.shiptmentTasks = this.mapTasks();
    }
  }
  private mapTasks() {
    if (!this.shipmentTaskStates) {
      return [];
    }

    return [
      {
        title: 'SHIPMENTS.SHIPMENTS_LIST.CUSTOMS_COMPLIANCE',
        color: this.mapColor(this.shipmentTaskStates.customsCompliance),
        tooltipColor: this.mapTooltipColor(this.shipmentTaskStates.customsCompliance),
        status: this.mapStatus(this.shipmentTaskStates.customsCompliance),
      },
      {
        title: 'SHIPMENTS.SHIPMENTS_LIST.SHIPPING_DOCUMENTS',
        color: this.mapColor(this.shipmentTaskStates.shippingDocuments),
        tooltipColor: this.mapTooltipColor(this.shipmentTaskStates.shippingDocuments),
        status: this.mapStatus(this.shipmentTaskStates.shippingDocuments),
      },
      {
        title: 'SHIPMENTS.SHIPMENTS_LIST.PICK_UP_COORDINATION',
        color: this.mapColor(this.shipmentTaskStates.pickUpCoordination),
        tooltipColor: this.mapTooltipColor(this.shipmentTaskStates.pickUpCoordination),
        status: this.mapStatus(this.shipmentTaskStates.pickUpCoordination),
      },
      {
        title: 'SHIPMENTS.SHIPMENTS_LIST.INVOICE_PAYMENT',
        color: this.mapColor(this.shipmentTaskStates.invoicePayment),
        tooltipColor: this.mapTooltipColor(this.shipmentTaskStates.invoicePayment),
        status: this.mapStatus(this.shipmentTaskStates.invoicePayment),
      },
    ];
  }

  private mapColor(taskState: TaskState): StateIndicatorCircleState {
    switch (taskState) {
      case TaskState.Done: {
        return StateIndicatorCircleState.Green;
      }
      case TaskState.Inprogress: {
        return StateIndicatorCircleState.Yellow;
      }
      case TaskState.Blocked: {
        return StateIndicatorCircleState.Red;
      }
    }
  }

  private mapStatus(taskState: TaskState): string {
    switch (taskState) {
      case TaskState.Done: {
        return 'COMMON.APPROVED';
      }
      case TaskState.Inprogress: {
        return 'COMMON.TECEX_IN_PROGRESS';
      }
      case TaskState.Blocked: {
        return 'COMMON.BLOCKING';
      }
    }
  }

  private mapTooltipColor(taskState: TaskState): TooltipColor {
    switch (taskState) {
      case TaskState.Done: {
        return 'green';
      }
      case TaskState.Inprogress: {
        return 'yellow';
      }
      case TaskState.Blocked: {
        return 'red';
      }
    }
  }
}
