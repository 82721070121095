import {addIcon} from "./app/svg/add";
import {addressIcon} from "./app/svg/address";
import {allDoneIcon} from "./app/svg/all-done";
import {arrowDownFilledIcon} from "./app/svg/arrow-down-filled";
import {arrowDownIcon} from "./app/svg/arrow-down";
import {arrowLeftIcon} from "./app/svg/arrow-left";
import {arrowRightIcon} from "./app/svg/arrow-right";
import {arrowUpIcon} from "./app/svg/arrow-up";
import {assignedToIcon} from "./app/svg/assigned-to";
import {attachmentIcon} from "./app/svg/attachment";
import {bagIcon} from "./app/svg/bag";
import {bgPatternIcon} from "./app/svg/bg-pattern";
import {binIcon} from "./app/svg/bin";
import {borderGuardIcon} from "./app/svg/border-guard";
import {bulbIcon} from "./app/svg/bulb";
import {calculateCostIcon} from "./app/svg/calculate-cost";
import {calculatingIcon} from "./app/svg/calculating";
import {cardIcon} from "./app/svg/card";
import {chatIcon} from "./app/svg/chat";
import {checkIcon} from "./app/svg/check";
import {clockAndPlaneIcon} from "./app/svg/clock-and-plane";
import {closeIcon} from "./app/svg/close";
import {companyIcon} from "./app/svg/company";
import {consigneesIcon} from "./app/svg/consignees";
import {copyIcon} from "./app/svg/copy";
import {currencyIcon} from "./app/svg/currency";
import {dashboardIcon} from "./app/svg/dashboard";
import {dateIcon} from "./app/svg/date";
import {detailedNotesIcon} from "./app/svg/detailed-notes";
import {docIcon} from "./app/svg/doc";
import {dotIcon} from "./app/svg/dot";
import {downloadIcon} from "./app/svg/download";
import {dragAndDropIcon} from "./app/svg/drag-and-drop";
import {emailIcon} from "./app/svg/email";
import {enlargeIcon} from "./app/svg/enlarge";
import {excelIcon} from "./app/svg/excel";
import {exclamationMarkIcon} from "./app/svg/exclamation-mark";
import {fastProcessIcon} from "./app/svg/fast-process";
import {filterIcon} from "./app/svg/filter";
import {flagIcon} from "./app/svg/flag";
import {freightIcon} from "./app/svg/freight";
import {globeIcon} from "./app/svg/globe";
import {idIcon} from "./app/svg/id";
import {infiniteIcon} from "./app/svg/infinite";
import {infoIcon} from "./app/svg/info";
import {insightsIcon} from "./app/svg/insights";
import {invoiceIcon} from "./app/svg/invoice";
import {lineItemsIcon} from "./app/svg/line-items";
import {listIcon} from "./app/svg/list";
import {locationIcon} from "./app/svg/location";
import {logOutIcon} from "./app/svg/log-out";
import {logoIcon} from "./app/svg/logo";
import {mailIcon} from "./app/svg/mail";
import {menuIcon} from "./app/svg/menu";
import {messagesIcon} from "./app/svg/messages";
import {muteIcon} from "./app/svg/mute";
import {navigationIcon} from "./app/svg/navigation";
import {notesIcon} from "./app/svg/notes";
import {numberIcon} from "./app/svg/number";
import {packageBreadthIcon} from "./app/svg/package-breadth";
import {packageHeightIcon} from "./app/svg/package-height";
import {packageSizeIcon} from "./app/svg/package-size";
import {packageIcon} from "./app/svg/package";
import {packagesNumberIcon} from "./app/svg/packages-number";
import {penIcon} from "./app/svg/pen";
import {pen2Icon} from "./app/svg/pen2";
import {pendingIcon} from "./app/svg/pending";
import {phoneIcon} from "./app/svg/phone";
import {planeLandingIcon} from "./app/svg/plane-landing";
import {planeTakeOffIcon} from "./app/svg/plane-take-off";
import {preferencesIcon} from "./app/svg/preferences";
import {productCatalogIcon} from "./app/svg/product-catalog";
import {profileIcon} from "./app/svg/profile";
import {questionIcon} from "./app/svg/question";
import {quoteDetailsIcon} from "./app/svg/quote-details";
import {quoteNewIcon} from "./app/svg/quote-new";
import {quotesIcon} from "./app/svg/quotes";
import {referenceNumberIcon} from "./app/svg/reference-number";
import {searchIcon} from "./app/svg/search";
import {secondHandGoodsIcon} from "./app/svg/second-hand-goods";
import {send2Icon} from "./app/svg/send2";
import {serviceTypeIcon} from "./app/svg/service-type";
import {settingsIcon} from "./app/svg/settings";
import {shipmentsPendingIcon} from "./app/svg/shipments-pending";
import {shippingIcon} from "./app/svg/shipping";
import {storeFeeIcon} from "./app/svg/store-fee";
import {successAndPlaneIcon} from "./app/svg/success-and-plane";
import {tableHeaderArrowIcon} from "./app/svg/table-header-arrow";
import {targetIcon} from "./app/svg/target";
import {taskIcon} from "./app/svg/task";
import {task2Icon} from "./app/svg/task2";
import {teamIcon} from "./app/svg/team";
import {timeIcon} from "./app/svg/time";
import {timerIcon} from "./app/svg/timer";
import {trackingIcon} from "./app/svg/tracking";
import {uploadBigIcon} from "./app/svg/upload-big";
import {uploadIcon} from "./app/svg/upload";
import {valueIcon} from "./app/svg/value";
import {waitingIcon} from "./app/svg/waiting";
import {weightIcon} from "./app/svg/weight";
import {referralProgramIcon} from "./app/svg/referral-program";
import {calculatorIcon} from "./app/svg/calculator";

export const icons = [
  addIcon, addressIcon, allDoneIcon, arrowDownFilledIcon, arrowDownIcon, arrowLeftIcon, arrowRightIcon, arrowUpIcon, assignedToIcon, attachmentIcon, attachmentIcon, bagIcon, bgPatternIcon, binIcon, borderGuardIcon, bulbIcon, calculateCostIcon, calculatingIcon, calculatorIcon, cardIcon, chatIcon, checkIcon, clockAndPlaneIcon, closeIcon, companyIcon, consigneesIcon, copyIcon, currencyIcon, dashboardIcon, dateIcon, detailedNotesIcon, docIcon, dotIcon, downloadIcon, dragAndDropIcon, emailIcon, enlargeIcon, excelIcon, exclamationMarkIcon, fastProcessIcon, filterIcon, flagIcon, freightIcon, globeIcon, idIcon, infiniteIcon, infoIcon, insightsIcon, invoiceIcon, lineItemsIcon, listIcon, locationIcon, logOutIcon, logoIcon, mailIcon, menuIcon, messagesIcon, muteIcon, navigationIcon, notesIcon, numberIcon, packageBreadthIcon, packageHeightIcon, packageSizeIcon, packageIcon, packagesNumberIcon, penIcon, pen2Icon, pendingIcon, phoneIcon, planeLandingIcon, planeTakeOffIcon, preferencesIcon, productCatalogIcon, profileIcon, questionIcon, quoteDetailsIcon, quoteNewIcon, quotesIcon, referenceNumberIcon, searchIcon, secondHandGoodsIcon, send2Icon, serviceTypeIcon, settingsIcon, shipmentsPendingIcon, shippingIcon, storeFeeIcon, successAndPlaneIcon, tableHeaderArrowIcon, targetIcon, taskIcon, task2Icon, teamIcon, timeIcon, timerIcon, trackingIcon, uploadBigIcon, uploadIcon, valueIcon, waitingIcon, weightIcon, referralProgramIcon
]
