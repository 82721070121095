import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import isEmpty from 'lodash/isEmpty';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InputDataVM } from '../../interfaces/input-data.vm';
import { TextHighlightDirective } from '../../directives/text-highlight.directive';
import { MatOptionModule } from '@angular/material/core';
import { NgIf, NgFor } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): Type<DropdownComponent> => DropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    NgIf,
    MatOptionModule,
    TextHighlightDirective,
    NgFor,
    MatInputModule,
  ],
})
export class DropdownComponent implements ControlValueAccessor, OnChanges, OnInit, AfterViewInit {
  protected controlValueAccessorChangeFn: (value: InputDataVM) => void;
  protected controlValueAccessorTouchFn: () => void;
  protected readonly destroy$ = new Subject<void>();
  protected selectedOption: InputDataVM;

  @Input() public data: InputDataVM<string, string>[];
  @Input() public allowRawValue = false;
  @Input() public rawValueHint?: string;
  @Input() public rawValueOptionHint?: string;
  @Output() public readonly focusInput = new EventEmitter<void>();
  @Output() public readonly blurInput = new EventEmitter<void>();
  @ViewChild('input') public input: ElementRef<HTMLInputElement>;

  public filteredData: InputDataVM<string, string>[] = [];
  public readonly inputFormControl = new UntypedFormControl();
  public searchKeyword: string;

  constructor(protected readonly cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.updateFilteredData();
    }
  }

  public ngOnInit(): void {
    this.updateFilteredData();

    this.inputFormControl.valueChanges
      .pipe(
        filter((value) => typeof value === 'string'),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.searchKeyword = value;
        this.updateFilteredData();
      });

    this.inputFormControl.valueChanges
      .pipe(
        filter((value) => typeof value !== 'string'),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.inputFormControl.setValue(value.viewValue);
        this.resetNativeInputValue();
      });
  }

  public ngAfterViewInit(): void {
    this.resetNativeInputValue();
  }

  public get shouldShowRawValueHint(): boolean {
    return this.allowRawValue && !isEmpty(this.rawValueHint) && isEmpty(this.inputFormControl.value);
  }

  public get shouldShowRawValueOption(): boolean {
    return (
      this.allowRawValue &&
      !isEmpty(this.inputFormControl.value) &&
      this.data?.every((option) => option.viewValue !== this.inputFormControl.value)
    );
  }

  public writeValue(value: InputDataVM): void {
    this.inputFormControl.setValue(value?.viewValue || '', { emitEvent: false });
    this.selectedOption = value;

    if (value) {
      this.resetNativeInputValue();
    }

    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: InputDataVM) => void): void {
    this.controlValueAccessorChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.controlValueAccessorTouchFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputFormControl.disable();
    } else {
      this.inputFormControl.enable();
    }
  }

  public onSelectionChange(event: MatAutocompleteSelectedEvent): void {
    this.selectedOption = event.option.value;
    this.searchKeyword = '';

    if (this.controlValueAccessorChangeFn) {
      this.controlValueAccessorChangeFn(event.option.value);
    }
  }

  public onInputFocus(): void {
    this.searchKeyword = '';
    this.updateFilteredData();
    this.focusInput.next();
  }

  // tslint:disable-next-line: cyclomatic-complexity
  public onInputBlur(event: FocusEvent): void {
    this.blurInput.next();

    if (this.controlValueAccessorTouchFn) {
      this.controlValueAccessorTouchFn();
    }

    if ((event.relatedTarget as HTMLElement)?.tagName === 'MAT-OPTION') {
      return;
    }

    const value = this.inputFormControl.value;

    if (this.selectedOption?.viewValue === value) {
      return;
    }

    const option = this.data.find((item) => item.viewValue === value);
    if (option) {
      this.controlValueAccessorChangeFn(option);

      return;
    }

    if (isEmpty(value)) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      this.controlValueAccessorChangeFn(undefined);

      return;
    }

    if (this.allowRawValue) {
      this.controlValueAccessorChangeFn({
        // tslint:disable-next-line: no-null-keyword
        value: null,
        viewValue: value,
      });

      return;
    }

    this.inputFormControl.setValue(this.selectedOption?.viewValue || '');
    this.resetNativeInputValue();
    this.updateFilteredData();
  }

  public displayWithFn(option: InputDataVM<string, string>): string {
    return option?.viewValue;
  }

  protected updateFilteredData(): void {
    const keyword = this.searchKeyword?.toLocaleLowerCase();

    if (!keyword) {
      this.filteredData = this.data;
      return;
    }

    if (keyword === ' ') {
      this.filteredData = [];
      return;
    }

    this.filteredData = this.data.filter((item) => item.viewValue.toString().toLocaleLowerCase().includes(keyword));
  }

  protected resetNativeInputValue(): void {
    if (!this.input) {
      return;
    }

    Promise.resolve().then(() => {
      this.input.nativeElement.value =
        !this.selectedOption || !this.selectedOption.viewValue ? '' : this.selectedOption.viewValue.toString();
    });
  }
}
