import { ValidatorFn } from '@angular/forms';

import AbstractControl from '@ngneat/reactive-forms';
import isNil from 'lodash/isNil';

export class CustomValidators {
  public static conditionalValidator(predicate: (control: typeof AbstractControl) => boolean, validator: ValidatorFn): ValidatorFn {
    return (control) => {
      if (isNil(control.parent)) {
        return null;
      }
      // @ts-ignore
      if (predicate(control as AbstractControl)) {
        return validator(control);
      }
      return null;
    };
  }
}
