import { Case as CaseResponse, GetFilteredCasesRequestFilterTabNameEnum, RecordType } from '@tecex-api/data';
import { MessageThreadCategory } from '../enums/message-thread-category.enum';
import { MessageThreadType } from '../enums/message-thread-type.enum';
import { MessageThread } from '../interfaces/messages/message-thread.interface';
import { mapIsUnread, mapLastMessage } from './messages/map-message.helper';

export const mapCase = (response: CaseResponse, profilePictureFallback: string): MessageThread => ({
  id: response.Id,
  type: MessageThreadType.Case,
  category: mapCategory(response),
  subject: response.subject,
  lastMessage: mapLastMessage(
    response.CreatedDate,
    response.LatestClientCommentedtime,
    response.LatestInternalCommentedtime
  )?.toISOString(),
  isUnread: mapIsUnread(response.LatestClientCaseViewedTime, response.LatestInternalCommentedtime),
  participants:
    response.Participants?.map((participant) => ({
      id: participant.ID,
      firstName: participant.Firstname,
      lastName: participant.Lastname,
    })) || [],
  shipment: response.NCPQuoteReference
    ? {
        id: response.SOID,
        title: response.NCPQuoteReference,
        reference: response.ClientReference,
      }
    : undefined,
  photoUrl: response.FullphotoURL || profilePictureFallback,
});

const mapCategory = (response: CaseResponse): MessageThreadCategory => {
  if (response.Status === 'Closed') {
    return MessageThreadCategory.Archived;
  }

  if (response.NCPQuoteReference) {
    const isQuote = [RecordType.COST_ESTIMATE, RecordType.ZEE_COST_ESTIMATE].includes(response.RecordTypename);

    return isQuote ? MessageThreadCategory.Quote : MessageThreadCategory.LiveShipment;
  }

  return MessageThreadCategory.GenericSupport;
};

export const mapCaseByTab = (
  response: CaseResponse,
  profilePictureFallback: string,
  tabName?: GetFilteredCasesRequestFilterTabNameEnum
): MessageThread => ({
  id: response.Id,
  type: MessageThreadType.Case,
  category: mapCategoryByTab(tabName),
  subject: response.subject,
  lastMessage: mapLastMessage(
    response.CreatedDate,
    response.LatestClientCommentedtime,
    response.LatestInternalCommentedtime
  )?.toISOString(),
  isUnread: mapIsUnread(response.LatestClientCaseViewedTime, response.LatestInternalCommentedtime),
  participants:
    response.Participants?.map((participant) => ({
      id: participant.ID,
      firstName: participant.Firstname,
      lastName: participant.Lastname,
    })) || [],
  shipment: response.NCPQuoteReference
    ? {
        id: response.SOID,
        title: response.NCPQuoteReference,
        reference: response.ClientReference,
      }
    : undefined,
  photoUrl: response.FullphotoURL || profilePictureFallback,
});

const mapCategoryByTab = (tabName?: GetFilteredCasesRequestFilterTabNameEnum): MessageThreadCategory => {
  switch (tabName) {
    case GetFilteredCasesRequestFilterTabNameEnum.QUOTES: {
      return MessageThreadCategory.Quote;
    }
    case GetFilteredCasesRequestFilterTabNameEnum.LIVE_SHIPMENTS: {
      return MessageThreadCategory.LiveShipment;
    }
    case GetFilteredCasesRequestFilterTabNameEnum.ARCHIVED: {
      return MessageThreadCategory.Archived;
    }
    default: {
      return MessageThreadCategory.GenericSupport;
    }
  }
};
