import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { PickupPreference } from '@tecex-api/data';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AddressType } from '../../../../enums/address-type.enum';
import { EditAddressDialogResultType } from '../../../../enums/edit-address-dialog-result-type.enum';
import { constructInputData } from '../../../../helpers/construct-input-data.helper';
import { AddressCardAddressVM } from '../../../../interfaces/address/address.vm';
import { EditAddressDialogResult } from '../../../../interfaces/address/edit-address-dialog-result.type';
import { InputDataVM } from '../../../../interfaces/input-data.vm';
import { ErrorNotificationService } from '../../../../services/error-notification.service';
import { ValidatorHelperService } from '../../../../services/validator-helper.service';
import { CustomValidators } from '../../../../validators/custom.validators';
import { emailWithTldValidator } from '../../../../validators/email-with-tld.validator';
import { phoneNumberValidator } from '../../../../validators/phone-number.validator';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { ToastMessageType } from '../../../toast-message/toast-message-type.enum';
import { ToastMessageService } from '../../../toast-message/toast-message.service';
import { EditAddressDialogActionType } from '../../enums/edit-address-dialog-action-type.enum';
import { mapGooglePlaceResult } from '../../helpers/map-google-place-result.helper';
import { CommonAddressConfig } from '../../interfaces/common-address-config.interface';
import { EditAddressDialogPayload } from '../../interfaces/edit-address-dialog-payload.type';
import { PickupPreferenceTranslationKeyPipe } from '../../pipes/pickup-preference-translation-key.pipe';
import { COMMON_ADDRESS_CONFIG } from '../../tokens/common-address-config.token';
import { PhoneMaskDirective } from '../../../../directives/phone-mask.directive';
import { DropdownComponent } from '../../../dropdown/dropdown.component';
import { GoogleMapAutocompleteDirective } from '../../../google-map-autocomplete/google-map-autocomplete.directive';
import { SelectComponent } from '../../../select/select.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormControlHintDirective } from '../../../form-control/directives/form-control-hint.directive';
import { FormControlInputDirective } from '../../../form-control/directives/form-control-input.directive';
import { FormControlIconDirective } from '../../../form-control/directives/form-control-icon.directive';
import { FormControlLabelDirective } from '../../../form-control/directives/form-control-label.directive';
import { FormControlComponent } from '../../../form-control/components/form-control/form-control.component';
import { LoadingIndicatorComponent } from '../../../loading-indicator/components/loading-indicator/loading-indicator.component';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { mapGoogleMapsAddress } from '../../helpers/map-google-maps-address.helper';

interface Form extends Omit<AddressCardAddressVM, 'country' | 'pickupPreference'> {
  country: InputDataVM<string, string>;
  pickupPreference: PickupPreference;
}

const MAX_LENGTH = 35;

@Component({
  selector: 'app-edit-address-dialog',
  templateUrl: './edit-address-dialog.component.html',
  styleUrls: ['./edit-address-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PickupPreferenceTranslationKeyPipe],
  standalone: true,
  imports: [
    MatButtonModule,
    SvgIconComponent,
    NgIf,
    LoadingIndicatorComponent,
    FormControlComponent,
    FormControlLabelDirective,
    FormControlIconDirective,
    FormsModule,
    FormControlInputDirective,
    ReactiveFormsModule,
    FormControlHintDirective,
    MatCheckboxModule,
    SelectComponent,
    NgClass,
    GoogleMapAutocompleteDirective,
    DropdownComponent,
    PhoneMaskDirective,
    AsyncPipe,
    TranslateModule,
  ],
})
export class EditAddressDialogComponent implements OnDestroy {
  public readonly PickupPreference = PickupPreference;
  public readonly countries = this.data.payload.countries;
  public isInEdit = false;
  public isLoading = false;

  public readonly showPickupPreference =
    this.commonAddressConfig.hasPickupPreference && this.data.payload.addressType === AddressType.Pickup;

  public readonly addressControl = this.formBuilder.group({
    tag: this.formBuilder.control('', [Validators.required]),
    companyName: this.formBuilder.control('', [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    pickupPreference: this.formBuilder.control(undefined, [
      CustomValidators.conditionalValidator(() => this.showPickupPreference, Validators.required),
    ]),
    isDefault: this.formBuilder.control(false),
    streetAddress: this.formBuilder.control('', [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    streetAddressTwo: this.formBuilder.control('', [Validators.maxLength(MAX_LENGTH)]),
    streetAddressThree: this.formBuilder.control('', [Validators.maxLength(MAX_LENGTH)]),
    city: this.formBuilder.control('', [Validators.required]),
    state: this.formBuilder.control(''),
    country: this.formBuilder.control(undefined, [Validators.required]),
    zip: this.formBuilder.control(undefined, [Validators.required]),
    contactPerson: this.formBuilder.control('', [Validators.required, Validators.maxLength(MAX_LENGTH)]),
    email: this.formBuilder.control('', [Validators.required, emailWithTldValidator]),
    phone: this.formBuilder.control('', [Validators.required, phoneNumberValidator()]),
    additionalPhone: this.formBuilder.control('', [phoneNumberValidator()]),
    comment: this.formBuilder.control(''),
    isActive: this.formBuilder.control(true),
  });

  public readonly pickupPreferenceData = [
    {
      value: PickupPreference.TECEX_ARRANGES_PICKUP,
      viewValue: this.translateService.instant(this.pickupPreferenceTranslationKeyPipe.transform(PickupPreference.TECEX_ARRANGES_PICKUP)),
    },
    {
      value: PickupPreference.CLIENT_ARRANGES_PICKUP,
      viewValue: this.translateService.instant(this.pickupPreferenceTranslationKeyPipe.transform(PickupPreference.CLIENT_ARRANGES_PICKUP)),
    },
    {
      value: PickupPreference.DEDICATED_PICKUP___FED_EX,
      viewValue: this.translateService.instant(
        this.pickupPreferenceTranslationKeyPipe.transform(PickupPreference.DEDICATED_PICKUP___FED_EX)
      ),
    },
    {
      value: PickupPreference.DEDICATED_PICKUP___DHL,
      viewValue: this.translateService.instant(this.pickupPreferenceTranslationKeyPipe.transform(PickupPreference.DEDICATED_PICKUP___DHL)),
    },
  ];

  public readonly tagControl = this.addressControl.controls.tag as UntypedFormControl;
  public readonly pickupPreferenceControl = this.addressControl.controls.pickupPreference as UntypedFormControl;
  public readonly companyNameControl = this.addressControl.controls.companyName as UntypedFormControl;
  public readonly isDefaultControl = this.addressControl.controls.isDefault as UntypedFormControl;
  public readonly streetAddressControl = this.addressControl.controls.streetAddress as UntypedFormControl;
  public readonly streetAddressTwoControl = this.addressControl.controls.streetAddressTwo as UntypedFormControl;
  public readonly streetAddressThreeControl = this.addressControl.controls.streetAddressThree as UntypedFormControl;
  public readonly cityControl = this.addressControl.controls.city as UntypedFormControl;
  public readonly stateControl = this.addressControl.controls.state as UntypedFormControl;
  public readonly countryControl = this.addressControl.controls.country as FormControl<InputDataVM<string, string>>;
  public readonly zipControl = this.addressControl.controls.zip as UntypedFormControl;
  public readonly contactPersonControl = this.addressControl.controls.contactPerson as UntypedFormControl;
  public readonly emailControl = this.addressControl.controls.email as UntypedFormControl;
  public readonly phoneControl = this.addressControl.controls.phone as UntypedFormControl;
  public readonly additionalPhoneControl = this.addressControl.controls.additionalPhone as UntypedFormControl;

  public readonly tagError$: Observable<string | null> = this.validatorHelperService.getError$(this.tagControl);
  public readonly pickupPreferenceError$: Observable<string | null> = this.validatorHelperService.getError$(this.pickupPreferenceControl);
  public readonly companyNameError$: Observable<string | null> = this.validatorHelperService.getError$(this.companyNameControl);
  public readonly streetAddressError$: Observable<string | null> = this.validatorHelperService.getError$(this.streetAddressControl);
  public readonly streetAddressTwoError$: Observable<string | null> = this.validatorHelperService.getError$(this.streetAddressTwoControl);
  public readonly streetAddressThreeError$: Observable<string | null> = this.validatorHelperService.getError$(
    this.streetAddressThreeControl
  );
  public readonly cityError$: Observable<string | null> = this.validatorHelperService.getError$(this.cityControl);
  public readonly countryError$: Observable<string | null> = this.validatorHelperService.getError$(this.countryControl);
  public readonly zipError$: Observable<string | null> = this.validatorHelperService.getError$(this.zipControl);
  public readonly contactPersonError$: Observable<string | null> = this.validatorHelperService.getError$(this.contactPersonControl);
  public readonly emailError$: Observable<string | null> = this.validatorHelperService.getError$(this.emailControl);
  public readonly phoneError$: Observable<string | null> = this.validatorHelperService.getError$(this.phoneControl);
  public readonly additionalPhoneError$: Observable<string | null> = this.validatorHelperService.getError$(this.additionalPhoneControl);

  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA)
    private readonly data: DialogData<EditAddressDialogPayload, EditAddressDialogResult>,
    @Inject(COMMON_ADDRESS_CONFIG) private readonly commonAddressConfig: CommonAddressConfig,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly validatorHelperService: ValidatorHelperService,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly toastMessageService: ToastMessageService,
    private readonly translateService: TranslateService,
    private readonly pickupPreferenceTranslationKeyPipe: PickupPreferenceTranslationKeyPipe,
    private readonly cdr: ChangeDetectorRef
  ) {
    switch (data.payload.action) {
      case EditAddressDialogActionType.Create: {
        if (data.payload.address?.country !== undefined) {
          this.addressControl.controls.country.setValue(constructInputData(data.payload.address.country));
          this.addressControl.controls.country.disable();
        }
        return;
      }
      case EditAddressDialogActionType.Edit: {
        this.isInEdit = true;
        this.addressControl.patchValue({
          ...data.payload.address,
          country: constructInputData(data.payload.address.country),
          pickupPreference: data.payload.address.pickupPreference,
        });
        this.addressControl.controls.country.disable();
        return;
      }
      default: {
        return;
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get shouldShowIsDefaultControl(): boolean {
    return this.data.payload.addressType === AddressType.Pickup;
  }

  public onBackIconClick(): void {
    this.data.dialogRef.close();
  }

  public onCancelClick(): void {
    this.data.dialogRef.close();
  }

  public onDeleteAddressClick(): void {
    if (this.data.payload.action !== EditAddressDialogActionType.Edit) {
      return;
    }
    this.isLoading = true;

    const address = this.mapResult({
      ...this.addressControl.getRawValue(),
      id: get(this.data.payload.address, 'id'),
    });

    this.data.payload
      .onDelete(address)
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        () => this.data.dialogRef.close({ type: EditAddressDialogResultType.Delete }),
        (error) => this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_DELETE_ADDRESS')
      );
  }

  public onAddressSelect(place: google.maps.places.PlaceResult): void {
    const autocompleteAddress = mapGooglePlaceResult(place);
    const country = this.countryControl.disabled
      ? this.countryControl.value
      : this.countries.find((supportedCountry) => supportedCountry.value === autocompleteAddress.country);
    if (!isNil(autocompleteAddress.country) && isNil(country)) {
      this.toastMessageService.open(
        this.translateService.instant('ERROR.COUNTRY_NOT_SUPPORTED', {
          country: autocompleteAddress.country,
        }),
        { type: ToastMessageType.Error }
      );
      return;
    }
    const addresses = mapGoogleMapsAddress(autocompleteAddress.address);
    this.addressControl.patchValue({
      streetAddress: addresses[0],
      streetAddressTwo: addresses[1],
      streetAddressThree: addresses[2],
      city: autocompleteAddress.city,
      state: autocompleteAddress.state,
      zip: autocompleteAddress.zip,
      country: country as InputDataVM<string, string>,
    });
  }

  public onSaveAddressClick(): void {
    if (this.addressControl.invalid) {
      return;
    }

    const address = this.mapResult({
      ...this.addressControl.getRawValue(),
      id: get(this.data.payload.address, 'id'),
      isActive: get(this.data.payload.address, 'isActive', true),
    });

    this.isLoading = true;
    this.data.payload
      .onSave(address)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        (updatedAddress) => this.data.dialogRef.close({ type: this.resultType, address: updatedAddress }),
        (error) => this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_ADDRESS')
      );
  }

  private get resultType(): EditAddressDialogResultType | undefined {
    switch (this.data.payload.action) {
      case EditAddressDialogActionType.Create: {
        return EditAddressDialogResultType.Create;
      }
      case EditAddressDialogActionType.Edit: {
        return EditAddressDialogResultType.Edit;
      }
      default: {
        return undefined;
      }
    }
  }

  private mapResult(values: Form): AddressCardAddressVM {
    return {
      ...values,
      country: values.country.value,
      pickupPreference: values.pickupPreference,
    };
  }
}
