import { Injectable } from '@angular/core';
import {
  AvailableParticipantShipmentOrderDataService,
  AvailableParticipantsOnChannelAccountDataService,
  CasesDataService,
  CreateMessageChannelCaseDataService,
  DeleteMessageCaseDataService,
  GetFilteredCasesRequestFilterTabNameEnum,
  TasksDataService,
  UpdateMessageCaseDataService,
} from '@tecex-api/data';
import * as CryptoJS from 'crypto-js';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { filterEmptyCase } from '../../../helpers/filter-empty-case.helper';
import { mapCase, mapCaseByTab } from '../../../helpers/map-case.helper';
import { mapTasksToMessageThreads } from '../../../helpers/map-tasks-to-message-threads.helper';
import { MessageThread } from '../../../interfaces/messages/message-thread.interface';
import { AuthService } from '../../../services/auth.service';

@Injectable()
export class CommonMessagesService {
  constructor(
    private readonly authService: AuthService,
    private readonly casesDataService: CasesDataService,
    private readonly tasksDataService: TasksDataService,
    private readonly participantService: AvailableParticipantShipmentOrderDataService,
    private readonly accountParticipantService: AvailableParticipantsOnChannelAccountDataService,
    private readonly createMessageChannelCaseDataService: CreateMessageChannelCaseDataService,
    private readonly updateMessageCaseDataService: UpdateMessageCaseDataService,
    private readonly deleteMessageCaseDataService: DeleteMessageCaseDataService
  ) {}

  public loadMessageThreads$(profilePictureFallback: string): Observable<MessageThread[]> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        forkJoin([
          this.casesDataService
            .getCases({
              Accesstoken: user.accessToken,
              ContactID: user.contactId,
            })
            .pipe(
              map(
                (response) =>
                  response?.Cases?.filter(filterEmptyCase).map((caseResponse) => mapCase(caseResponse, profilePictureFallback)) || []
              )
            ),

          this.tasksDataService
            .getTasks({
              Accesstoken: user.accessToken,
              UserID: user.id,
              UserContactID: user.contactId,
              AccountID: user.accountId,
            })
            .pipe(map((response) => mapTasksToMessageThreads(response.Tasks))),
        ])
      ),
      map(([caseMessageThreads, taskMessageThreads]) => [].concat(caseMessageThreads, taskMessageThreads))
    );
  }

  public loadTaskMessageThreads$(): Observable<MessageThread[]> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.tasksDataService
          .getTasks({
            Accesstoken: user.accessToken,
            UserID: user.id,
            UserContactID: user.contactId,
            AccountID: user.accountId,
          })
          .pipe(map((response) => mapTasksToMessageThreads(response.Tasks)))
      )
    );
  }

  public loadMessageThreadsByTab$(
    profilePictureFallback: string,
    tabName?: GetFilteredCasesRequestFilterTabNameEnum
  ): Observable<MessageThread[]> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.casesDataService
          .getFilteredCases({
            Accesstoken: user.accessToken,
            AccountID: user.accountId,
            FilterTabName: tabName,
          })
          .pipe(
            map((response) => {
              return (
                response?.Cases?.filter(filterEmptyCase).map((caseResponse) => {
                  return mapCaseByTab(caseResponse, profilePictureFallback, tabName);
                }) || []
              );
            })
          )
      )
    );
  }

  // Get the data of shipment-order participants :

  public listShipmentOrderParticipants$(soId: string): Observable<any> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.participantService.getShipmentOrderParticiapant({
          Accesstoken: user.accessToken,
          AccountID: user.accountId,
          SOID: soId,
        })
      )
    );
  }

  // Get the data of account participants :

  public listAccountParticipants$(): Observable<any> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.accountParticipantService.getAccountParticipant({
          Accesstoken: user.accessToken,
          AccountID: user.accountId,
        })
      )
    );
  }
  public base64ToHex(str: string): any {
    const raw = atob(str);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
      const hex = raw.codePointAt(i).toString(16);
      result += hex.length === 2 ? hex : `0${hex}`;
    }
    return result.toUpperCase();
  }

  public asciiToHex(str: string): any {
    const arr1 = [];
    for (let n = 0, l = str.length; n < l; n++) {
      const hex = Number(str.codePointAt(n)).toString(16);
      arr1.push(hex);
    }
    return arr1.join('');
  }

  public decrypt_core_AES_CBC(key: string, iv: string, ciphertext: string): any {
    const token = CryptoJS.enc.Hex.parse(key);
    const message = CryptoJS.AES.decrypt(
      {
        ciphertext,
      },
      token,
      {
        iv,
      }
    );
    return CryptoJS.enc.Utf8.stringify(message);
  }

  // Post for create a message channel :

  public postCreateMessageChannelCreations$(invoiceId?: string, taskId?: string, soId?: string): Observable<any> {
    return this.authService.getUser$().pipe(
      switchMap((user): any => {
        return this.createMessageChannelCaseDataService.createMessageChannelCases({
          Accesstoken: user.accessToken,
          AccountID: user.accountId,
          ContactID: user.contactId,
          ClientUserID: user.id,
          Subject: '-= DRAFT =-',
          InvoiceId: invoiceId ? invoiceId : null,
          TaskId: taskId ? taskId : null,
          SOID: soId ? soId : null,
        });
      })
    );
  }

  // Post for update a message channel :

  public postUpdateMessageCaseData$(caseId?: string, subject?: string, description?: string): Observable<any> {
    return this.authService.getUser$().pipe(
      switchMap((user): any => {
        return this.updateMessageCaseDataService.updateMessageCases({
          Accesstoken: user.accessToken,
          CaseID: caseId,
          Subject: subject,
          Description: description,
        });
      })
    );
  }

  // Post for delete a message channel :

  public postDeleteMessageCaseData$(caseId?: string): Observable<any> {
    return this.authService.getUser$().pipe(
      switchMap((user): any => {
        return this.deleteMessageCaseDataService.deleteMessageCase({
          Accesstoken: user.accessToken,
          MSGCS: [
            {
              CSID: caseId,
            },
          ],
        });
      })
    );
  }
}
