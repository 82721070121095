import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { DialogService } from '@global/modules/dialog/dialog.service';
import { SendbirdService } from '@global/modules/message-thread/services/sendbird-message.service';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { MixpanelService } from '@global/services/mixpanel.service';
import { BlockScrollService } from '@global/services/block-scroll.service';
import { SessionService } from '@global/modules/session/services/session.service';
import { InspectletService } from '@global/services/inspectlet.service';
import { UserpilotService } from '@global/services/userpilot.service';
import { HotjarService } from '@global/services/hotjar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet, AsyncPipe],
})
export class AppComponent {
  // Add the host listener for send-bird connection on tab change : Start
  @HostListener('document:visibilitychange', ['$event'])
  public onVisibilityChange(): void {
    if (document.hidden) {
      this.sendBirdServices.disconnect();
    } else {
      this.sendBirdServices.connect();
    }
  }
  // Add the host listener for send-bird connection on tab change : End

  public readonly hasOpenDialog$: Observable<boolean> = this.dialogService.hasOpenDialog$();

  constructor(
    private readonly dialogService: DialogService,
    private readonly sendBirdServices: SendbirdService,
    private readonly mixpanelService: MixpanelService,
    public readonly blockScrollService: BlockScrollService,
    private readonly sessionService: SessionService,
    private readonly inspectletService: InspectletService,
    private readonly userpilotService: UserpilotService,
    private readonly hotjarService: HotjarService
  ) {
    this.mixpanelService.startTrack();
  }
}
