import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MessageCardDescriptionDirective } from './directives/message-card-description.directive';
import { MessageCardIconDirective } from './directives/message-card-icon.directive';
import { MessageCardTitleDirective } from './directives/message-card-title.directive';
import { MatButtonModule } from '@angular/material/button';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgClass, NgTemplateOutlet, NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf } from '@angular/common';

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, NgSwitch, NgSwitchCase, SvgIconComponent, NgSwitchDefault, NgIf, MatButtonModule],
})
export class MessageCardComponent {
  @Input() public type: 'hint' | 'info' | 'warning' | 'error';
  @Input() public linkText: string;
  @Input() public linkTextColor: 'default' | 'red' = 'default';
  @Input() public linkType: 'default' | 'button' = 'default';
  @Output() public linkClick = new EventEmitter();

  @ContentChild(MessageCardIconDirective, { read: TemplateRef }) public icon: TemplateRef<any>;
  @ContentChild(MessageCardTitleDirective, { read: TemplateRef }) public title: TemplateRef<any>;
  @ContentChild(MessageCardDescriptionDirective, { read: TemplateRef }) public description: TemplateRef<any>;

  public messageLinkClick(): void {
    this.linkClick.emit();
  }
}
