import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { AnnouncmentBarComponent } from './components/announcment-bar/announcment-bar.component';
import { MaintenanceDialogComponent } from './components/maintenance-dialog/maintenance-dialog.component';

@NgModule({
  imports: [CommonModule, TranslateModule, SvgIconsModule, MatButtonModule, AnnouncmentBarComponent, MaintenanceDialogComponent],
  exports: [AnnouncmentBarComponent],
})
export class AnnouncmentModule {}
