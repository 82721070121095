import { HttpErrorResponse } from '@angular/common/http';
import { ActiveInactive, AddressesDataService, ProfileDataService } from '@tecex-api/data';
import { PickupPreference, ShipmentOrderRelationsFreightDetails, Success } from '@tecex-api/data/model/models';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map, mapTo, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AddressType } from '../enums/address-type.enum';
import { mapAddress } from '../helpers/map-address.helper';
import { AddressBook } from '../interfaces/address/address-book.intefrace';
import { AddressCardAddressVM } from '../interfaces/address/address.vm';
import { EditAddressDialogResult } from '../interfaces/address/edit-address-dialog-result.type';
import { InputDataVM } from '../interfaces/input-data.vm';
import { EditAddressDialogService } from '../modules/common-address/components/edit-address-dialog/edit-address-dialog.service';
import { EditAddressDialogActionType } from '../modules/common-address/enums/edit-address-dialog-action-type.enum';
import { mapAddressBook } from '../modules/common-address/helpers/map-address-book.helper';
import { mapAddressCreatePayload } from '../modules/common-address/helpers/map-address-create-payload.helper';
import { mapAddressUpdatePayload } from '../modules/common-address/helpers/map-address-update-payload.helper';
import { mapUpdatePickupReferenceHelper } from '../modules/common-address/helpers/map-update-pickup-reference.helper';
import { DialogShowMode } from '../modules/dialog/enums/dialog-show-mode.enum';
import { LoadingIndicatorService } from '../modules/loading-indicator/services/loading-indicator.service';
import { AuthService } from '../services/auth.service';
import { ErrorNotificationService } from '../services/error-notification.service';

export abstract class BaseAddressService {
  protected readonly pickupAddresses: Map<string, Observable<AddressCardAddressVM[]>> = new Map();
  protected readonly shipToAddresses: Map<string, Observable<AddressCardAddressVM[]>> = new Map();

  constructor(
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly errorNotificationService: ErrorNotificationService,
    protected readonly authService: AuthService,
    protected readonly addressesDataService: AddressesDataService,
    protected readonly profileDataService: ProfileDataService,
    protected readonly editAddressDialogService: EditAddressDialogService
  ) {}

  public abstract selectDefaultPickupAddress$(country: string, countries: InputDataVM[]): Observable<AddressCardAddressVM | undefined>;
  public abstract selectPickupAddresses$({
    country,
    countries,
    selectedAddresses,
  }: {
    country: string;
    countries: InputDataVM[];
    selectedAddresses: AddressCardAddressVM[];
  }): Observable<AddressCardAddressVM[] | undefined>;
  public abstract selectShipToAddresses$({
    country,
    countries,
    selectedAddresses,
  }: {
    country: string;
    countries: InputDataVM[];
    selectedAddresses: AddressCardAddressVM[];
  }): Observable<AddressCardAddressVM[] | undefined>;

  public createPickupAddressThroughDialog$(
    countries: InputDataVM<string, string>[],
    country?: string
  ): Observable<EditAddressDialogResult> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.editAddressDialogService
          .open(
            {
              action: EditAddressDialogActionType.Create,
              addressType: AddressType.Pickup,
              address: { country },
              countries,
              onSave: (addressPayload: AddressCardAddressVM) => {
                const mappedAddressPayload = mapAddressCreatePayload(addressPayload);
                return this.addressesDataService
                  .createPickupAddresses({
                    PickUpAddress: [
                      {
                        ...mappedAddressPayload,
                        Accesstoken: user.accessToken,
                        AccountID: user.accountId,
                      },
                    ],
                  })
                  .pipe(
                    map((newAddresses) => mapAddress(AddressType.Pickup, newAddresses[0])),
                    tap(() => {
                      this.clearCache(AddressType.Pickup, addressPayload.country);
                    })
                  );
              },
            },
            {
              showMode: DialogShowMode.Side,
            }
          )
          .afterClosed$()
      )
    );
  }

  public createShipToAddressThroughDialog$(
    countries: InputDataVM<string, string>[],
    country?: string
  ): Observable<EditAddressDialogResult> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.editAddressDialogService
          .open(
            {
              action: EditAddressDialogActionType.Create,
              addressType: AddressType.Delivery,
              address: { country },
              countries,
              onSave: (addressPayload: AddressCardAddressVM) => {
                const mappedAddressPayload = mapAddressCreatePayload(addressPayload);
                return this.addressesDataService
                  .createDestinationAddresses({
                    FianlDelAddress: [
                      {
                        ...mappedAddressPayload,
                        Accesstoken: user.accessToken,
                        AccountID: user.accountId,
                      },
                    ],
                  })
                  .pipe(
                    map((newAddresses) => mapAddress(AddressType.Delivery, newAddresses[0])),
                    tap(() => {
                      this.clearCache(AddressType.Delivery, addressPayload.country);
                    })
                  );
              },
            },
            {
              showMode: DialogShowMode.Side,
            }
          )
          .afterClosed$()
      )
    );
  }

  public editAddressThroughDialog$(
    addressType: AddressType,
    address: AddressCardAddressVM,
    countries: InputDataVM<string, string>[]
  ): Observable<EditAddressDialogResult> {
    return this.editAddressDialogService
      .open(
        {
          action: EditAddressDialogActionType.Edit,
          addressType,
          address,
          countries,
          onSave: (addressPayload: AddressCardAddressVM) =>
            this.updateAddress$(addressPayload).pipe(tap(() => this.clearCache(addressType, address.country))),
          onDelete: (addressPayload: AddressCardAddressVM) =>
            this.deleteAddress$(addressPayload).pipe(tap(() => this.clearCache(addressType, address.country))),
        },
        {
          showMode: DialogShowMode.Side,
        }
      )
      .afterClosed$();
  }

  public updateAddress$(addressPayload: AddressCardAddressVM): Observable<AddressCardAddressVM> {
    const mappedAddressPayload = mapAddressUpdatePayload(addressPayload);

    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.addressesDataService.updateAddress({
          ...mappedAddressPayload,
          Accesstoken: user.accessToken,
          AccountID: user.accountId,
        })
      ),
      mapTo(addressPayload)
    );
  }

  public deleteAddress$(addressPayload: AddressCardAddressVM): Observable<any> {
    const mappedAddressPayload = mapAddressUpdatePayload(addressPayload);
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.addressesDataService.updateAddress({
          ...mappedAddressPayload,
          AddressStatus: ActiveInactive.INACTIVE,
          Accesstoken: user.accessToken,
          AccountID: user.accountId,
        })
      )
    );
  }

  public getAddressBook$(): Observable<AddressBook> {
    return this.authService
      .getUser$()
      .pipe(switchMap((user) => this.profileDataService.getUserDefaults(user.accountId).pipe(map(mapAddressBook))));
  }

  protected clearCache(addressType: AddressType, country: string): void {
    switch (addressType) {
      case AddressType.Pickup: {
        this.pickupAddresses.delete(country);
        break;
      }
      case AddressType.Delivery: {
        this.shipToAddresses.delete(country);
        break;
      }
      default: {
        return;
      }
    }
  }

  protected getPickupAddresses$(pickupCountry: string, showLoadingIndicator = true): Observable<AddressCardAddressVM[]> {
    return this.authService.getUser$().pipe(
      switchMap((user) => {
        const pickupAddressesEndpoint$ = this.addressesDataService
          .getPickupAddressesForCountry({
            ClientAccId: user.accountId,
            Accesstoken: user.accessToken,
            Country: pickupCountry,
          })
          .pipe(map((addresses) => addresses.map((address) => mapAddress(AddressType.Pickup, address))));

        return this.getCachableAddresses$(AddressType.Pickup, pickupAddressesEndpoint$, showLoadingIndicator);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  protected getShipToAddresses$(shipToCountry: string, showLoadingIndicator = true): Observable<AddressCardAddressVM[]> {
    return this.authService.getUser$().pipe(
      switchMap((user) => {
        const shipToAddressesEndpoint$ = this.addressesDataService
          .getDestinationAddressesForCountry({
            ClientAccId: user.accountId,
            Accesstoken: user.accessToken,
            Country: shipToCountry,
          })
          .pipe(map((addresses) => addresses.map((address) => mapAddress(AddressType.Delivery, address))));

        return this.getCachableAddresses$(AddressType.Delivery, shipToAddressesEndpoint$, showLoadingIndicator);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  protected getCachableAddresses$(
    addressType: AddressType,
    addressEndpoint$: Observable<AddressCardAddressVM[]>,
    showLoadingIndicator = true
  ): Observable<AddressCardAddressVM[]> {
    return of({}).pipe(
      tap(() => {
        if (showLoadingIndicator) {
          this.loadingIndicatorService.open();
        }
      }),
      switchMap(() =>
        addressEndpoint$.pipe(
          map((addresses) => addresses.filter((address) => address.isActive)),
          finalize(() => {
            if (showLoadingIndicator) {
              this.loadingIndicatorService.dispose();
            }
          }),
          catchError((error) => {
            // ¯\_(ツ)_/¯
            if (
              !(error instanceof HttpErrorResponse) ||
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              error.status !== 500 ||
              error.error !== 'There are currently no addresses for this country. Please create a new address.'
            ) {
              this.errorNotificationService.notifyAboutError(error, this.getAddressErrorKey(addressType));
            }

            return of([]);
          })
        )
      )
    );
  }

  protected getAddressErrorKey(addressType: AddressType): string {
    switch (addressType) {
      case AddressType.Pickup: {
        return 'ERROR.FAILED_TO_LOAD_PICKUP_ADDRESSES';
      }
      case AddressType.Delivery: {
        return 'ERROR.FAILED_TO_LOAD_DELIVERY_ADDRESSES';
      }
      default: {
        return '';
      }
    }
  }

  public getCachedPickupAddressesForCountry$(country: string, showLoadingIndicator = true): Observable<AddressCardAddressVM[]> {
    if (!this.pickupAddresses.has(country)) {
      this.pickupAddresses.set(country, this.getPickupAddresses$(country, showLoadingIndicator));
    }

    return this.pickupAddresses.get(country);
  }

  public getCachedShipToAddressesForCountry$(country: string, showLoadingIndicator = true): Observable<AddressCardAddressVM[]> {
    if (!this.shipToAddresses.has(country)) {
      this.shipToAddresses.set(country, this.getShipToAddresses$(country, showLoadingIndicator));
    }

    return this.shipToAddresses.get(country);
  }

  public updateDefaultAddress$(country: string, address: AddressCardAddressVM): Observable<void> {
    return this.updateAddress$({
      ...address,
      isDefault: true,
    }).pipe(
      tap(() => this.pickupAddresses.delete(country)),
      mapTo(undefined)
    );
  }

  public getIsCustomizedFinalDeliveriesAllowed$(): Observable<boolean> {
    return this.authService.getUser$().pipe(map((user) => user.allowCustomizedFinalDeliveries));
  }

  public updatePickupReference$(
    pickupPreference: PickupPreference,
    freightDetails: ShipmentOrderRelationsFreightDetails
  ): Observable<Success> {
    const request = mapUpdatePickupReferenceHelper(pickupPreference, freightDetails);
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.addressesDataService.updateAddress({
          ...request,
          Accesstoken: user.accessToken,
          AccountID: user.accountId,
        })
      )
    );
  }
}
