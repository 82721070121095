import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { ControlValueAccessor, FormControl } from '@ngneat/reactive-forms';
import { asyncScheduler, merge, Observable, Subject } from 'rxjs';
import { map, observeOn, startWith, takeUntil } from 'rxjs/operators';
import { FormControlService } from '../form-control/components/form-control/form-control.service';
import { MultiAutocompleteOptionDirective } from './multi-autocomplete-option.directive';
import { MatOptionModule } from '@angular/material/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgFor, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-multi-autocomplete',
  templateUrl: './multi-autocomplete.component.html',
  styleUrls: ['./multi-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiAutocompleteComponent),
    },
  ],
  standalone: true,
  imports: [
    MatChipsModule,
    NgFor,
    NgTemplateOutlet,
    SvgIconComponent,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatOptionModule,
    AsyncPipe,
  ],
})
export class MultiAutocompleteComponent<T = unknown> implements ControlValueAccessor, OnInit, AfterContentInit, OnDestroy {
  @ViewChild(MatAutocomplete) public matAutocomplete: MatAutocomplete;
  @ContentChildren(MultiAutocompleteOptionDirective, { descendants: true }) public options: QueryList<MultiAutocompleteOptionDirective>;
  @Output() public readonly focusInput = new EventEmitter<void>();
  @Output() public readonly blurInput = new EventEmitter<void>();

  public inputFormControl = new FormControl('');
  public chipFormControl = new FormControl([]);
  public filteredOptions$: Observable<MultiAutocompleteOptionDirective[]>;
  public selectedOptions$: Observable<MultiAutocompleteOptionDirective[]>;
  public isDisabled = false;

  private controlValueAccessorChangeFn: (value: T[]) => void;
  private controlValueAccessorTouchFn: () => void;
  private readonly destory$ = new Subject<void>();

  constructor(@Optional() private readonly formControlService: FormControlService) {}

  public ngOnInit(): void {
    this.chipFormControl.valueChanges.pipe(takeUntil(this.destory$)).subscribe((value) => {
      if (!this.controlValueAccessorChangeFn) {
        return;
      }
      this.controlValueAccessorChangeFn(value);
      this.controlValueAccessorTouchFn();
    });
  }

  public ngAfterContentInit(): void {
    this.filteredOptions$ = merge(this.options.changes, this.inputFormControl.valueChanges).pipe(
      // startWith is here to make sure panel opens when input is focused.
      // tslint:disable-next-line: no-null-keyword deprecation
      startWith(null),
      map(() => this.getFilteredOptions())
    );

    this.filteredOptions$.pipe(observeOn(asyncScheduler), takeUntil(this.destory$)).subscribe(() => {
      if (!this.matAutocomplete?.opened) {
        return;
      }

      this.matAutocomplete.options.forEach((option) => option.setInactiveStyles());
    });

    this.selectedOptions$ = merge(this.options.changes, this.chipFormControl.valueChanges).pipe(
      // startWith is here to make sure initial values are handled correctly.
      // tslint:disable-next-line: no-null-keyword deprecation
      startWith(null),
      map(() =>
        this.chipFormControl.value
          .map((item: T) => this.options.find((option) => option.value === item))
          .filter((item) => item !== undefined)
      )
    );
  }

  public onItemRemoved(toRemove: T): void {
    this.chipFormControl.setValue(this.chipFormControl.value.filter((item: T) => item !== toRemove));
  }

  public writeValue(value: T[]): void {
    this.chipFormControl.setValue(value, { emitEvent: false });
  }

  public registerOnChange(fn: (value: T[]) => void): void {
    this.controlValueAccessorChangeFn = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.controlValueAccessorTouchFn = fn;
  }

  public onOptionSelect(event: MatAutocompleteSelectedEvent): void {
    const alreadySelected = this.chipFormControl?.value.find((item: T) => item === event.option.value);

    if (!alreadySelected) {
      this.chipFormControl.setValue([...this.chipFormControl?.value, event.option.value]);
    }

    this.inputFormControl.setValue('');
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onFocus(): void {
    this.focusInput.next();
  }

  public onBlur(): void {
    if (this.controlValueAccessorTouchFn) {
      this.controlValueAccessorTouchFn();
    }

    this.blurInput.next();
  }

  public get hasError(): boolean {
    return this.formControlService?.hasError;
  }

  public ngOnDestroy(): void {
    this.destory$.next();
    this.destory$.complete();
  }

  private getFilteredOptions(): MultiAutocompleteOptionDirective[] {
    const filterValue = this.inputFormControl.value.toLowerCase();

    return this.options
      .filter(({ searchValue }) => searchValue.toLowerCase().includes(filterValue))
      .sort((a, b) => {
        const aIsPrefixed = a.searchValue.toLowerCase().startsWith(filterValue);
        const bIsPrefixed = b.searchValue.toLowerCase().startsWith(filterValue);

        if (aIsPrefixed && !bIsPrefixed) {
          return -1;
        }

        if (!aIsPrefixed && bIsPrefixed) {
          return 1;
        }

        return 0;
      });
  }
}
