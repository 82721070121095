import { Injectable, Injector } from '@angular/core';
import { IntentionWithGoods, ShipmentOrderDataService } from '@tecex-api/data';
import last from 'lodash/last';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RePollService } from '../../../../../../../projects/zee/src/app/shared/services/re-poll.service';
import { assertNever } from '../../../../helpers/assert-never.helper';
import { AuthService } from '../../../../services/auth.service';
import { ErrorNotificationService } from '../../../../services/error-notification.service';
import { UpdateQuoteService } from '../../../common-quote/services/update-quote.service';
import { DialogRef } from '../../../dialog/dialog-ref';
import { DialogService } from '../../../dialog/dialog.service';
import { DialogShowMode } from '../../../dialog/enums/dialog-show-mode.enum';
import { LoadingIndicatorService } from '../../../loading-indicator/services/loading-indicator.service';
import { BeneficialOwnerDialogPayload } from './beneficial-owner-dialog-payload.interface';
import { BeneficialOwnerDialogComponent } from './beneficial-owner-dialog.component';
import { BeneficialOwnerStep } from './beneficial-owner-step.enum';
import { mapBeneficialOwnerResult } from './map-beneficial-owner-result.helper';

@Injectable()
export class BeneficialOwnerDialogService {
  constructor(
    private readonly injector: Injector,
    private readonly dialogService: DialogService,
    private readonly updateQuoteService: UpdateQuoteService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly shipmentOrderDataService: ShipmentOrderDataService,
    private readonly authService: AuthService,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly rePollService: RePollService
  ) {}

  public open(
    payload: Pick<
      BeneficialOwnerDialogPayload,
      | 'title'
      | 'description'
      | 'defaultTeamMember'
      | 'shipmentOrder'
      | 'showShipmentOrderBox'
      | 'isInactive'
      | 'countries'
      | 'shipmentOrderCompanyName'
      | 'onMsgClickHandler'
    >
  ): DialogRef<boolean> {
    const steps$ = new BehaviorSubject<BeneficialOwnerStep[]>([BeneficialOwnerStep.IntetionWithGoods]);

    const dialogRef: DialogRef<boolean> = this.dialogService.open<BeneficialOwnerDialogPayload, boolean>(
      BeneficialOwnerDialogComponent,
      {
        ...payload,
        step$: steps$.pipe(map((steps) => last(steps))),
        hasPreviousStep$: steps$.pipe(map((steps) => steps.length > 1)),
        handleBack: () => steps$.next(steps$.value.slice(0, -1)),
        handleNext$: (result) => {
          const currentStep = last(steps$.value);

          switch (currentStep) {
            case BeneficialOwnerStep.IntetionWithGoods: {
              // eslint-disable-next-line sonarjs/no-nested-switch
              switch (result.intentionWithGoods) {
                case IntentionWithGoods.TO_SELL_GOODS: {
                  steps$.next([...steps$.value, BeneficialOwnerStep.CompanyDetails]);
                  break;
                }
                case IntentionWithGoods.RETAIN_OWNERSHIP_OF_GOODS: {
                  steps$.next([...steps$.value, BeneficialOwnerStep.Confirmation]);
                  break;
                }
                case IntentionWithGoods.OTHER: {
                  steps$.next([...steps$.value, BeneficialOwnerStep.OtherDetails]);
                  break;
                }
              }
              break;
            }
            case BeneficialOwnerStep.CompanyDetails: {
              return this.loadingIndicatorService
                .withFullscreen$(
                  this.updateQuoteService.updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result)).pipe(
                    switchMap(() => this.authService.getUser$()),
                    switchMap((user) =>
                      this.rePollService.pollSoDetails$({
                        accessToken: user.accessToken,
                        accountId: user.accountId,
                        contactId: user.contactId,
                        shipmentOrderId: payload.shipmentOrder.id,
                      })
                    )
                  )
                )
                .pipe(
                  tap((shipmentOrder) => {
                    const nextStep = BeneficialOwnerStep.Ownership;
                    steps$.next([...steps$.value, nextStep]);
                  })
                );
            }

            case BeneficialOwnerStep.OtherDetails:
            case BeneficialOwnerStep.VatNumber:
            case BeneficialOwnerStep.Ownership: {
              steps$.next([...steps$.value, BeneficialOwnerStep.Confirmation]);
              break;
            }
            case BeneficialOwnerStep.Confirmation: {
              return this.loadingIndicatorService
                .withFullscreen$(
                  this.updateQuoteService.updateQuote$(payload.shipmentOrder.id, mapBeneficialOwnerResult(result)).pipe(
                    catchError((error) => {
                      this.errorNotificationService.notifyAboutError(error, 'ERROR.FAILED_TO_SAVE_BENEFICIAL_OWNER_DETAILS');
                      return EMPTY;
                    })
                  )
                )
                .pipe(tap(() => dialogRef.close(true)));
            }
            default: {
              return assertNever(currentStep);
            }
          }

          return of(undefined);
        },
      },
      {
        showMode: DialogShowMode.Side,
        closeButton: false,
        closeOnNavigation: true,
        width: '800px',
        injector: this.injector,
      }
    );

    return dialogRef;
  }
}
