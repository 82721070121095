import { Pipe, PipeTransform } from '@angular/core';
import { BeneficialOwnerStep } from '../beneficial-owner-step.enum';

@Pipe({
  name: 'appShowSteps',
  pure: true,
  standalone: true,
})
export class ShowStepsPipe implements PipeTransform {
  private readonly showSteps: BeneficialOwnerStep[] = [
    BeneficialOwnerStep.CompanyDetails,
    BeneficialOwnerStep.VatNumber,
    BeneficialOwnerStep.Ownership,
    BeneficialOwnerStep.OtherDetails,
  ];

  public transform(value: BeneficialOwnerStep): boolean {
    return this.showSteps.includes(value);
  }
}
