import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { DownloadBlockPayload } from '../../types/download-block-payload.interface';
import { BlockComponent } from '../block/block.component';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'app-download-block',
  templateUrl: './download-block.component.html',
  styleUrls: ['./download-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent],
})
export class DownloadBlockComponent extends BlockComponent<DownloadBlockPayload, undefined> implements OnInit {
  public ngOnInit(): void {
    this.register.next(new FormControl(undefined));
  }
}
