// @ts-nocheck
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { DatepickerBlockPayload } from '../../types/datepicker-block-payload.interface';
import { DatepickerBlockResult } from '../../types/datepicker-block-result.interface';
import { BlockComponent } from '../block/block.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FormControlHintDirective } from '../../../form-control/directives/form-control-hint.directive';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { FormControlInputSuffixDirective } from '../../../form-control/directives/form-control-input-suffix.directive';
import { FormControlInputDirective } from '../../../form-control/directives/form-control-input.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormControlLabelDirective } from '../../../form-control/directives/form-control-label.directive';
import { NgIf } from '@angular/common';
import { FormControlComponent } from '../../../form-control/components/form-control/form-control.component';

@Component({
  selector: 'app-datepicker-block',
  templateUrl: './datepicker-block.component.html',
  styleUrls: ['./datepicker-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormControlComponent,
    NgIf,
    FormControlLabelDirective,
    MatDatepickerModule,
    FormsModule,
    FormControlInputDirective,
    ReactiveFormsModule,
    FormControlInputSuffixDirective,
    SvgIconComponent,
    FormControlHintDirective,
    NgxMaterialTimepickerModule,
  ],
})
export class DatepickerBlockComponent extends BlockComponent<DatepickerBlockPayload, DatepickerBlockResult> implements OnInit {
  public form = new FormGroup<DatepickerBlockResult>({
    date: new FormControl<Date>(),
    time: new FormControl<string>(),
  });

  public get dateControl(): FormControl<Date> {
    return this.form.controls.date as unknown as FormControl<Date>;
  }

  public get timeControl(): FormControl<string> {
    return this.form.controls.time as unknown as FormControl<string>;
  }

  public ngOnInit(): void {
    this.register.emit(this.form);
  }
}
