import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouteSegment } from '@global/enums/route-segment.enum';
import { ShipmentStatus } from '@global/enums/shipment-status.enum';
import { ShipmentSummaryComponentType } from '@global/modules/tasks/types/shipment-summary-component-type.interface';
import { TaskVM } from '@global/modules/tasks/types/task.vm';
import { TranslateModule } from '@ngx-translate/core';
import { CostPipe } from '../../../../../../../../common/src/global/pipes/cost.pipe';
import { WeightUnitPipe } from '../../../../../../../../common/src/global/pipes/weight-unit.pipe';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TrackerComponent } from '../../../../../../../../common/src/global/modules/tracker/tracker.component';
import { ShipmentStateIndicatorCirclesComponent } from '../../../../../../../../common/src/global/modules/shipment-state-indicator-circles/shipment-state-indicator-circles.component';
import { NgSwitch, NgSwitchCase, AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-shipment-summary',
  templateUrl: './shipment-summary.component.html',
  styleUrls: ['./shipment-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    NgSwitch,
    NgSwitchCase,
    ShipmentStateIndicatorCirclesComponent,
    TrackerComponent,
    SvgIconComponent,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
    WeightUnitPipe,
    CostPipe,
    TranslateModule,
  ],
})
export class ShipmentSummaryComponent implements ShipmentSummaryComponentType {
  @Input() public shipmentOrder: TaskVM['shipmentOrder'];

  public readonly ShipmentStatus = ShipmentStatus;

  public get shipmentLink(): string[] {
    return [RouteSegment.Root, RouteSegment.ShipmentsList, this.shipmentOrder.id];
  }
}
