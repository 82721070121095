import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProfilePicturePipe } from '../../../../pipes/profile-picture.pipe';
import { MatRippleModule } from '@angular/material/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-message-participant-item',
  templateUrl: './message-participant-item.component.html',
  styleUrls: ['./message-participant-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, SvgIconComponent, MatRippleModule, ProfilePicturePipe, TranslateModule],
})
export class MessageParticipantItemComponent {
  // @Input() public teamMember: Participant;
  @Input() public teamMember: any;
  @Input() public added: boolean;
  @Input() public removable = false;
  @Input() public keyword: string;
  @Input() public participantSection: string;

  @Output() public add = new EventEmitter<void>();
  @Output() public remove = new EventEmitter<void>();

  public get searchedName(): string {
    return this.getSearchedTextWithBoldKeywords(
      `${this.teamMember.firstName} ${this.teamMember.lastName ? this.teamMember.lastName : ''}`,
      this.keyword
    );
  }

  public onAddClick(): void {
    this.add.next();
  }

  public onRemoveClick(): void {
    this.remove.next();
  }

  private getSearchedTextWithBoldKeywords(searchText: string, keyword: string): string {
    const keywordLowerCase = keyword.toLocaleLowerCase();
    const keywordLength = keywordLowerCase.length;

    const parts: { value: string; searched: boolean }[] = [];
    let searchedText = searchText;
    let searchedTextLowerCase = searchedText.toLocaleLowerCase();
    while (searchedTextLowerCase.includes(keywordLowerCase)) {
      const keywordIndex = searchedTextLowerCase.indexOf(keywordLowerCase);
      parts.push(
        { value: searchedText.slice(0, Math.max(0, keywordIndex)), searched: false },
        // eslint-disable-next-line unicorn/prefer-string-slice
        { value: searchedText.substring(keywordIndex, keywordIndex + keywordLength), searched: true }
      );
      searchedText = searchedText.slice(Math.max(0, keywordIndex + keywordLength));
      searchedTextLowerCase = searchedTextLowerCase.slice(Math.max(0, keywordIndex + keywordLength));
    }
    parts.push({ value: searchedText, searched: false });

    return parts.reduce(
      (acc, part) => (part.searched ? `${acc}<span class="text-normal-bold">${part.value}</span>` : acc + part.value),
      ''
    );
  }

  // get the team member name :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public teamMemberName(teamMember: any): void {
    return teamMember.FirstName || teamMember.LastName
      ? `${teamMember.FirstName} ${teamMember.LastName}`
      : // eslint-disable-next-line unicorn/no-nested-ternary, prettier/prettier
      teamMember.Name
      ? teamMember.Name
      : // eslint-disable-next-line sonarjs/no-all-duplicated-branches
        `${teamMember.firstName}` + ' ' + `${teamMember.lastName === undefined ? '' : teamMember.lastName}`;
  }
}
