import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { poundsInKilogram } from '../../constants/global.constants';
import { LengthUnit } from '../../enums/length-unit.enum';
import { WeightUnit } from '../../enums/weight-unit.enum';
import { PackageClosedContentPackageVM } from './package.vm';
import { TranslateModule } from '@ngx-translate/core';
import { LengthUnitPipe } from '../../pipes/length-unit.pipe';
import { WeightUnitPipe } from '../../pipes/weight-unit.pipe';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe, LowerCasePipe, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-package-closed-content-card',
  templateUrl: './package-closed-content-card.component.html',
  styleUrls: ['./package-closed-content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    SvgIconComponent,
    AsyncPipe,
    LowerCasePipe,
    DecimalPipe,
    WeightUnitPipe,
    LengthUnitPipe,
    TranslateModule,
  ],
})
export class PackageClosedContentCardComponent {
  public WeightUnit = WeightUnit;
  @Input() public package: PackageClosedContentPackageVM;
  @Input() public packageNumber: number;
  @Input() public weightUnit: WeightUnit;
  @Input() public lengthUnit: LengthUnit;
  @Input() public disableDelete?: boolean;
  @Input() public isEditable = false;
  @Output() public delete = new EventEmitter<void>();
  @Output() public edit = new EventEmitter<void>();

  public get totalWeightInLbs(): number {
    return this.package.totalWeight / poundsInKilogram;
  }

  public onDelete(): void {
    this.delete.emit();
  }

  public onEdit(): void {
    this.edit.emit();
  }

  // For getting the data and manipulate latest value :
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getTotalWeight(packages: any) {
    const VOLUMETRIC_WEIGHT_RATIO = 5000;

    // eslint-disable-next-line unused-imports/no-unused-vars, prefer-const
    let volumetricWeight =
      packages.lengthUnit === LengthUnit.Cm
        ? ((packages.length * packages.breadth * packages.height) / VOLUMETRIC_WEIGHT_RATIO) * packages.packageCount
        : ((packages.length * packages.breadth * packages.height) / 305.12) * packages.packageCount;

    // eslint-disable-next-line prefer-const
    let totalWeight =
      packages.weightUnit === WeightUnit.Kg
        ? packages.packageCount * packages.weight
        : (packages.packageCount * packages.weight) / poundsInKilogram;

    return Math.max(totalWeight, volumetricWeight);
  }
}
