import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CurrencyCode,
  DimensionWithNull,
  OrderType,
  ProfileDataService,
  ServiceTypeWithNull,
  UserDefaultsResponse,
  WeightUnitWithNull,
  YesNoWithNull,
} from '@tecex-api/data';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserDefaultsService {
  constructor(
    private readonly profileDataService: ProfileDataService,
    private readonly authService: AuthService
  ) {}

  public getUserDefaults$(): Observable<UserDefaultsResponse> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.profileDataService.getUserDefaults(user.accountId).pipe(
          switchMap((defaults) => {
            const hasFinalQuoteDefaults = defaults.ClientDefaultvalues?.some((item) => item.OrderType === OrderType.FINAL_QUOTE);

            if (hasFinalQuoteDefaults) {
              return of(defaults);
            }

            return this.createDefault$(user.accountId);
          }),
          catchError((error) => {
            // ¯\_(ツ)_/¯
            // tslint:disable-next-line:no-magic-numbers
            if (
              error instanceof HttpErrorResponse &&
              error.status === HttpStatusCode.InternalServerError &&
              error.error === 'Default values are not found'
            ) {
              return this.createDefault$(user.accountId);
            }

            throw error;
          })
        )
      )
    );
  }

  private createDefault$(accountId: string): Observable<unknown> {
    return this.profileDataService
      .createUserDefaults({
        DefaultPref: [
          {
            ClientID: accountId,
            Order_Type: OrderType.FINAL_QUOTE,
            Chargeable_Weight_Units: WeightUnitWithNull.KGS,
            ion_Batteries: YesNoWithNull.I_CHOOSE_UPON_CREATION,
            Package_Dimensions: DimensionWithNull.CM,
            Package_Weight_Units: WeightUnitWithNull.KGS,
            Second_hand_parts: YesNoWithNull.I_CHOOSE_UPON_CREATION,
            Service_Type: ServiceTypeWithNull.IOR,
            TecEx_to_handle_freight: YesNoWithNull.I_CHOOSE_UPON_CREATION,
            // tslint:disable-next-line:no-null-keyword
            Country: null,
            DefaultCurrency: CurrencyCode.USD,
          },
        ],
      })
      .pipe(switchMap(() => this.profileDataService.getUserDefaults(accountId)));
  }
}
