<div class="dialog-container">
  <div class="dialog-content">
    <div class="d-flex justify-content-between">
      <h3 class="dialog-title mb-0" [innerHTML]="data.payload.title"></h3>
      <app-message-button [user]="data.payload.defaultTeamMember" (buttonClick)="onMessageClick()"></app-message-button>
    </div>

    <div class="text-reduced-regular color-text-secondary mt-4" [innerHTML]="data.payload.description"></div>

    <app-shipment-summary
      *ngIf="data.payload.showShipmentOrderBox && data.payload.shipmentOrder"
      [shipmentOrder]="data.payload.shipmentOrder"
      class="mt-4 mb-4"
    ></app-shipment-summary>

    <div *ngIf="data.payload.isInactive" class="text-reduced-bold color-text-secondary py-3">
      {{ 'TASKS.INACTIVE_HINT' | translate }}
    </div>

    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="BeneficialOwnerStep.IntetionWithGoods">
        <p class="text-reduced-regular color-text-secondary">{{ 'TASKS.BENEFICIAL_OWNER.INTENTION_WITH_GOODS_DESCRIPTION' | translate }}</p>

        <app-form-control class="select">
          <span appFormControlLabel>{{ 'TASKS.BENEFICIAL_OWNER.PLEASE_SELECT_FROM_THE_LIST' | translate }}</span>
          <app-select
            class="form-control-input"
            [formControl]="intentionWithGoodsControl"
            [data]="[
              {
                value: IntentionWithGoods.TO_SELL_GOODS,
                viewValue:
                  'TASKS.BENEFICIAL_OWNER.COMPANY_SELLING_GOODS' | translate: { companyName: data.payload.shipmentOrderCompanyName }
              },
              {
                value: IntentionWithGoods.RETAIN_OWNERSHIP_OF_GOODS,
                viewValue:
                  'TASKS.BENEFICIAL_OWNER.COMPANY_RETAINING_OWNERSHIP' | translate: { companyName: data.payload.shipmentOrderCompanyName }
              },
              { value: IntentionWithGoods.OTHER, viewValue: 'TASKS.BENEFICIAL_OWNER.MORE_COMPLICATED' | translate }
            ]"
            appFormControlInput
          ></app-select>
        </app-form-control>
      </ng-container>

      <ng-container *ngSwitchCase="BeneficialOwnerStep.CompanyDetails">
        <form autocomplete="off">
          <div>
            <p class="text-reduced-regular color-text-secondary">{{ 'TASKS.BENEFICIAL_OWNER.COMPANY_NAME_DESCRIPTION' | translate }}</p>

            <app-form-control>
              <span appFormControlLabel>{{ 'TASKS.BENEFICIAL_OWNER.COMPANY_NAME_LABEL' | translate }}</span>
              <input [formControl]="companyNameControl" type="text" class="form-control-input" appFormControlInput />
            </app-form-control>
          </div>

          <div>
            <p class="text-reduced-regular color-text-secondary">{{ 'TASKS.BENEFICIAL_OWNER.COMPANY_COUNTRY_DESCRIPTION' | translate }}</p>

            <app-form-control>
              <span appFormControlLabel>{{ 'TASKS.BENEFICIAL_OWNER.COMPANY_COUNTRY_LABEL' | translate }}</span>
              <app-dropdown
                [formControl]="companyCountryControl"
                appFormControlInput
                class="form-control-input"
                [data]="countries"
              ></app-dropdown>
            </app-form-control>
          </div>

          <div>
            <p class="text-reduced-regular color-text-secondary">{{ 'TASKS.BENEFICIAL_OWNER.COMPANY_ADDRESS_DESCRIPTION' | translate }}</p>

            <app-form-control>
              <span appFormControlLabel>{{ 'TASKS.BENEFICIAL_OWNER.COMPANY_ADDRESS_LABEL' | translate }}</span>
              <input [formControl]="companyAddressControl" type="text" class="form-control-input" appFormControlInput />
            </app-form-control>
            <div class="d-flex justify-content-spacebetween max-available-width">
              <app-form-control class="col-6 pl-0">
                <span appFormControlLabel>{{ 'TASKS.BENEFICIAL_OWNER.COMPANY_ADDRESS_CITY' | translate }}</span>
                <input [formControl]="companyCityControl" type="text" class="form-control-input" appFormControlInput />
              </app-form-control>
              <app-form-control class="col-6 pr-0">
                <span appFormControlLabel>{{ 'TASKS.BENEFICIAL_OWNER.COMPANY_ADDRESS_POSTAL_CODE' | translate }}</span>
                <input [formControl]="companyPostalCodeControl" type="text" class="form-control-input" appFormControlInput />
              </app-form-control>
            </div>
          </div>
        </form>
      </ng-container>

      <ng-container *ngSwitchCase="BeneficialOwnerStep.OtherDetails">
        <p class="text-reduced-regular color-text-secondary">{{ 'TASKS.BENEFICIAL_OWNER.OTHER_DESCRIPTION' | translate }}</p>

        <app-form-control>
          <span appFormControlLabel>{{ 'TASKS.BENEFICIAL_OWNER.OTHER_LABEL' | translate }}</span>
          <textarea
            class="form-control-input"
            appFormControlInput
            appTextareaAutosize
            [rows]="1"
            [formControl]="otherDescriptionControl"
          ></textarea>
        </app-form-control>
      </ng-container>

      <ng-container *ngSwitchCase="BeneficialOwnerStep.VatNumber">
        <p class="text-reduced-regular color-text-secondary">{{ 'TASKS.BENEFICIAL_OWNER.VAT_NUMBER_DESCRIPTION' | translate }}</p>

        <app-form-control>
          <span appFormControlLabel>{{ 'TASKS.BENEFICIAL_OWNER.VAT_NUMBER_LABEL' | translate }}</span>
          <input [formControl]="vatNumberControl" type="text" class="form-control-input" appFormControlInput />
        </app-form-control>
      </ng-container>

      <ng-container *ngSwitchCase="BeneficialOwnerStep.Ownership">
        <p class="text-reduced-regular color-text-secondary">{{ 'TASKS.BENEFICIAL_OWNER.OWNERSHIP_DESCRIPTION' | translate }}</p>

        <app-form-control>
          <span appFormControlLabel>{{ 'TASKS.BENEFICIAL_OWNER.OWNERSHIP_LABEL' | translate }}</span>
          <app-selection
            [formControl]="ownershipControl"
            appFormControlInput
            [items]="[
              { value: BuyerRetainsOwnership.YES, viewValue: 'COMMON.YES' | translate },
              {
                value: BuyerRetainsOwnership.NO,
                viewValue: 'COMMON.NO' | translate
              },
              {
                value: BuyerRetainsOwnership.UNSURE,
                viewValue: 'TASKS.BENEFICIAL_OWNER.UNSURE' | translate
              }
            ]"
          >
          </app-selection>
        </app-form-control>
      </ng-container>

      <ng-container *ngSwitchCase="BeneficialOwnerStep.Confirmation">
        <p class="text-reduced-regular color-text-secondary" [innerHTML]="'TASKS.BENEFICIAL_OWNER.CONFIRMATION' | translate"></p>
      </ng-container>
    </ng-container>
  </div>

  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex align-items-center justify-content-between w-100">
      <div>
        <p
          *ngIf="data.payload.step$ | async | appShowSteps"
          [innerHtml]="data.payload.step$ | async | appStepText"
          class="text-reduced-regular color-text-secondary"
        ></p>
      </div>
      <div>
        <button
          *ngIf="data.payload.hasPreviousStep$ | async"
          mat-button
          type="button"
          class="secondary-button mr-3"
          (click)="onBackClick()"
        >
          {{ 'COMMON.BACK' | translate }}
        </button>
        <div
          class="d-inline-block"
          [appTooltip]="data.payload.isInactive && 'TASKS.INACTIVE_TOOLTIP' | translate"
          [appTooltipStrategy]="'hover'"
        >
          <button mat-button class="primary-button" (click)="onNextClick()" [disabled]="!canProceed">
            {{ 'COMMON.NEXT' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
