<mat-chip-grid [disabled]="isDisabled" #chipList>
  <mat-chip-row
    class="chip"
    [class.error]="hasError"
    *ngFor="let option of selectedOptions$ | async"
    (removed)="onItemRemoved(option.value)"
  >
    <ng-container [ngTemplateOutlet]="option.templateRef"></ng-container>
    <svg-icon key="close" matChipRemove></svg-icon>
  </mat-chip-row>
  <input
    #chipInput
    [formControl]="inputFormControl"
    [matAutocomplete]="autoComplete"
    [matChipInputFor]="chipList"
    (focus)="onFocus()"
    (blur)="onBlur()"
    data-hj-allow
  />
  <svg-icon key="arrow-down" class="arrow-icon" [class.disabled]="isDisabled"></svg-icon>
</mat-chip-grid>
<mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="onOptionSelect($event)" class="app-multi-autocomplete">
  <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.value">
    <ng-container [ngTemplateOutlet]="option.templateRef"></ng-container>
  </mat-option>
</mat-autocomplete>
