/* eslint-disable unicorn/no-nested-ternary */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { FormControl } from '@ngneat/reactive-forms';
import { Store } from '@ngrx/store';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { utc } from 'moment';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import SendBird from 'sendbird';
import { CONFIG_TOKEN } from '../../../../../global/config/config.token';
import { GlobalConfig } from '../../../../../global/config/global-config.interface';
import { SourceApp } from '../../../../../global/enums/source-app.enum';
import { SendbirdMention } from '../../../../../global/interfaces/messages/sendbird-mention.interface';
import { User } from '../../../../../global/interfaces/user.interface';
import * as commonMessageActions from '../../../../../global/modules/message-thread/actions/common-messages.actions';
import { AuthService } from '../../../../../global/services/auth.service';
import { ErrorNotificationService } from '../../../../../global/services/error-notification.service';
import { SOURCE_APP } from '../../../../../global/tokens/source-app.token';
import { RouteSegment } from '../../../../enums/route-segment.enum';
import { MessagePayload } from '../../../../interfaces/messages/message-payload.interface';
import { SendbirdMessagePayload } from '../../../../interfaces/messages/sendbird-message-payload.interface';
import { Participant } from '../../../../interfaces/participant.interface';
import { ShipmentOrderPerson } from '../../../../interfaces/shipment-order-person.interface';
import { TeamMemberLists } from '../../../../interfaces/team-member-lists.interface';
import { FileExtensionService } from '../../../../services/file-extension.service';
import { MessageDialogPayload } from '../../../message-dialog/types/message-dialog-payload.interface';
import * as commonMessageAction from '../../actions/common-messages.actions';
import * as classicCustomBuild from '../../ckClassicBuild/build/ckeditor';
import * as commonMessageReducer from '../../reducers';
import { MessagingAWSService } from '../../services/messaging-aws.service';
import { SendbirdService } from '../../services/sendbird-message.service';
import { SendbirdMessageParticipantListComponent } from '../sendbird-message-participant-list/sendbird-message-participant-list.component';
import { Adapter } from '../sendbird-message-thread/ckeditor.adapter';
import { TranslateModule } from '@ngx-translate/core';
import { SendBirdMessageThreadParticipantsPipe } from '../../../../pipes/sendbird-message-thread-participants.pipe';
import { MessageThreadParticipantsPipe } from '../../../../pipes/message-thread-participants.pipe';
import { ProfilePicturePipe } from '../../../../pipes/profile-picture.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SendbirdMessageComponent } from '../sendbird-message/sendbird-message.component';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { LoadingIndicatorComponent } from '../../../loading-indicator/components/loading-indicator/loading-indicator.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { SendbirdSubjectComponent } from '../sendbird-subject/sendbird-subject.component';

@Component({
  selector: 'app-sendbird-message-thread',
  templateUrl: './sendbird-message-thread.component.html',
  styleUrls: ['./sendbird-message-thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  standalone: true,
  imports: [
    SendbirdSubjectComponent,
    MatMenuModule,
    NgIf,
    SendbirdMessageParticipantListComponent,
    MatButtonModule,
    NgClass,
    MatProgressSpinnerModule,
    LoadingIndicatorComponent,
    SvgIconComponent,
    NgFor,
    SendbirdMessageComponent,
    MatProgressBarModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    ProfilePicturePipe,
    MessageThreadParticipantsPipe,
    SendBirdMessageThreadParticipantsPipe,
    TranslateModule,
  ],
})
export class SendbirdMessageThreadComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() public messageThread: any;
  @Input() public teamMembers: TeamMemberLists;
  @Input() public sendbirdMessage: Array<any>;
  @Input() public sendingMessage: boolean;
  @Input() public sendbirdparticiapant: any;
  @Input() public sendbirdchannel: any;
  @Input() public shipmentOrderPeople: ShipmentOrderPerson[];
  @Input() public payloadOfDialog: MessageDialogPayload;
  @Input() public defaultParticipantSectionFlag: boolean;
  @Input() public isguestUserEnabled: boolean;
  @Input() public sendBirdChannelInfo: any;
  @Output() public sendsendbirdMessage = new EventEmitter<SendbirdMessagePayload>();
  @Output() public S3fileMessageDownload = new EventEmitter<any>();
  @Output() public outSideClick = new EventEmitter<any>();
  @Output() public caseIdFromMessageThread = new EventEmitter<any>();
  @Output() public send = new EventEmitter<MessagePayload>();

  @ViewChild('scrollableMessages') public scrollContainer: ElementRef;
  @ViewChild('messageInput') public messageInput: ElementRef<HTMLDivElement>;
  @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger;
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  @ViewChild(SendbirdMessageParticipantListComponent) sendbirdMessageParticipantListComponent: SendbirdMessageParticipantListComponent;

  public readonly RouteSegment = RouteSegment;
  public readonly messageControl = new FormControl<string>('');
  public readonly attachmentControl = new FormControl<FileList[]>();
  public readonly subjectControl = new FormControl<string>('', [Validators.required]);
  public readonly maxFileSizeInMb = 4;

  public attachments: File[];
  public filesExceededSizeLimit: File[];
  public isDraggingOver = false;
  public base64ImagesFromMessage: any = [];
  public newBody = '';
  public messageArray: Array<any> = [];
  public participantonsendbird: any = [];
  public userFeeds: any;
  public userFeedsSendbird: any = [];
  public isCompleted = true;
  /**
   * Only relevant when message thread does not exist yet but user added participants
   * The new participants will only be added when message thread is created
   */
  public newParticipants: Participant[] = [];

  private scrolled = false;
  private readonly destroyed$ = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public ckEditor: any = classicCustomBuild;
  public fileObj: Array<any> = [];
  public color: ThemePalette = 'primary';
  public mode: MatProgressBarModule = 'determinate';
  public valueOfLoader: number;
  public bufferValue = 0;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public isUserJoined: boolean = true;
  public ckeditorInstance = undefined;
  public listOfTaggedTeamMembers: any = [];
  public listOfGuestUsers: any = [];
  public listOfUsersForAcc: any = [];
  public caseCreatedFlag = false;
  public user$: Observable<User> = this.authService.getUser$();
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public orgId: string = '';
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public caseId: string = '';
  public countForUpdateMessageDetail = 0;

  public DetailOfMessageCreationCase$: Observable<any>;
  public nextButtonClickFlag = false;
  public descriptionOfMessage: any;
  public tagParticipantsClickFlag = false;
  public channelDetailOfFirstMessage: any;
  public sendbirdParticipants: any[];
  public flagForSpinner = false;
  public editParticipantClickFlag = false;
  public deleteChannelAPICallFlag = false;
  public existingChannelDetail: any = null;
  public userDetail: any;
  public decodedToken: any;
  private readonly responseOfUpdateMessageCase$ = this.store$.select(commonMessageReducer.selectResponseOfUpdateMessageCase);
  public readonly isLoadingForAccountParticipants$ = this.store$.select(commonMessageReducer.selectAccountPeopleDataLoadingState);
  public readonly isLoadingForshipmentOrderParticipants$ = this.store$.select(
    commonMessageReducer.selectShipmentOrderPeopleDataLoadingState
  );
  public guestUserFormValue: any;
  public saveButtonEnableDisableFlagValue = false;
  public guestUserDisableViaFlag = false;
  public sendbirdChannelInfoDetail: any;
  public sendbirdExistingChannelUrl: string;
  public loadingStateForChannelInfoDetail$ = this.store$.select(commonMessageReducer.selectChannelInfoDetailLoadingState);
  public flagForSubjectEnableDisable: any;
  public lengthOfMessageWhichSend = 0;
  public totalFileCount = 0;
  public numberOfFileLoaded = 0;
  public initialLoaderValue = 0;

  // // Import the config for CKEditor for tools :

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly fileExtensionService: FileExtensionService,
    private readonly SendbirdServiceService: SendbirdService,
    private readonly authService: AuthService,
    public router: Router,
    @Inject(SOURCE_APP) public sourceApp: SourceApp,
    private readonly store$: Store<commonMessageReducer.AppState>,
    private readonly messagingAWSService: MessagingAWSService,
    private readonly renderer: Renderer2,
    @Inject(CONFIG_TOKEN) private readonly configs: GlobalConfig,
    private readonly errorNotificationService: ErrorNotificationService
  ) {
    this.user$.subscribe((userDetails) => {
      this.userDetail = userDetails;
    });

    this.store$.dispatch(commonMessageAction.channelInfoListEmpty());
  }

  public editorConfig = {
    extraPlugins: [Adapter],
    mention: {
      dropdownLimit: 10_000,
      feeds: [
        {
          marker: '@',
          feed: this.loadGetmention.bind(this),
          itemRenderer: (item: { userId: string; id: string; isMuted: boolean }): HTMLElement => {
            const itemElement = document.createElement('button');
            itemElement.classList.add('ck');
            itemElement.classList.add('ck-button');
            itemElement.classList.add('ck-off');
            itemElement.classList.add('ck-button_with-text');
            itemElement.setAttribute('type', 'button');
            itemElement.setAttribute('tabindex', '-1');
            itemElement.dataset.ckeTooltipPosition = 's';
            itemElement.id = `mention-list-item-id-${item.userId}`;

            const spanElement = document.createElement('span');
            spanElement.classList.add('ck');
            spanElement.classList.add('ck-button__label');
            spanElement.id = `mention-list-text-item-id-${item.userId}`;
            spanElement.textContent = `${item.id}`;

            if (item.isMuted) {
              const imgElement = document.createElement('img');
              imgElement.setAttribute('src', '/assets/images/mute.png');
              imgElement.setAttribute('style', 'height: 18px; padding-left: 5px;');

              spanElement.append(imgElement);
            }
            itemElement.append(spanElement);

            return itemElement;
          },
        },
      ],
    },
    mediaEmbed: { previewsInData: true },
  };

  public get allParticipants(): Participant[] {
    if (this.isNewThread()) {
      return this.newParticipants;
    }

    return this.messageThread.participants.concat(this.newParticipants);
  }

  public get hasAttachments(): boolean {
    return !isNil(this.attachments) && this.attachments.length > 0;
  }

  public get canSendMessage(): boolean {
    return (
      !this.sendingMessage &&
      (!isEmpty(this.messageControl.value) || this.hasAttachments) &&
      !isEmpty(this.subjectControl.value) &&
      this.isUserJoined
    );
  }

  public get isLastMessageSentByCurrentUser(): boolean {
    return false;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public ngOnChanges(changes: SimpleChanges): void {
    this.renderer.listen('window', 'click', (e: any) => {
      if (
        e.target.classList[0] === 'cdk-overlay-backdrop' &&
        !this.nextButtonClickFlag &&
        changes?.sendbirdchannel?.currentValue === null
      ) {
        this.outSideClick.emit(true);
      } else if (
        this.caseId &&
        this.orgId &&
        e.target.classList[0] === 'cdk-overlay-backdrop' &&
        e.target.classList[1] !== 'context-menu_backdrop' &&
        !this.deleteChannelAPICallFlag
      ) {
        this.deleteCreatedChannelFromAWS();
      } else if (
        this.caseId &&
        this.orgId &&
        e.target.classList.length === 0 &&
        (e.target.farthestViewportElement === null || e.target.farthestViewportElement?.parentElement?.className === 'svg-icon-close') &&
        e.target.parentElement.className !== 'delete-attachment-btn svg-icon-close' &&
        e.target.parentElement.className !== 'svg-icon-attachment' &&
        !e.target.classList.value.includes('ck-') &&
        this.canSendMessage === false &&
        e.srcElement.parentElement.className !== 'svg-icon-send-2 ng-star-inserted' &&
        !this.deleteChannelAPICallFlag
      ) {
        this.deleteCreatedChannelFromAWS();
      } else if (this.listOfTaggedTeamMembers.length === 0 && changes?.sendbirdchannel?.currentValue === null) {
        this.outSideClick.emit(true);
      }
    });

    // For quote and shipment order message ( Existing channel with message ) :
    if (changes?.sendbirdchannel?.currentValue && changes.sendbirdchannel.currentValue.url) {
      this.sendbirdExistingChannelUrl = changes.sendbirdchannel.currentValue.url;
      this.guestUserDisableViaFlag = true;
      this.deleteChannelAPICallFlag = true;
      this.caseCreatedFlag = true;
      this.sendbirdChannelInfoDetail = changes?.sendbirdchannel?.currentValue;
    } else {
      this.guestUserDisableViaFlag = false;
      this.caseCreatedFlag = false;
    }

    if (changes.sendbirdparticiapant) {
      this.saveButtonEnableDisableFlagValue = false;
    }

    if (changes.sendingMessage) {
      this.messageControl.setDisable(this.sendingMessage);
      this.attachmentControl.setDisable(this.sendingMessage);
    }

    if (changes.sendbirdMessage) {
      this.handleMessageThreadChange(changes.sendbirdMessage);
      setTimeout(() => this.scrollToBottom());
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public ngOnInit(): void {
    if (this.sendbirdchannel?.url) {
      this.SendbirdServiceService.getChannel(this.orgId && this.caseId ? `${this.orgId}-${this.caseId}` : this.sendbirdchannel?.url)
        .then((result: any) => {
          const selectedChannel = result;
          if (this.SendbirdServiceService.isConnected()) {
            this.SendbirdServiceService.getMessagesFromChannel(
              selectedChannel,
              (errors: SendBird.SendBirdError, messages: Array<SendBird.AdminMessage | SendBird.FileMessage | SendBird.UserMessage>) => {
                if (!errors) {
                  this.sendbirdMessage = messages;
                  this.lengthOfMessageWhichSend = this.sendbirdMessage.length > 0 ? 1 : 0;
                  this.cdr.markForCheck();
                }
              }
            );
          }
        })
        .catch((error_) => {
          this.errorNotificationService.notifyAboutError(error_, 'ERROR.ERROR_GETTING_MENTION_LIST');
        });
    }

    if (this.sendbirdMessage || this.sendingMessage) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
      this.sendbirdChannelInfoDetail.url ? (this.guestUserDisableViaFlag = this.isguestUserEnabled) : (this.guestUserDisableViaFlag = true);
    }

    this.store$.dispatch(commonMessageActions.loadAwstokens());

    if (
      (this.router.url.includes('dashboard') &&
        this.router.url.includes('task') &&
        this.payloadOfDialog.shipment &&
        this.payloadOfDialog.shipment?.id) ||
      (this.router.url.includes('shipments-list') && this.router.url.includes('task')) ||
      (this.router.url.includes('invoices') &&
        this.payloadOfDialog.id &&
        this.payloadOfDialog.invoiceName &&
        this.payloadOfDialog.shipment?.id) ||
      (this.payloadOfDialog?.source?.type === 'NCP Invoice' && this.payloadOfDialog.invoiceName && this.payloadOfDialog.shipment?.id)
    ) {
      // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially
      this.store$.dispatch(
        commonMessageAction.loadShipmentOrderParticipants({
          SOID:
            this.payloadOfDialog && this.payloadOfDialog.shipment?.id
              ? this.payloadOfDialog.shipment?.id
              : this.sendbirdchannel?.shipment?.id,
        })
      );
    }
    // Adding this condition for Account Level tasks - Start
    else if (
      (this.router.url.includes('dashboard') &&
        this.router.url.includes('task') &&
        !this.payloadOfDialog.shipment &&
        !this.payloadOfDialog.shipment?.id) ||
      (this.router.url.includes('invoices') &&
        this.payloadOfDialog.id &&
        !this.payloadOfDialog.invoiceName &&
        !this.payloadOfDialog.shipment?.id) ||
      (this.payloadOfDialog?.source?.type === 'NCP Invoice' && this.payloadOfDialog.invoiceName && !this.payloadOfDialog.shipment?.id)
    ) {
      this.store$.dispatch(commonMessageAction.loadAccountParticipants());
    }
    // Adding this condition for Account Level tasks - End

    // tslint:disable-next-line: early-exit
    if (this.isNewThread() && this.messageThread?.participants?.length > 0) {
      this.newParticipants = this.messageThread?.participants?.map((participant) => ({
        ...participant,
        isNew: true,
      }));
    }
    this.SendbirdServiceService.getAwsTokens$();
    this.registerEventHandlers();
  }
  public loadGetmentionFeed$(): Observable<SendbirdMention> {
    let url = '';
    if (this.sendbirdchannel.Id) {
      url = `${this.userDetail.orgId}-${this.sendbirdchannel.Id}`;
    } else if (this.sendbirdchannel.url) {
      url = this.sendbirdchannel.url;
    } else if (this.sendbirdchannel.id) {
      url =
        this.sendbirdchannel.id.split('-').length === 1 ? `${this.userDetail.orgId}-${this.sendbirdchannel.id}` : this.sendbirdchannel.id;
    }

    return this.SendbirdServiceService.getMentionslist$(url);
  }

  public filterMention(queryText: string): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const isItemMatching = (item) => {
          // Make the search case-insensitive.
          const searchString = queryText.toLowerCase();

          return item.id.toLowerCase().includes(searchString) || item.userId.toLowerCase().includes(searchString);
        };
        const itemsToDisplay = this.userFeeds
          .map((mentions) => {
            return {
              userId: mentions.id,
              isMuted: mentions.isMuted,
              id: `@${mentions.nickname}`,
            };
          })
          .filter((element) => isItemMatching(element));
        resolve(itemsToDisplay);
      }, 100);
    });
  }

  public async loadGetmention(queryText: string): Promise<any> {
    if (queryText.length === 0) {
      const mentionUsers = await lastValueFrom(this.loadGetmentionFeed$());
      this.userFeeds = mentionUsers.mentionList;
      return this.filterMention(queryText);
    } else {
      return this.filterMention(queryText);
    }
  }

  private handleMessageThreadChange(change: SimpleChange): void {
    this.subjectControl.setValue(this.sendbirdchannel?.subject ? this.sendbirdchannel?.subject : this.sendbirdchannel?.name);
    if (this.sendbirdchannel) {
      this.isUserJoined = this.SendbirdServiceService.isuserJoined(this.sendbirdchannel, null);
      if (this.isUserJoined) {
        this.ckeditorInstance?.disableReadOnlyMode('cke');
        this.attachmentControl.enable();
        this.attachmentControl.setValue([]);
        this.messageControl.setValue('');

        this.subjectControl.enable();
      } else {
        this.ckeditorInstance?.enableReadOnlyMode('cke');
        this.attachmentControl.disable();
        this.subjectControl.disable();
        this.attachmentControl.setValue([]);
        this.messageControl.setValue('You can not send a message on this channel as you are not a participant.');
      }
    }
  }

  public ngAfterViewInit(): void {
    if (!this.scrolled) {
      this.scrollToBottom();
    }
    if (!this.sendbirdchannel) {
      this.menuTrigger.openMenu();
    }
  }

  private scrollToBottom(): void {
    this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
  }

  public get subject(): string {
    return this.subjectControl.value;
  }

  public handleFileInputChange(event: Event): void {
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;
    this.handleFiles(fileInput.files);
    this.cdr.markForCheck();
  }

  public handleFiles(fileList: FileList | null): void {
    if (!fileList && fileList.length > 0) {
      return;
    }

    const file = [...fileList];
    this.attachments = file;
    this.attachments = this.fileExtensionService.handleDisallowedExtensions(this.attachments);
  }

  public onDismissAttachmentClick(attachment: File): void {
    if (this.sendingMessage) {
      return;
    }
    this.attachments = this.attachments.filter((item) => item !== attachment);
  }

  public onThreadScroll(): void {
    this.scrolled = true;
  }

  public getMessagesFromChannelBySendBirdChannelURL(sendBirdURLFromExisting?: string): void {
    this.SendbirdServiceService.getChannel(this.orgId && this.caseId ? `${this.orgId}-${this.caseId}` : sendBirdURLFromExisting)
      .then((result: any) => {
        const selectedChannel = result;
        if (this.SendbirdServiceService.isConnected()) {
          this.SendbirdServiceService.getMessagesFromChannel(
            selectedChannel,
            (errors: SendBird.SendBirdError, messages: Array<SendBird.AdminMessage | SendBird.FileMessage | SendBird.UserMessage>) => {
              if (!errors) {
                this.sendbirdMessage = messages;
                this.cdr.markForCheck();
                // When a message is received and loaded onto a page, it automatically scrolls :
                setTimeout(() => this.scrollToBottom());
              }
            }
          );
        }
      })
      .catch((error_) => {
        this.errorNotificationService.notifyAboutError(error_, 'ERROR.ERROR_GETTING_MENTION_LIST');
      });
  }

  public onTextAreaInput(): void {
    this.scrollToBottom();
  }
  public onBlur(): void {
    if (this.subjectControl.invalid) {
      this.subjectControl.markAllAsTouched();
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public setLoaderValue(loaderValue: number): void {
    if (loaderValue === 100) {
      this.numberOfFileLoaded += 1;
      this.valueOfLoader = (this.numberOfFileLoaded * 100) / this.totalFileCount;
    } else {
      this.initialLoaderValue = this.numberOfFileLoaded > 0 ? (this.numberOfFileLoaded * 100) / this.totalFileCount : 0;
      this.valueOfLoader = loaderValue ? this.initialLoaderValue + loaderValue / this.totalFileCount : 0;
    }
    if (this.numberOfFileLoaded === this.totalFileCount) {
      this.initialLoaderValue = 0;
      this.numberOfFileLoaded = 0;
      this.totalFileCount = 0;
      this.isCompleted = true;
    }
    this.cdr.detectChanges();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity, @typescript-eslint/explicit-module-boundary-types
  public onSendMessageClick(userDetail?: any): void {
    if (!this.subjectControl.value) {
      this.subjectControl.setValue(this.sendbirdchannel?.name);
    }
    if (this.subjectControl.invalid) {
      this.subjectControl.markAllAsTouched();
      return;
    }

    const body = this.newBody;
    let channelDetailFromSendbird: any;
    this.messageControl.setValue('');
    this.attachmentControl.setValue([]);
    const s3Attachment = this.attachments;
    this.totalFileCount = this.attachments?.length ? this.attachments?.length : 0;
    this.isCompleted = s3Attachment && s3Attachment.length > 0 ? false : true;
    this.valueOfLoader = 0;
    this.initialLoaderValue = 0;
    this.numberOfFileLoaded = 0;
    this.attachments = [];

    const mention = this.userFeedsSendbird;
    if (body || s3Attachment) {
      if (
        this.router.url.includes('message') &&
        this.payloadOfDialog?.nextButtonClickFromMessageFlag !== undefined &&
        !this.payloadOfDialog.nextButtonClickFromMessageFlag
      ) {
        this.sendsendbirdMessage.emit({
          subject: this.subjectControl.value,
          body,
          mention,
          attachments: s3Attachment,
          channelUrl:
            this.caseId && this.orgId
              ? `${this.orgId}-${this.caseId}`
              : this.sendbirdchannel.url
              ? this.sendbirdchannel.url
              : this.sendbirdchannel.id,
          callbackFunc: this.setLoaderValue.bind(this),
        });
        this.deleteChannelAPICallFlag = true;
        this.guestUserDisableViaFlag = true;
        this.flagForSubjectEnableDisable = false;
        this.lengthOfMessageWhichSend = 1;
      } else {
        const message: SendbirdMessagePayload = {
          subject: this.subjectControl.value,
          body,
          mention,
          attachments: s3Attachment,
          channelUrl:
            this.caseId && this.orgId
              ? `${this.orgId}-${this.caseId}`
              : this.sendbirdchannel.url
              ? this.sendbirdchannel.url
              : this.sendbirdchannel.id,
          callbackFunc: this.setLoaderValue.bind(this),
        };

        this.SendbirdServiceService.getChannel(
          this.caseId && this.orgId
            ? `${this.orgId}-${this.caseId}`
            : this.sendbirdchannel.url
            ? this.sendbirdchannel.url
            : this.sendbirdchannel.id
        )
          .then((channel: any) => {
            // eslint-disable-next-line prefer-const
            this.channelDetailOfFirstMessage = channel.lastMessage === null ? true : false;
            this.deleteChannelAPICallFlag = true;
            this.flagForSubjectEnableDisable = false;
            this.sendbirdchannel = channel;

            this.SendbirdServiceService.sendMessage(
              channel,
              message,
              // eslint-disable-next-line @typescript-eslint/no-shadow
              (error: SendBird.SendBirdError, userMessage: SendBird.UserMessage | SendBird.FileMessage) => {
                if (!error && this.channelDetailOfFirstMessage) {
                  this.deleteChannelAPICallFlag = true;
                  channelDetailFromSendbird = channel;
                  this.lengthOfMessageWhichSend = channel?.lastMessage ? 1 : 0;
                  this.sendbirdMessage = [];
                  this.sendbirdMessage.push(message);

                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  this.caseId && this.subjectControl.value
                    ? this.store$.dispatch(
                        commonMessageAction.updateMessageCases({
                          CaseID: this.caseId,
                          Subject: this.subjectControl.value,
                          Description: channelDetailFromSendbird.lastMessage.message
                            ? channelDetailFromSendbird.lastMessage.message
                            : `File uploaded by ${userDetail.name} to channel: ${channelDetailFromSendbird.lastMessage.name}`,
                          orgId: this.orgId,
                        })
                      )
                    : null;
                } else if (error && error.code === 800_200) {
                  this.isCompleted = true;
                }
                this.getMessagesFromChannelBySendBirdChannelURL(
                  this.caseId && this.orgId
                    ? `${this.orgId}-${this.caseId}`
                    : this.sendbirdchannel.url
                    ? this.sendbirdchannel.url
                    : this.sendbirdchannel.id
                );
              }
            );
          })
          .catch((error_) => {
            this.errorNotificationService.notifyAboutError(error_, 'ERROR.ERROR_SENDING_MESSAGE');
          });

        // });
      }
    }
  }

  public onS3fileMessageDownloadClick(event: { objectKey: string }): void {
    this.S3fileMessageDownload.emit(event);
  }
  public onParticipantAdd(participant: Participant): void {
    this.newParticipants = this.newParticipants.concat({
      ...participant,
      isNew: true,
    });
  }

  public onParticipantRemove(participant: Participant): void {
    this.newParticipants = this.newParticipants.filter(({ id }) => id !== participant.id);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private isNewThread(): boolean {
    return this.sendbirdMessage?.length > 0 ? false : true;
  }

  public removeInvalidFileClick(fileName: string): void {
    this.filesExceededSizeLimit = this.filesExceededSizeLimit.filter((file) => file.name !== fileName);
  }

  public onDragStart(event: DragEvent): void {
    if (event.dataTransfer.types[0] === 'text/html' || event.dataTransfer.types[1] === 'text/plain') {
      this.isDraggingOver = false;
    }
  }

  public onDragEnter(event: DragEvent): void {
    this.isDraggingOver = event.dataTransfer.types[0] === 'Files' ? true : false;
  }

  public onDragLeave(): void {
    this.isDraggingOver = false;
  }

  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isDraggingOver = false;
    if (isNil(event.dataTransfer)) {
      return;
    }

    if (event.dataTransfer.files.length > 0) {
      this.handleFiles(event.dataTransfer.files);
    }
  }

  public dragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  onChange(editor: ChangeEvent): void {
    const textdata = editor?.editor?.getData();
    this.newBody = textdata;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-member-accessibility
  onReady(editor: any) {
    if (editor) {
      this.ckeditorInstance = editor;

      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new Adapter(loader);
      };

      const imageUploadEditing = editor.plugins.get('ImageUploadEditing');

      imageUploadEditing?.on('uploadComplete', (evt, { data, imageElement }) => {
        editor.model.change((writer) => {
          writer.setAttribute('alt', data.filename, imageElement);
          writer.setAttribute('src', data.urls.default, imageElement);
          evt.stop();
        });
      });
      editor.conversion.for('downcast').add((dispatcher: any) =>
        dispatcher.on('attribute:uploadStatus:imageBlock', (evt: any, data: any, conversionApi: any) => {
          const viewWriter = conversionApi.writer;
          const figure = conversionApi.mapper.toViewElement(data.item);
          const img = figure.getChild(0);

          if (data.attributeNewValue !== 'complete') {
            const src = data.item.getAttribute('src');
            const fileName = data.item.getAttribute('alt');
            viewWriter.setAttribute('src', src, img);
            viewWriter.setAttribute('alt', fileName, img);
            evt.stop();
          }
        })
      );

      // CKEditorInspector.attach('header-editor', editor);
      if (this.sendbirdchannel) {
        this.isUserJoined = this.SendbirdServiceService.isuserJoined(this.sendbirdchannel);
        if (this.isUserJoined) {
          this.ckeditorInstance.disableReadOnlyMode('cke');
          this.attachmentControl.enable();
          this.attachmentControl.setValue([]);
          this.messageControl.setValue('');
          this.subjectControl.enable();

          this.ckeditorInstance.ui.view.toolbar.items.get(18).isEnabled = true;
        } else {
          this.ckeditorInstance.enableReadOnlyMode('cke');
          this.ckeditorInstance.ui.view.toolbar.items.get(18).isEnabled = false;
          this.attachmentControl.disable();
          this.subjectControl.disable();
          this.attachmentControl.setValue([]);
          this.subjectControl.setValue('');
          this.messageControl.setValue('You can not send a message on this channel as you are not a participant.');
        }
      }
    }
  }

  public cancelButtonClick(): void {
    if (!this.caseId && !this.orgId) this.outSideClick.emit(true);

    // Closing the menu of ACC from cancel button click :
    this.menuTrigger.closeMenu();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, sonarjs/cognitive-complexity
  public async createMessageChannelCaseClick(event: any, userDetail: any): Promise<void> {
    this.guestUserDisableViaFlag = true;
    this.flagForSpinner = true;
    event.stopPropagation();
    this.nextButtonClickFlag = true;
    this.deleteChannelAPICallFlag = false;
    this.saveButtonEnableDisableFlagValue = false;

    await this.detailOfMessageCreationCases(true);

    let count = 0;
    this.DetailOfMessageCreationCase$.subscribe((DetailOfMessageCreationCase) => {
      if (DetailOfMessageCreationCase.Id && count === 1) {
        this.createChannel(DetailOfMessageCreationCase, userDetail.orgId, userDetail);
      }
      count++;
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public detailOfMessageCreationCases(createMessageCaseFlag: boolean): void {
    // For Dashboard / Invoices Tab in General / Messages Tab (left side) / New Quote Creation :
    if (
      (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
      (this.router.url.includes('message') && !this.router.url.includes('task')) ||
      (this.router.url.includes('invoices') &&
        !this.payloadOfDialog.id &&
        !this.payloadOfDialog.shipment &&
        !this.payloadOfDialog.shipment?.id) ||
      this.router.url.includes('basics') ||
      this.router.url.includes('product-listing')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag ? this.store$.dispatch(commonMessageAction.createMessageChannelCase({})) : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Quote in Quotes Tab / Shipment Order tab / Tracking Tab / In new quote - line-items :
    else if (
      this.router.url.includes('quotes') ||
      (this.router.url.includes('shipments-list') &&
        this.payloadOfDialog.shipment &&
        (!this.payloadOfDialog.shipment?.id || !this.payloadOfDialog.id)) ||
      this.router.url.includes('tracking') ||
      this.router.url.includes('line-items') ||
      this.router.url.includes('final-costs') ||
      this.router.url.includes('shipment-method')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(commonMessageAction.createMessageChannelCase({ SOID: this.payloadOfDialog.shipment.id }))
        : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Invoice on the Invoices Tab :
    else if (this.router.url.includes('invoices') && this.payloadOfDialog.id && this.payloadOfDialog.shipment.id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(
            commonMessageAction.createMessageChannelCase({
              SOID: this.payloadOfDialog.shipment.id,
              InvoiceID: this.payloadOfDialog.id,
            })
          )
        : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Shipment Order in the Shipment Orders tab / Task from the Dashboard Tab :
    else if (this.router.url.includes('shipments-list') && this.payloadOfDialog.shipment.id && this.payloadOfDialog.id) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(
            commonMessageAction.createMessageChannelCase({ SOID: this.payloadOfDialog.shipment.id, TaskID: this.payloadOfDialog.id })
          )
        : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Task from the Dashboard Tab :
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    else if (
      this.router.url.includes('dashboard') &&
      this.payloadOfDialog.shipment &&
      this.payloadOfDialog.shipment.id &&
      this.payloadOfDialog.id
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag
        ? this.store$.dispatch(
            commonMessageAction.createMessageChannelCase({ SOID: this.payloadOfDialog.shipment.id, TaskID: this.payloadOfDialog.id })
          )
        : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
    // For Specific Task from the Dashboard Tab :
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    else if (
      // eslint-disable-next-line sonarjs/no-identical-conditions
      this.router.url.includes('dashboard') &&
      this.router.url.includes('task') &&
      this.payloadOfDialog.id &&
      !this.payloadOfDialog.shipment &&
      !this.payloadOfDialog.shipment?.id
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      createMessageCaseFlag ? this.store$.dispatch(commonMessageAction.createMessageChannelCase({ TaskID: this.payloadOfDialog.id })) : '';
      this.DetailOfMessageCreationCase$ = null;
      this.DetailOfMessageCreationCase$ = this.store$.select(commonMessageReducer.selectDetailOfMessageCreationCase);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity, @typescript-eslint/explicit-module-boundary-types
  public createChannel(responseOfCreatedChannel: any, orgId: string, userDetail: any): void {
    this.deleteChannelAPICallFlag = false;
    let parentObjectDynamicValue: string;
    let parentNameDynamicValue: string;
    let parentIdDynamicValue: string;
    let shipmentOrderIdDynamicValue: string;
    let directRefUrlDynamicValue: any;

    if (
      (this.router.url.includes('shipments-list') && !this.router.url.includes('task') && !this.payloadOfDialog.invoiceName) ||
      (this.router.url.includes('quote') && !this.router.url.includes('-quote')) ||
      this.router.url.includes('tracking') ||
      this.router.url.includes('line-items') ||
      this.router.url.includes('final-costs') ||
      this.router.url.includes('shipment-method')
    ) {
      parentObjectDynamicValue = 'Shipment Order';
      directRefUrlDynamicValue = `${window.location.href.split(this.router.url)[0]}/shipments-list/${this.payloadOfDialog.shipment.id}`;

      parentIdDynamicValue = this.payloadOfDialog.shipment.id;
      shipmentOrderIdDynamicValue = this.payloadOfDialog.shipment.id;
      parentNameDynamicValue =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.title && this.payloadOfDialog.shipment.reference
          ? `${this.payloadOfDialog.shipment.title} - ${this.payloadOfDialog.shipment.reference}`
          : this.payloadOfDialog.shipment.title;
    } else if (
      (this.router.url.includes('invoices') &&
        (this.payloadOfDialog.id || this.payloadOfDialog.invoiceName) &&
        this.payloadOfDialog.shipment.id) ||
      (this.router.url.includes('shipments-list') &&
        this.payloadOfDialog.invoiceName &&
        this.payloadOfDialog.shipment &&
        this.payloadOfDialog.shipment.id)
    ) {
      parentObjectDynamicValue = 'Invoice';
      directRefUrlDynamicValue = `${window.location.href.split(this.router.url)[0]}/messages/${responseOfCreatedChannel.Id}`;
      shipmentOrderIdDynamicValue = this.payloadOfDialog.shipment.id;
      parentIdDynamicValue = this.payloadOfDialog.id;
      parentNameDynamicValue =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.title && this.payloadOfDialog.shipment.reference
          ? // eslint-disable-next-line max-len
            `${this.payloadOfDialog.shipment.title} - ${this.payloadOfDialog.shipment.reference} - Invoice ${this.payloadOfDialog.invoiceName}`
          : `${this.payloadOfDialog.shipment.title} - Invoice ${this.payloadOfDialog.invoiceName}`;
    } else if (
      (this.router.url.includes('shipments-list') &&
        this.router.url.includes('task') &&
        this.payloadOfDialog.shipment.id &&
        !this.payloadOfDialog.invoiceName) ||
      (this.router.url.includes('dashboard') && this.router.url.includes('task'))
    ) {
      parentObjectDynamicValue = 'Task';
      const url =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.id
          ? `${window.location.href.split(this.router.url)[0]}/shipments-list/${this.payloadOfDialog.shipment.id}#${
              window.location.href.split('#')[1]
            }`
          : `${window.location.href.split(this.router.url)[0]}/messages/${responseOfCreatedChannel.Id}`;
      directRefUrlDynamicValue = this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.id ? url.split('/message')[0] : url;
      shipmentOrderIdDynamicValue =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.id ? this.payloadOfDialog.shipment.id : null;
      parentIdDynamicValue = this.payloadOfDialog.id;
      parentNameDynamicValue =
        this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.parentName
          ? this.payloadOfDialog.shipment.parentName
          : this.payloadOfDialog.taskNameForAccountLevel;
    } else if (
      this.router.url.includes('message') ||
      (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
      this.router.url.includes('invoices') ||
      this.router.url.includes('basics') ||
      this.router.url.includes('product-listing')
    ) {
      parentObjectDynamicValue = 'Account';
      parentNameDynamicValue = userDetail.accountName;
      parentIdDynamicValue = userDetail.accountId;
      shipmentOrderIdDynamicValue = null;
      directRefUrlDynamicValue = `${window.location.href.split(this.router.url)[0]}/messages/${responseOfCreatedChannel.Id}`;
    }

    let users = [];

    for (const elementOfTaggedMember of this.listOfTaggedTeamMembers) {
      let userTypeDynamicValue: any = '';
      let userRoleDynamicValue: any = '';
      let userCompanyDynamicValue: any = '';
      let affiliationTypeDynamicValue: any = '';

      // For ACTecexPeople, SOTecexPeople and AllTecexPeople :
      if (
        this.listOfUsersForAcc.AllTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
        this.listOfUsersForAcc.ACTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
        this.listOfUsersForAcc.SOTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id)
      ) {
        userTypeDynamicValue = 'Internal';
        userRoleDynamicValue =
          this.listOfUsersForAcc.ACTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
          (this.listOfUsersForAcc.SOTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) && elementOfTaggedMember.Role)
            ? elementOfTaggedMember.Role
            : null;
        userCompanyDynamicValue = SourceApp.TecEx;
      }
      // For client user and client contacts :
      else if (
        this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
        this.listOfUsersForAcc.ClientContacts?.find((x: any) => x.contactID === elementOfTaggedMember.id)
      ) {
        userTypeDynamicValue = 'Client';
        userRoleDynamicValue = null;
        userCompanyDynamicValue = this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.UserID === elementOfTaggedMember.id)?.CompanyName
          ? this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.UserID === elementOfTaggedMember.id).CompanyName
          : this.listOfUsersForAcc.ClientContacts?.find((x: any) => x.contactID === elementOfTaggedMember.id).CompanyName;
      }
      // For third-party participants :
      else if (this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)) {
        userTypeDynamicValue = 'Affiliate';
        userRoleDynamicValue = null;
        userCompanyDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
          .ThirdPartyCompany;
        affiliationTypeDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
          .AffiliateType;
      }

      const objForUserList = {
        id:
          userTypeDynamicValue === 'Client'
            ? // eslint-disable-next-line unicorn/no-nested-ternary
              elementOfTaggedMember.userId
              ? elementOfTaggedMember.userId
              : elementOfTaggedMember.id
            : elementOfTaggedMember.id,
        email: elementOfTaggedMember.Useremail
          ? elementOfTaggedMember.Useremail
          : // eslint-disable-next-line unicorn/no-nested-ternary
          elementOfTaggedMember.userEmail
          ? elementOfTaggedMember.userEmail
          : elementOfTaggedMember.Email,
        nickname: elementOfTaggedMember.nickName
          ? elementOfTaggedMember.nickName
          : // eslint-disable-next-line unicorn/no-nested-ternary
          elementOfTaggedMember.FirstName && elementOfTaggedMember.LastName
          ? `${elementOfTaggedMember.FirstName} ${elementOfTaggedMember.LastName}`
          : elementOfTaggedMember.firstName,
        isMuted: elementOfTaggedMember.DefaultMuteStatus ? true : false,
        userType: userTypeDynamicValue ? userTypeDynamicValue : null,
        userRole: userRoleDynamicValue ? userRoleDynamicValue : null,
        userCompany: userCompanyDynamicValue ? userCompanyDynamicValue : null,
        affiliationType: affiliationTypeDynamicValue ? affiliationTypeDynamicValue : null,
        profileUrlWebApp: `${window.location.href.split(this.router.url)[0]}/profile/my-team`,
      };

      users.push(objForUserList);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (this.listOfGuestUsers.length > 0) users = users.concat(this.listOfGuestUsers);

    this.caseCreatedFlag = responseOfCreatedChannel.Id ? true : false;
    this.sendbirdchannel = responseOfCreatedChannel;

    const channelCreateWithUserDetail: any = {
      orgId: `${orgId}`,
      orgMyName: this.getDynamicOrgMyName(),
      caseId: responseOfCreatedChannel.Id,
      accountId: userDetail.accountId,
      sendbirdChannelUrl: `${orgId}-${responseOfCreatedChannel.Id}`,
      emailTicketId:
        this.sourceApp === 'TecEx' ? `${'TX'}${responseOfCreatedChannel.CaseNumber}` : `${'ZX'}${responseOfCreatedChannel.CaseNumber}`,
      channelName: '-= DRAFT =-',
      clientName: userDetail.accountName,
      lastActivityDate: utc(),
      lastModifiedBy: userDetail.id,
      lastEmailSent: null,
      accountTzLocation: responseOfCreatedChannel.Account.Preferred_Time_Zone__c,
      channelStatus: 'open',
      reOpenEnabled: false,
      businessUnit: this.sourceApp,

      // Dynamically manage via page wise :
      parentObject: parentObjectDynamicValue,
      parentName: parentNameDynamicValue,
      parentId: parentIdDynamicValue,
      shipmentOrderId: shipmentOrderIdDynamicValue,
      directRefUrl: directRefUrlDynamicValue,
      guestUserEnabled:
        // eslint-disable-next-line unicorn/no-nested-ternary
        typeof this.guestUserFormValue === 'boolean'
          ? this.guestUserFormValue
          : this.router.url.includes('invoices') ||
            (this.router.url.includes('shipments-list') &&
              this.payloadOfDialog.invoiceName &&
              this.payloadOfDialog.shipment &&
              this.payloadOfDialog.shipment.id)
          ? false
          : true,

      // add user dynamically :
      users,
    };

    /* Remove the code because of the passing caseID not,
     task or shipment order id : this.caseId = this.messageThread.id ? this.messageThread.id : responseOfCreatedChannel.Id; */

    this.caseId = responseOfCreatedChannel.Id;
    this.orgId = userDetail.orgId;

    this.messagingAWSService.channelCreate(channelCreateWithUserDetail, orgId).subscribe((data: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
      data ? '' : (this.existingChannelDetail = null);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
      this.router.url.includes('task')
        ? this.subjectControl.setValue(
            this.payloadOfDialog.shipment && this.payloadOfDialog.shipment.parentName
              ? this.payloadOfDialog.shipment.parentName
              : this.payloadOfDialog.taskNameForAccountLevel
          )
        : '';
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
      this.router.url.includes('task') ? this.subjectControl.disable() : '';
      this.flagForSpinner = false;
      this.ckeditorInstance.disableReadOnlyMode('cke');
      this.isUserJoined = true;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
      this.router.url.includes('task') ? '' : this.subjectControl.enable();
      this.menuTrigger.closeMenu();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, sonarjs/cognitive-complexity
  public async updateChannel(userDetail: any, sendBirdChannelObject: any): Promise<void> {
    this.saveButtonEnableDisableFlagValue = false;
    await this.detailOfMessageCreationCases(false);

    let count = 0;
    let responseOfCreatedChannel: any;

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.DetailOfMessageCreationCase$
      ? this.DetailOfMessageCreationCase$.subscribe((DetailOfMessageCreationCase) => {
          if (DetailOfMessageCreationCase.Id && count === 0) {
            responseOfCreatedChannel = DetailOfMessageCreationCase;
            count++;
          }
        })
      : null;

    if (responseOfCreatedChannel && responseOfCreatedChannel?.Id ? responseOfCreatedChannel?.Id : sendBirdChannelObject) {
      let users = [];

      for (const elementOfTaggedMember of this.listOfTaggedTeamMembers) {
        let userTypeDynamicValue: any = '';
        let userRoleDynamicValue: any = '';
        let userCompanyDynamicValue: any = '';
        let affiliationTypeDynamicValue: any = '';

        // For ACTecexPeople, SOTecexPeople and AllTecexPeople :
        if (
          this.listOfUsersForAcc.AllTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
          this.listOfUsersForAcc.ACTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
          this.listOfUsersForAcc.SOTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id)
        ) {
          userTypeDynamicValue = 'Internal';
          userRoleDynamicValue =
            this.listOfUsersForAcc.ACTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
            (this.listOfUsersForAcc.SOTecexPeople?.find((x: any) => x.UserID === elementOfTaggedMember.id) &&
              (elementOfTaggedMember.Role || elementOfTaggedMember.userRole))
              ? elementOfTaggedMember.Role || elementOfTaggedMember.userRole
              : null;
          userCompanyDynamicValue = SourceApp.TecEx;
        }
        // For client user and client contacts :
        else if (
          this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.UserID === elementOfTaggedMember.id) ||
          this.listOfUsersForAcc.ClientContacts?.find((x: any) => x.contactID === elementOfTaggedMember.id)
        ) {
          userTypeDynamicValue = 'Client';
          userRoleDynamicValue = null;
          userCompanyDynamicValue = this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.UserID === elementOfTaggedMember.id)?.CompanyName
            ? this.listOfUsersForAcc.ClientUsers?.find((x: any) => x.UserID === elementOfTaggedMember.id).CompanyName
            : this.listOfUsersForAcc.ClientContacts?.find((x: any) => x.contactID === elementOfTaggedMember.id).CompanyName;
        }
        // For third-party participants :
        else if (this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)) {
          userTypeDynamicValue = 'Affiliate';
          userRoleDynamicValue = null;
          userCompanyDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
            .ThirdPartyCompany;
          affiliationTypeDynamicValue = this.listOfUsersForAcc.ThirdPartyPeople?.find((x: any) => x.Id === elementOfTaggedMember.id)
            .AffiliateType;
        }

        let nickName;

        if (elementOfTaggedMember.FirstName && elementOfTaggedMember.LastName) {
          nickName = `${elementOfTaggedMember.FirstName} ${elementOfTaggedMember.LastName}`;
        } else if (elementOfTaggedMember.Name) {
          nickName = elementOfTaggedMember.Name;
        } else if (elementOfTaggedMember.firstName && elementOfTaggedMember.lastName) {
          nickName = `${elementOfTaggedMember.firstName} ${elementOfTaggedMember.lastName}`;
        } else if (elementOfTaggedMember.nickname) {
          nickName = elementOfTaggedMember.nickname;
        } else if (elementOfTaggedMember.AffiliateUserName) {
          nickName = elementOfTaggedMember.AffiliateUserName;
        }

        let email;

        if (elementOfTaggedMember.Useremail) {
          email = elementOfTaggedMember.Useremail;
        } else if (elementOfTaggedMember.userEmail) {
          email = elementOfTaggedMember.userEmail;
        } else if (elementOfTaggedMember.Email) {
          email = elementOfTaggedMember.Email;
        } else if (elementOfTaggedMember.email) {
          email = elementOfTaggedMember.email;
        } else if (elementOfTaggedMember.ThirdPartyEmail) {
          email = elementOfTaggedMember.ThirdPartyEmail;
        }

        let defaultMuteStatus;

        if (elementOfTaggedMember.DefaultMuteStatus) {
          defaultMuteStatus = true;
        } else if (elementOfTaggedMember.isMuted) {
          defaultMuteStatus = elementOfTaggedMember.isMuted;
        } else if (!elementOfTaggedMember.DefaultMuteStatus || !elementOfTaggedMember.isMuted) {
          defaultMuteStatus = false;
        }

        const objForUserList = {
          id: elementOfTaggedMember.id,
          email,
          nickname: nickName,
          isMuted: defaultMuteStatus,
          userType: userTypeDynamicValue,
          userRole: userRoleDynamicValue,
          userCompany: userCompanyDynamicValue,
          affiliationType: affiliationTypeDynamicValue,
          profileUrlWebApp: `${window.location.href.split(this.router.url)[0]}/profile/my-team`,
        };

        users.push(objForUserList);
      }

      this.caseId = responseOfCreatedChannel && responseOfCreatedChannel.Id ? responseOfCreatedChannel.Id : sendBirdChannelObject;
      this.orgId = userDetail.orgId;

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (this.listOfGuestUsers.length > 0) users = users.concat(this.listOfGuestUsers);

      const userListOfDetail: any = {
        // orgId-caseId = orgId and existing case id :
        sendbirdChannelUrl:
          sendBirdChannelObject || sendBirdChannelObject?.split('-').length === 1
            ? `${userDetail.orgId}-${sendBirdChannelObject}`
            : `${userDetail.orgId}-${responseOfCreatedChannel.Id}`,

        // add user dynamically :
        users,
      };

      this.messagingAWSService.updateChannelUserList(userListOfDetail, `${userDetail.orgId}`).subscribe((data: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        data
          ? this.SendbirdServiceService.getMessagesFromChannelUrlPromise(userListOfDetail.sendbirdChannelUrl).then(
              (dataOfExistingChannel: any) => {
                if (dataOfExistingChannel.channelInfo.users) {
                  this.sendbirdparticiapant = dataOfExistingChannel.channelInfo.users;

                  this.listOfTaggedTeamMembers = dataOfExistingChannel.channelInfo.users;
                }
              }
            )
          : null;
        this.menuTrigger.closeMenu();
      });
    }
  }

  // For getting the updated data / message from react / other channel : Start
  @HostListener('document:visibilitychange', ['$event'])
  public onVisibilityChange(): void {
    const channelUrl = this.sendbirdchannel?.url ? this.sendbirdchannel.url : `${this.orgId}-${this.sendbirdchannel?.Id}`;
    if (document.hidden) {
      // console.log('hidden');
    } else {
      this.SendbirdServiceService.getChannel(channelUrl).then((channel: any) => {
        this.sendbirdchannel = channel;
        this.SendbirdServiceService.getMessagesFromChannel(
          channel,
          (error: SendBird.SendBirdError, messages: Array<SendBird.UserMessage | SendBird.FileMessage | SendBird.AdminMessage>) => {
            if (!error) {
              this.sendbirdMessage = messages;
              this.cdr.markForCheck();
            }
          }
        );
      });
      this.SendbirdServiceService.registerEventHandlers((data: { event: string; data: any }) => {
        if (this.sendbirdchannel?.url && data.event === 'onMessageReceived' && data.data.channel.url === channelUrl) {
          this.sendbirdMessage.push(data.data.message);
          // When a message is received and loaded onto a page, it automatically scrolls :
          setTimeout(() => this.scrollToBottom());
          this.cdr.markForCheck();
        }
      });
      this.cdr.markForCheck();
    }
  }
  // For getting the updated data / message from react / other channel : End

  // For remove the created channel and abandoned process :
  public deleteCreatedChannelFromAWS(): void {
    let removeChannelCreated: any = null;
    if (this.orgId && this.caseId) {
      this.nextButtonClickFlag = false;
      removeChannelCreated = {
        sendbirdChannelUrl: `${this.orgId}-${this.caseId}`,
      };

      this.orgId = '';
      this.caseId = '';
      this.cdr.detectChanges();

      this.store$.dispatch(
        commonMessageAction.deleteMessageCases({
          CaseID: removeChannelCreated.sendbirdChannelUrl.split('-')[1],
          sendbirdChannelFullUrl: removeChannelCreated,
        })
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onTaggeedTeamMemberChanges(participants: any): void {
    this.listOfTaggedTeamMembers = participants;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onGuestUsersChanges(participants: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, prettier/prettier
    (participants && participants.length > 0) || this.listOfTaggedTeamMembers.length > 0
      ? (this.saveButtonEnableDisableFlagValue = true)
      : null;
    this.listOfGuestUsers = participants;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onListOfACCUsers(listOfUsers: any): void {
    this.listOfUsersForAcc = listOfUsers;
  }

  public tagParticipantsClick(): void {
    this.tagParticipantsClickFlag = true;
    this.cdr.detectChanges();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public clickForEditParticipants(): void {
    this.saveButtonEnableDisableFlagValue = false;
    this.editParticipantClickFlag = true;

    this.sendbirdMessageParticipantListComponent?.onCloseSearchClick();

    if (this.sendbirdparticiapant && this.sendbirdparticiapant.length > 0) {
      if (!this.router.url.includes('messages')) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (this.router.url.includes('dashboard') && !this.router.url.includes('task')) ||
        this.router.url.includes('basics') ||
        (this.router.url.includes('invoices') &&
          !this.payloadOfDialog.id &&
          !this.payloadOfDialog.invoiceName &&
          !this.payloadOfDialog.shipment?.id)
          ? this.store$.dispatch(commonMessageAction.loadAccountParticipants())
          : this.store$.dispatch(
              commonMessageAction.loadShipmentOrderParticipants({
                SOID:
                  this.messageThread.shipment && this.messageThread.shipment.id
                    ? this.messageThread.shipment.id
                    : this.router.url.split('/')[2],
              })
            );
      } else if (this.router.url.includes('messages')) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.messageThread.shipment && this.messageThread.shipment.id
          ? this.store$.dispatch(commonMessageAction.loadShipmentOrderParticipants({ SOID: this.messageThread.shipment.id }))
          : this.store$.dispatch(commonMessageAction.loadAccountParticipants());
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (this.orgId && this.caseId) || this.sendbirdExistingChannelUrl
      ? this.store$.dispatch(
          commonMessageAction.channelInfoForParticipantList({
            sendBirdChannelUrl: this.orgId && this.caseId ? `${this.orgId}-${this.caseId}` : this.sendbirdExistingChannelUrl,
          })
        )
      : null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getGuestUserValue(value: any): void {
    this.guestUserFormValue = value;
  }

  // for not closing the ACC modal : Start
  public fireEventForStopPropagation(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
  }
  // for not closing the ACC modal : End

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getSaveButtonFlagValue(flag: any): void {
    this.saveButtonEnableDisableFlagValue = flag;
  }

  public getDynamicOrgMyName(): string {
    if (window.location.href.includes('staging')) {
      return `${this.sourceApp}--Staging`;
    } else if (window.location.href.includes('merging')) {
      return `${this.sourceApp}--Merging`;
    } else if (window.location.href.includes('localhost')) {
      return `${this.sourceApp}--Local`;
    } else {
      return `${this.sourceApp}`;
    }
  }

  // For getting the message real-time access : Start
  public registerEventHandlers(): void {
    this.SendbirdServiceService.registerEventHandlers((data: { event: string; data: any }) => {
      if (this.sendbirdchannel?.url && data.event === 'onMessageReceived' && data.data.channel.url === this.sendbirdchannel.url) {
        this.sendbirdMessage.push(data.data.message);
        // When a message is received and loaded onto a page, it automatically scrolls :
        setTimeout(() => this.scrollToBottom());
        this.cdr.markForCheck();
      }
    });
  }
}
