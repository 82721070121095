<div class="dialog-container">
  <div class="dialog-content">
    <img [src]="imgPath" class="logo" alt="TecEx client portal" width="80" height="32" />

    <div class="text-center">
      <svg-icon key="exclamation-mark" class="icon"></svg-icon>
      <div class="text-increased-bold color-text-primary text-uppercase mt-4">{{ 'TERMS_AND_CONDITIONS.DIALOG_TITLE' | translate }}</div>
      <p *ngIf="gracePeriod; else no_grace_period_message" class="text-reduced-regular">
        {{ 'TERMS_AND_CONDITIONS.DIALOG_MESSAGE_GRACE_PERIOD' | translate: { gracePeriodDate: textDate } }}
      </p>

      <ng-template #no_grace_period_message>
        <p class="text-reduced-regular">
          {{ 'TERMS_AND_CONDITIONS.DIALOG_MESSAGE_NO_GRACE_PERIOD' | translate }}
        </p>
      </ng-template>

      <p>
        <a [href]="termLink" class="text-reduced-regular" target="_blank">{{ 'TERMS_AND_CONDITIONS.TERMS_AND_CONDITIONS' | translate }}</a>
      </p>

      <p>
        <a [href]="privacyLink" class="text-reduced-regular" target="_blank">{{ 'TERMS_AND_CONDITIONS.PRIVACY_POLICY' | translate }}</a>
      </p>

      <p class="text-tiny-medium">
        {{ 'TERMS_AND_CONDITIONS.CONTACT_DETAILS' | translate }}
      </p>
    </div>

    <div class="text-tiny-regular mt-4 color-text-primary">
      {{ 'TERMS_AND_CONDITIONS.DIALOG_SIGNATURE' | translate }}
      <div class="text-tiny-medium">{{ 'TERMS_AND_CONDITIONS.DIALOG_SIGNATURE_TEAM' | translate }}</div>
    </div>
  </div>

  <div class="dialog-footer d-flex align-items-center justify-content-center py-3">
    <button *ngIf="gracePeriod" mat-button class="secondary-button" (click)="onDismissClick()">
      {{ 'TERMS_AND_CONDITIONS.DIALOG_DISMISS_BUTTON' | translate }}
    </button>
    <button mat-button class="primary-button ml-4" (click)="onAcceptClick()">
      {{ 'TERMS_AND_CONDITIONS.DIALOG_ACCEPT_BUTTON' | translate }}
    </button>
  </div>
</div>
