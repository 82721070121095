import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MessageThreadMessage } from '../../../../interfaces/messages/message-thread-message.interface';
import { SendbirdMessageDatePipe } from '../../../../pipes/sendbird-message-date.pipe';
import { ProfilePicturePipe } from '../../../../pipes/profile-picture.pipe';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf, NgStyle, NgClass } from '@angular/common';

@Component({
  selector: 'app-sendbird-message',
  templateUrl: './sendbird-message.component.html',
  styleUrls: ['./sendbird-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgStyle, NgClass, SvgIconComponent, MatDialogModule, ProfilePicturePipe, SendbirdMessageDatePipe],
})
export class SendbirdMessageComponent implements OnChanges {
  @Input() public message: MessageThreadMessage;
  @Input() public sendbirdmessage: any;
  @Input() public sendbirdMessageArray: any[];
  @Input() public userId: any;
  @Output() public S3fileMessageDownload = new EventEmitter<any>();

  public body: SafeHtml;

  constructor(
    private readonly domSanitizer: DomSanitizer,
    public dialog: MatDialog
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.sendbirdmessage) {
      const sendbirdMessage = this.sendbirdmessage.message?.includes('img')
        ? this.replaceSrc(this.sendbirdmessage.message)
        : this.sendbirdmessage.message;
      this.body = this.domSanitizer.bypassSecurityTrustHtml(sendbirdMessage);
    }
  }

  public onS3fileMessageDownloadClick(s3ObjectKey: string): void {
    this.S3fileMessageDownload.emit({
      objectKey: s3ObjectKey,
    });
  }

  public popupImage: any;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public openPopup(data: any): void {
    this.dialog.open(data);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getImage(data: any, templateRef: any): void {
    if (data.target.localName === 'img') {
      this.popupImage = this.sendbirdMessageArray.filter((test) => test.messageId === Number(data.target.id.replace('_thumbnail', '')));
      this.openPopup(templateRef);
    }
  }

  public replaceSrc(htmlString: string): string {
    const newdataRaw = htmlString.split(/(<img[^>]*>)/g);
    const newD = [];
    return this.ParseChat(newdataRaw, newD, 0);
  }

  public ParseChat(rawHtml: Array<any>, newD: Array<string>, iter: number): string {
    if (rawHtml.length === iter) {
      return newD.join('');
    } else if (iter <= rawHtml.length && rawHtml[iter]?.startsWith('<img')) {
      const parser = new DOMParser();
      const fileStr = parser.parseFromString(rawHtml[iter], 'text/html').querySelector('img');
      const imageSrc = this.sendbirdMessageArray.find((test) => test.messageId === Number(fileStr.id.replace('_thumbnail', '')))
        ?.thumbnails[1]?.url;
      const script = `
        <img id="${fileStr.id}"  src=${imageSrc}>`;
      newD.push(script.replaceAll(/\s+/g, ' ').trim());

      iter += 1;
      return this.ParseChat(rawHtml, newD, iter);
    } else {
      const data = rawHtml[iter];
      iter += 1;
      newD.push(data);
      return this.ParseChat(rawHtml, newD, iter);
    }
  }
}
