import { GetUsersResponseItem } from '@tecex-api/data';
import { Participant } from '../interfaces/participant.interface';

export const mapUserToParticipant = (contact: GetUsersResponseItem): Participant => ({
  id: contact.UserID,
  firstName: contact.FirstName,
  lastName: contact.LastName,
  photoUrl: contact.FullPhotoUrl,
  isOutOfOffice: contact.OutOfOfficeIsEnabled,
  outOfOfficeText: contact.OutOfOfficeMessage,
  standByPersonId: contact.StandbyUserID,
  standByPersonName: contact.StandbyUserName,
  roleOfParticipant: contact.Role,
  userEmail: contact.Useremail,
  nickName: `${contact.FirstName} ${contact.LastName}`,
});
