import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { AuthService } from './auth.service';
import Hotjar from '@hotjar/browser';
import { TokenConfigService } from './token-config.service';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HotjarService {
  private initialized = false;

  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    // Settings are actually provided in AppModule because of how Ngx Hotjar works.
    // Check this thread for more details: https://github.com/maxandriani/ngx-hotjar/issues/28
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly authService: AuthService,
    private readonly injector: Injector,
    private readonly tokenConfigService: TokenConfigService
  ) {
    const tokenUser$ = combineLatest([this.tokenConfigService.getTokens$(), this.authService.getUser$()]);

    tokenUser$.subscribe((response) => {
      (async () => {
        if (!this.initialized) {
          Hotjar.init(response[0].hotjarSiteId as unknown as number, 6);
          this.initialized = true;
        }
        Hotjar.identify(response[1].id, {
          email: response[1].email,
          user_id: response[1].id,
          account_id: response[1].accountId,
        });
      })();
    });
  }
}
