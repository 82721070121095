import { ComponentType } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { MessageButtonUserVM } from '../../../message-button/user.vm';
import { SHIPMENT_SUMMARY_COMPONENT_TYPE } from '../../tokens/shipment-summary-component-type.token';
import { ShipmentSummaryComponentType } from '../../types/shipment-summary-component-type.interface';
import { TaskVM } from '../../types/task.vm';
import { TaskBlocksComponent } from '../task-blocks/task-blocks.component';
import { TasksDialogPayload } from './tasks-dialog-payload.interface';
import { MessageThreadParticipantsPipe } from '../../../../pipes/message-thread-participants.pipe';
import { ProfilePicturePipe } from '../../../../pipes/profile-picture.pipe';
import { TooltipDirective } from '../../../tooltip/tooltip.directive';
import { MatButtonModule } from '@angular/material/button';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgIf } from '@angular/common';
import { MessageButtonComponent } from '../../../message-button/message-button.component';

@Component({
  selector: 'app-tasks-dialog',
  templateUrl: './tasks-dialog.component.html',
  styleUrls: ['./tasks-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MessageButtonComponent,
    NgIf,
    TaskBlocksComponent,
    SvgIconComponent,
    MatButtonModule,
    TooltipDirective,
    ProfilePicturePipe,
    MessageThreadParticipantsPipe,
    TranslateModule,
  ],
})
export class TasksDialogComponent implements OnDestroy, AfterViewInit {
  @ViewChild(TaskBlocksComponent) public taskBlocks: TaskBlocksComponent;
  @ViewChild('shipmentSummary', { read: ViewContainerRef }) public shipmentSummaryContainerRef: ViewContainerRef;

  public areBlocksValid = this.task.blocks.length > 0 ? false : true;
  //public areBlocksValid =  false;
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private readonly translateService: TranslateService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DIALOG_DATA) private readonly data: DialogData<TasksDialogPayload, any[]>,
    @Inject(SHIPMENT_SUMMARY_COMPONENT_TYPE) private readonly shipmentSummaryComponentType: ComponentType<ShipmentSummaryComponentType>
  ) {}

  public ngAfterViewInit(): void {
    if (this.showShipmentOrderBox && this.task.shipmentOrder) {
      this.initShipmentSummaryComponent();
    }
  }

  public get showShipmentOrderBox(): boolean {
    return this.data.payload.showShipmentOrderBox;
  }

  public get task(): TaskVM {
    return this.data.payload.task;
  }

  public get defaultTeamMember(): MessageButtonUserVM {
    return this.data.payload.defaultTeamMember;
  }

  public get buttonLabel(): string {
    if (this.task.buttonLabel) {
      return this.task.buttonLabel;
    }

    if (this.task.blocks.length > 0) {
      return this.translateService.instant('COMMON.SUBMIT');
    }

    return this.translateService.instant('TASKS.NOTED');
  }

  public onMessageClick(): void {
    this.data.payload.onMsgClickHandler();
  }

  public onBlocksValidityChange(valid: boolean): void {
    this.areBlocksValid = valid;
  }

  public onCancelClick(): void {
    this.data.dialogRef.close();
  }

  public onSubmitClick(): void {
    this.data.dialogRef.close(this.taskBlocks.getValue());
  }

  public initShipmentSummaryComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.shipmentSummaryComponentType);
    const componentRef = this.shipmentSummaryContainerRef.createComponent<ShipmentSummaryComponentType>(componentFactory);
    componentRef.instance.shipmentOrder = this.task.shipmentOrder;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
