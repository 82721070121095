import { Inject, Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CasesDataService,
  ProfileDataService,
  SendMessageDataService,
  ShipmentOrder,
  ShipmentOrderDataService,
  TasksDataService,
} from '@tecex-api/data';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { map, mapTo, switchMap } from 'rxjs/operators';
import { RePollService } from '../../../../../../projects/zee/src/app/shared/services/re-poll.service';
import { CONFIG_TOKEN } from '../../../config/config.token';
import { GlobalConfig } from '../../../config/global-config.interface';
import { MessageThreadType } from '../../../enums/message-thread-type.enum';
import { mapMessages } from '../../../helpers/messages/map-messages.helper';
import { MessageThreadDetails } from '../../../interfaces/messages/message-thread-details.interface';
import { Participant } from '../../../interfaces/participant.interface';
import { AuthService } from '../../../services/auth.service';
import { TeamMemberService } from '../../../services/team-member.service';
import { ToastMessageService } from '../../toast-message/toast-message.service';
import { TokenConfigService } from '../../../services/token-config.service';
import { MessageService } from './message.service';

@Injectable()
export class TaskMessageService extends MessageService {
  constructor(
    ngZone: NgZone,
    authService: AuthService,
    profileDataService: ProfileDataService,
    casesDataService: CasesDataService,
    sendMessageDataService: SendMessageDataService,
    teamMemberService: TeamMemberService,
    toastMessageService: ToastMessageService,
    translateService: TranslateService,
    tokenConfigService: TokenConfigService,
    @Inject(CONFIG_TOKEN) config: GlobalConfig,
    private readonly tasksDataService: TasksDataService,
    private readonly shipmentOrderDataService: ShipmentOrderDataService,
    private readonly rePollService: RePollService
  ) {
    super(
      ngZone,
      authService,
      profileDataService,
      casesDataService,
      sendMessageDataService,
      teamMemberService,
      toastMessageService,
      translateService,
      tokenConfigService,
      config
    );
  }

  public createThread$(): Observable<MessageThreadDetails> {
    return throwError('Creating threads for tasks is not supported.');
  }

  public getThread$(id: string, initial = false): Observable<MessageThreadDetails> {
    if (initial) {
      // This one has a really bad effect on the load time,
      // so we decided to let it happen in the background.
      this.updateClientViewedTime$(id).subscribe();
    }

    return this.authService.getUser$().pipe(
      switchMap((user) =>
        forkJoin([
          this.tasksDataService.getTask({
            AccessToken: user.accessToken,
            TaskID: id,
          }),
        ]).pipe(
          switchMap(([taskDetails]) =>
            this.getReplies$(taskDetails.FeedsOnTask).pipe(
              map((feedItems) => ({
                ...taskDetails,
                FeedsOnTask: feedItems,
              }))
            )
          ),
          switchMap((response) => {
            const users$ = this.getUsersFromMessages$(response.FeedsOnTask);

            const shipmentOrder$: Observable<ShipmentOrder | null> =
              // Onboarding tasks are special type of tasks that belongs to an account and not a shipment order.
              response.task?.Task_Category__c === 'Onboarding'
                ? of(null)
                : this.rePollService.pollSoDetails$({
                    accessToken: user.accessToken,
                    accountId: user.accountId,
                    contactId: user.contactId,
                    shipmentOrderId: response.task.Shipment_Order__c,
                  });

            return forkJoin([users$, shipmentOrder$]).pipe(
              map(([users, shipmentOrder]) => ({
                id: response.task.Id,
                type: MessageThreadType.Task,
                subject: response.template.taskTitle,
                photoUrl: undefined,
                messages: mapMessages(response.FeedsOnTask, users, user.id),
                participants:
                  response.Participants?.map((participant) => ({
                    id: participant.Id,
                    firstName: participant.Firstname,
                    lastName: participant.Lastname,
                  })) || [],
                shipment: response.task.Shipment_Order__c &&
                  shipmentOrder && {
                    id: response.task.Shipment_Order__r.Id,
                    title: shipmentOrder.NCP_Quote_Reference__c,
                    reference: shipmentOrder.Client_Reference__c,
                  },
              }))
            );
          })
        )
      )
    );
  }

  public addParticipants$(recordId: string, participants: Participant[]): Observable<void> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.tasksDataService
          .addParticipants({
            Accesstoken: user.accessToken,
            AccountID: user.accountId,
            RecordID: recordId,
            contactId: user.contactId,
            NotifyContacts: participants.map((participant) => ({
              NotifyContactID: participant.id,
            })),
          })
          .pipe(mapTo(undefined))
      )
    );
  }
}
