export const mapLastMessage = (createdDate: string, lastClientMessageTime: string, lastMessageTime: string): Date => {
  const dates: Date[] = [new Date(createdDate)];

  if (lastClientMessageTime) {
    dates.push(new Date(lastClientMessageTime));
  }

  if (lastMessageTime) {
    dates.push(new Date(lastMessageTime));
  }

  return new Date(Math.max(...dates.map((date) => date.getTime())));
};

export const mapIsUnread = (lastClientViewedTime: string, lastInternalMessageTime: string): boolean => {
  if (!lastInternalMessageTime) {
    return false;
  }

  if (lastInternalMessageTime && !lastClientViewedTime) {
    return true;
  }

  const latestInternalDate = new Date(lastInternalMessageTime);
  const latestViewDate = new Date(lastClientViewedTime);

  return latestViewDate < latestInternalDate;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mapSendbirdMessageIsUnread = (SendbirdChannelUrl: string, sendbirdChannelArray): boolean => {
  return sendbirdChannelArray.filter((e) => e.url === SendbirdChannelUrl).length > 0 ? true : false;
};
