import { NgModule } from '@angular/core';
import { GlobalModule } from '../../global.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { SelectionComponent } from './selection.component';

@NgModule({
  imports: [GlobalModule, SvgIconsModule, SelectionComponent],
  exports: [SelectionComponent],
})
export class SelectionModule {}
