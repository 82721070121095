import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TrackingState } from '../../enums/tracking-state.enum';
import { mapTrackerTitle } from '../../helpers/map-tracker-title.helper';
import { TrackerData } from './tracker-data';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor, DatePipe, TranslateModule],
})
export class TrackerComponent implements OnChanges {
  @Input() public state: TrackingState;
  @Input() public size: 'small' | 'large' = 'small';
  @Input() public date?: Date | string;
  public title: string;
  public readonly TrackingState = TrackingState;

  public completionMap = new Map<TrackingState, boolean>();

  public trackerSteps: TrackerData[] = [
    {
      state: TrackingState.InTransit,
      title: 'TRACKING.STATES.IN_TRANSIT',
    },
    {
      state: TrackingState.ArrivedInCountry,
      title: 'TRACKING.STATES.ARRIVED_IN_COUNTRY',
    },
    {
      state: TrackingState.ClearedCustoms,
      title: 'TRACKING.STATES.CLEARED_CUSTOMS',
    },
    {
      state: TrackingState.FinalDeliveryInProgress,
      title: 'TRACKING.STATES.FINAL_DELIVERY',
    },
    {
      state: TrackingState.Delivered,
      title: 'TRACKING.STATES.DELIVERED',
    },
  ];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      this.title = mapTrackerTitle(this.state);
      this.mapCompletion();
    }
  }

  public mapCompletion(): void {
    const { completionMap } = this.trackerSteps.reduce(
      (completionAcc, step) => ({
        reachedCompletedState: completionAcc.reachedCompletedState || step.state === this.state,
        completionMap: completionAcc.completionMap.set(step.state, !completionAcc.reachedCompletedState),
      }),
      { reachedCompletedState: false, completionMap: new Map<TrackingState, boolean>() }
    );
    this.completionMap = completionMap;
  }

  public isStateCompleted(state: TrackingState): boolean {
    return this.completionMap.get(state) || false;
  }
}
