import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientVettingStatus, TasksDataService, TaskState } from '@tecex-api/data';
import isUndefined from 'lodash/isUndefined';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { distinctUntilChanged, first, map, skip, switchMap } from 'rxjs/operators';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { OnboardingPercentages } from '../enums/onboarding-percentages.enum';
import { mapTasks } from '../helpers/map-tasks.helper';
import { User } from '../interfaces/user.interface';
import { DashboardYourTaskVM } from '../modules/common-dashboard/interfaces/dashboard-your-task.vm';
import { AuthService } from './auth.service';
import { TokenConfigService } from './token-config.service';

export interface OnboardingStatus {
  percentage: OnboardingPercentages;
  processText: string;
  dashboardLinkVisible: boolean;
}

@Injectable()
export class OnboardingService {
  private readonly initialState: OnboardingStatus = {
    percentage: OnboardingPercentages.ZeroConditionMatch,
    processText: '',
    dashboardLinkVisible: false,
  };

  private readonly _isOnboardingVisible$ = new BehaviorSubject<boolean>(false);
  private readonly _currentOnboardingStatus$ = new BehaviorSubject<OnboardingStatus>(this.initialState);

  public isOnboardingVisible$ = this._isOnboardingVisible$.asObservable();
  public currentOnboardingStatus$ = this._currentOnboardingStatus$.asObservable();
  public verifyAccountTaskId;

  public get isOnboardingVisible(): boolean {
    return this._isOnboardingVisible$.value;
  }

  constructor(
    private readonly authService: AuthService,
    private readonly tasksDataService: TasksDataService,
    private readonly translateService: TranslateService,
    private readonly tokenConfigService: TokenConfigService,
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig
  ) {
    this.tokenConfigService.getTokens$().subscribe((tokens) => {
      this.verifyAccountTaskId = tokens.verifyAccountTask;
    });

    this.authService
      .getUser$()
      .pipe(distinctUntilChanged(), skip(1))
      .subscribe((user) => {
        this.setVisibility(user);
        if (this._isOnboardingVisible$.value) {
          this.refreshOnboardingStatus(user);
        }
      });
  }

  private setVisibility(user: User) {
    const visible =
      !user.isVetted ||
      !user.contractSigned ||
      [ClientVettingStatus.IN_PROGRESS, ClientVettingStatus.REJECT, null, undefined].includes(user.vettingStatus);
    this._isOnboardingVisible$.next(visible);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public refreshOnboardingStatus(user?: User, tasks?: DashboardYourTaskVM[]) {
    (isUndefined(user) ? this.authService.getUser$() : of(user))
      .pipe(
        switchMap((result) =>
          forkJoin([
            of(result),
            isUndefined(tasks)
              ? this.tasksDataService
                  .getDashboardTasks({
                    Accesstoken: result.accessToken,
                    UserID: result.id,
                    UserContactID: result.contactId,
                    AccountID: result.accountId,
                  })
                  .pipe(map(({ Tasks }) => mapTasks(Tasks, result.id)))
              : of(tasks),
          ])
        ),
        first()
      )
      .subscribe(([resultUser, resultTasks]) => {
        this.setVisibility(resultUser);
        this.setOnboardingStatus(resultUser, resultTasks);
      });
  }

  private setOnboardingStatus(user: User, tasks: DashboardYourTaskVM[]) {
    this._currentOnboardingStatus$.next({
      percentage: this.getPercentage(user),
      ...this.getProcessTextAndLinkVisibility(user, tasks),
    });
  }

  // @ts-ignore
  private getPercentage(user: User): OnboardingPercentages {
    const vettingStatusCondition = [ClientVettingStatus.ACCEPT, ClientVettingStatus.ONGOING_MONITORING].includes(user.vettingStatus);
    const matchingConditions = [user.isVetted, user.contractSigned, vettingStatusCondition].filter(Boolean).length;
    switch (matchingConditions) {
      case 0: {
        return OnboardingPercentages.ZeroConditionMatch;
      }
      case 1: {
        return OnboardingPercentages.OneConditionMatch;
      }
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      case 2: {
        return OnboardingPercentages.TwoConditionMatch;
      }
    }
  }

  private getProcessTextAndLinkVisibility(
    user: User,
    tasks: DashboardYourTaskVM[]
  ): Pick<OnboardingStatus, 'processText' | 'dashboardLinkVisible'> {
    if (user.isVetted && user.contractSigned) {
      return { processText: this.translateService.instant('ONBOARDING.TEAM_IS_WORKING_NOTICE'), dashboardLinkVisible: false };
    }
    const verifyAccountTask = tasks.find((task) => task.masterTaskId === this.verifyAccountTaskId);
    if (!user.isVetted && verifyAccountTask) {
      if (verifyAccountTask.state === TaskState.UNDER_REVIEW) {
        return { processText: this.translateService.instant('ONBOARDING.UNDER_REVIEW'), dashboardLinkVisible: false };
      }
      return { processText: this.translateService.instant('ONBOARDING.APPLY_FOR_FULL_ACCESS_NOTICE'), dashboardLinkVisible: true };
    }
    const signContractTask = tasks.find((task) => task.masterTaskId === this.config.signContractTaskMasterId);
    if (!user.contractSigned && signContractTask) {
      if (signContractTask.state === TaskState.UNDER_REVIEW) {
        return { processText: this.translateService.instant('ONBOARDING.UNDER_REVIEW'), dashboardLinkVisible: false };
      }
      return { processText: this.translateService.instant('ONBOARDING.SIGN_CONTRACT_NOTICE'), dashboardLinkVisible: true };
    }
    return { processText: this.translateService.instant('ONBOARDING.UNDER_REVIEW'), dashboardLinkVisible: false };
  }
}
