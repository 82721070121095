import { LithiumBatteryTypes, ShipmentOrder, TypeOfGoods, UpdateShipmentOrderRequest, YesNo } from '@tecex-api/data';

export const mapShipmentOrderToUpdateRequest = (shipmentOrder: ShipmentOrder): UpdateShipmentOrderRequest => ({
  Reference1: shipmentOrder.Client_Reference__c,
  Reference2: shipmentOrder.Client_Reference__2__c,
  Shipment_Value_USD: shipmentOrder.Shipment_Value_USD__c,
  LionBatteries: shipmentOrder.Li_ion_Batteries__c,
  LionBatteriestype: shipmentOrder.Li_ion_Batteries__c === YesNo.YES ? LithiumBatteryTypes.ION_BATTERIES : undefined,
  TypeOfGoods: shipmentOrder.Type_of_Goods__c || TypeOfGoods.NEW,
  ServiceType: shipmentOrder.Service_Type__c,
  ShipFromCountry: shipmentOrder.Ship_From_Country__c,
  ChargeableWeight: shipmentOrder.Chargeable_Weight__c,
  FinalDeliveries: shipmentOrder.Final_Deliveries_New__c,
  BuyerAccount: shipmentOrder.Buyer_Account__c,
  NCPClientNotes: shipmentOrder.NCP_Client_Notes__c,
  ShippingStatus: shipmentOrder.Shipping_Status__c,
  ClientContactForShipment: shipmentOrder.Client_Contact_for_this_Shipment__c,
  preferredFreight: shipmentOrder.Preferred_Freight_method__c,
  Intention_with_Goods: shipmentOrder.Intention_with_Goods__c,
  Intention_with_Goods_Other: shipmentOrder.Intention_with_Goods_Other__c,
  Contact_name: shipmentOrder.Contact_name__c,
  Beneficial_Owner_Country: shipmentOrder.Beneficial_Owner_Country__c,
  Beneficial_Owner_Company_Address: shipmentOrder.Beneficial_Owner_Company_Address__c,
  Buyer_or_BO_VAT_Number: shipmentOrder.Buyer_or_BO_VAT_Number__c,
  Buyer_Retains_Ownership: shipmentOrder.Buyer_Retains_Ownership__c,
  BuyerBeneficialOwnerPostalCode: shipmentOrder.Buyer_Beneficial_Owner_Postal_Code__c,
  BuyerBeneficialOwnerCity: shipmentOrder.Buyer_Beneficial_Owner_City__c,
});
