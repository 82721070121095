import { ChangeDetectionStrategy, Component } from '@angular/core';
import { helpMenuItem } from '@global/constants/global.constants';
import { RouteSegment } from '@global/enums/route-segment.enum';
import { logOutLink, profileLink, profileMenuItems, settingsMenuItems } from '@modules/profile/data/profile-menu-items';
import { navMenuItems } from '@shared/constants/app.constants';
import { VERSION } from '../../../version';
// import { IntercomLauncherComponent } from '../../../../../../common/src/global/components/intercom-launcher/intercom-launcher.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../../../../../../common/src/global/modules/app-nav/components/header/header.component';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  // imports: [HeaderComponent, RouterOutlet, IntercomLauncherComponent]
  imports: [HeaderComponent, RouterOutlet],
})
export class PageLayoutComponent {
  public readonly version = VERSION;
  public readonly settingsMenuItems = settingsMenuItems;
  public readonly profileMenuItems = profileMenuItems;
  public readonly logOutLink = logOutLink;
  public readonly profileLink = profileLink;
  public readonly menuItems = navMenuItems;
  public readonly helpMenuItem = helpMenuItem;
  public readonly logo = {
    logo: 'assets/icons/logo.svg',
    link: [RouteSegment.Dashboard],
    alt: 'Zee client portal',
  };
}
