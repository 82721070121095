import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import isNil from 'lodash/isNil';
import mixpanel from 'mixpanel-browser';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { MixpanelEvent } from '../enums/mixpanel-event.enum';
import { RouteSegment } from '../enums/route-segment.enum';
import { SourceApp } from '../enums/source-app.enum';
import { SOURCE_APP } from '../tokens/source-app.token';
import { AuthService } from './auth.service';
import { TokenConfigService } from './token-config.service';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private initialized = false;

  constructor(
    @Inject(SOURCE_APP) private readonly sourceApp: SourceApp,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly tokenConfigService: TokenConfigService
  ) {}

  public startTrack() {
    const tokenUser$ = combineLatest([
      this.tokenConfigService.getTokens$(),
      this.authService.getUser$().pipe(
        distinctUntilChanged(),
        filter((user) => !isNil(user))
      ),
    ]);

    tokenUser$.subscribe((response) => {
      if (!this.initialized) {
        mixpanel.init(response[0].mixpanelToken, { debug: false });
        mixpanel.register({
          flavour: SourceApp.Zee.toUpperCase(),
          platform: 'web',
        });
        this.initialized = true;
      }
      mixpanel.identify(response[1].id);
    });

    this.authService.logout$.subscribe(() => mixpanel.reset());

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter(
          (event) =>
            !(event as NavigationEnd).url.includes(RouteSegment.Logout) &&
            !(event as NavigationEnd).url.includes(RouteSegment.LoginCallback)
        )
      )
      .subscribe((routerEvent) => this.track(MixpanelEvent.ScreenView, { screen_name: (routerEvent as NavigationEnd).url }));
  }

  public track(action: MixpanelEvent, properties?: { [key: string]: any }): void {
    if (this.initialized) {
      mixpanel.track(action, properties);
    }
  }
}
