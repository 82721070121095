export enum BlockType {
  Instruction = 'Instruction',
  IsntructionImage = 'IsntructionImage',
  Upload = 'Upload',
  Download = 'Download',
  Input = 'Input',
  Datepicker = 'Datepicker',
  Select = 'Select',
  MultiSelect = 'MultiSelect',
  Switch = 'Switch',
  PickUpAddress = 'PickUpAddress',
  ShipToAddress = 'ShipToAddress',
  Packages = 'Packages',
  Battery = 'Battery',
  PartSubCategoryTable = 'PartSubCategoryTable',
  PickupPreference = 'PickupPreference',
}
