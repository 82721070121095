import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { PreInspectionFacilitator } from '../../enums/pre-inspection-facilitator.enum';
import { PreInspectionSteps } from '../../enums/pre-inspection-steps.enum';
import { PreInspectionDialogPayload } from './pre-inspection-dialog-payload.interface';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '../../../tooltip/tooltip.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionComponent } from '../../../selection/selection.component';
import { ShipmentSummaryComponent } from '../shipment-summary/shipment-summary.component';
import { MessageButtonComponent } from '../../../message-button/message-button.component';
import { NgSwitch, NgSwitchCase, NgIf } from '@angular/common';

enum Step {
  ChooseFacilitator,
  TecExConfirmation,
  ClientConfirmation,
}

@Component({
  selector: 'app-pre-inspection',
  templateUrl: './pre-inspection-dialog.component.html',
  styleUrls: ['./pre-inspection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    MessageButtonComponent,
    NgIf,
    ShipmentSummaryComponent,
    SelectionComponent,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    TooltipDirective,
    TranslateModule,
  ],
})
export class PreInspectionDialogComponent implements OnInit {
  public currentStep: PreInspectionSteps = PreInspectionSteps.First;
  public step = Step.ChooseFacilitator;

  public readonly Step = Step;
  public readonly PreInspectionFacilitator = PreInspectionFacilitator;

  public formGroup = new FormGroup({
    preInscpectionFacilitator: new FormControl(undefined, [Validators.required]),
    clientCompleted: new FormControl(false),
  });

  public get preInscpectionFacilitatorControl(): UntypedFormControl {
    return this.formGroup.controls.preInscpectionFacilitator as UntypedFormControl;
  }

  public get clientCompletedControl(): UntypedFormControl {
    return this.formGroup.controls.clientCompleted as UntypedFormControl;
  }

  constructor(@Inject(DIALOG_DATA) public readonly data: DialogData<PreInspectionDialogPayload>) {}

  public ngOnInit(): void {
    if (this.data.payload.isInactive) {
      this.formGroup.disable();
    }
  }

  public onMessageClick(): void {
    this.data.payload.onMsgClickHandler();
  }

  public onNextClick(): void {
    if (this.preInscpectionFacilitatorControl.value === PreInspectionFacilitator.Tecex) {
      this.step = Step.TecExConfirmation;
    } else {
      this.step = Step.ClientConfirmation;
      this.clientCompletedControl.setValidators(Validators.requiredTrue);
    }
  }

  public onSubmitClick(): void {
    this.data.dialogRef.close(this.preInscpectionFacilitatorControl.value);
  }

  public onCancelClick(): void {
    this.data.dialogRef.close();
  }
}
