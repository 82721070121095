import { LongCurrencyCode } from '@tecex-api/data';
import { CurrencyCode } from '../enums/currency-code.enum';

export const mapFromLongCurrencyCode = (value: LongCurrencyCode | string): CurrencyCode => {
  switch (value) {
    case LongCurrencyCode.EURO__EUR: {
      return CurrencyCode.EUR;
    }
    case LongCurrencyCode.JAPANESE_YEN__JPY: {
      return CurrencyCode.JPY;
    }
    case LongCurrencyCode.AUSTRALIAN_DOLLAR__AUD: {
      return CurrencyCode.AUD;
    }
    case LongCurrencyCode.US_DOLLAR__USD: {
      return CurrencyCode.USD;
    }
    case LongCurrencyCode.CHINESE_YUAN__CNY: {
      return CurrencyCode.CNY;
    }
    case LongCurrencyCode.BRITISH_POUND__GBP: {
      return CurrencyCode.GBP;
    }
    case LongCurrencyCode.UAE_DIRHAM__AED: {
      return CurrencyCode.AED;
    }
    case LongCurrencyCode.CANADIAN_DOLLAR__CAD: {
      return CurrencyCode.CAD;
    }
    case LongCurrencyCode.CZECH_KORUNA__CZK: {
      return CurrencyCode.CZK;
    }
    default: {
      return CurrencyCode.USD;
    }
  }
};

export const mapToLongCurrencyCode = (value: CurrencyCode): LongCurrencyCode => {
  switch (value) {
    case CurrencyCode.AED: {
      return LongCurrencyCode.UAE_DIRHAM__AED;
    }
    case CurrencyCode.AUD: {
      return LongCurrencyCode.AUSTRALIAN_DOLLAR__AUD;
    }
    case CurrencyCode.CAD: {
      return LongCurrencyCode.CANADIAN_DOLLAR__CAD;
    }
    case CurrencyCode.EUR: {
      return LongCurrencyCode.EURO__EUR;
    }
    case CurrencyCode.GBP: {
      return LongCurrencyCode.BRITISH_POUND__GBP;
    }
    case CurrencyCode.USD: {
      return LongCurrencyCode.US_DOLLAR__USD;
    }
    case CurrencyCode.JPY: {
      return LongCurrencyCode.JAPANESE_YEN__JPY;
    }
    case CurrencyCode.CNY: {
      return LongCurrencyCode.CHINESE_YUAN__CNY;
    }
    case CurrencyCode.CZK: {
      return LongCurrencyCode.CZECH_KORUNA__CZK;
    }
    default: {
      return LongCurrencyCode.US_DOLLAR__USD;
    }
  }
};
