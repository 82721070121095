import { Injectable } from '@angular/core';
import { ShipmentOrderPackage, ShipmentOrderPackageDataService } from '@tecex-api/data';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { RePollService } from '../../../../../projects/zee/src/app/shared/services/re-poll.service';
import { mapCreatePackagesPayload } from '../../helpers/map-create-packages-payload.helper';
import { mapUpdatePackagesPayload } from '../../helpers/map-update-packages-payload.helper';
import { sequence } from '../../helpers/rx.helper';
import { ChargeableWeightDialogResult } from '../../interfaces/chargeable-weight-dialog.vm';
import { ChargeableWeightDialogPackageVM } from '../../interfaces/package.vm';
import { User } from '../../interfaces/user.interface';
import { PackageUpdateQuoteData } from './interfaces/package-update-quote-data.interface';

interface PackageGroups {
  newPackages: ChargeableWeightDialogPackageVM[];
  updatedPackages: ChargeableWeightDialogPackageVM[];
}

@Injectable()
export class PackageService {
  constructor(
    private readonly shipmentOrderPackageDataService: ShipmentOrderPackageDataService,
    private readonly rePollService: RePollService
  ) {}

  public updateQuotePackages$(quoteData: PackageUpdateQuoteData, packagesData: ChargeableWeightDialogResult, user: User): Observable<void> {
    const { newPackages, updatedPackages } = packagesData.packages.reduce<PackageGroups>(
      (acc, packageItem) => {
        if (isNil(packageItem.id)) {
          return { ...acc, newPackages: acc.newPackages.concat(packageItem) };
        }
        return { ...acc, updatedPackages: acc.updatedPackages.concat(packageItem) };
      },
      { newPackages: [], updatedPackages: [] }
    );
    const deletedPackages = quoteData.packages.filter((packageItem) => updatedPackages.every((p) => p.id !== packageItem.id));

    const packageRequests: Observable<any>[] = [];

    if (!isEmpty(deletedPackages)) {
      packageRequests.push(
        this.rePollService.deleteSOPackageRetry$({
          accessToken: user.accessToken,
          soPackages: deletedPackages.map((packageItem) => ({ SOPID: packageItem.id })),
        })
      );
    }

    if (!isEmpty(newPackages)) {
      packageRequests.push(this.createQuotePackages$(quoteData, { ...packagesData, packages: newPackages }, user));
    }

    if (!isEmpty(updatedPackages)) {
      packageRequests.push(this.editQuotePackages$({ ...packagesData, packages: updatedPackages }, user));
    }

    return sequence(packageRequests);
  }

  public createQuotePackages$(
    quoteData: PackageUpdateQuoteData,
    packagesData: ChargeableWeightDialogResult,
    user: User
  ): Observable<ShipmentOrderPackage[]> {
    const payload = mapCreatePackagesPayload(quoteData.quoteId, packagesData);

    return this.rePollService.createSoOrderPackageRetry$({ user, payload }).pipe(mapTo(undefined));
  }

  private editQuotePackages$(packagesData: ChargeableWeightDialogResult, user: User): Observable<void> {
    const payload = mapUpdatePackagesPayload(packagesData);

    return this.rePollService.updateSoPackageRetry$({ user, payload }).pipe(mapTo(undefined));
  }
}
