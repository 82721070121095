import { LineItemsColumn } from '@global/enums/line-items-column.enum';
import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { defaultStoreFeePercentage } from '@shared/constants/app.constants';
import { LineItemsMappingOptions } from '@shared/interfaces/line-items-mapping-options.interface';
import { ZeeAddOrEditPartsRequestLineItems } from '@tecex-api/data';

export const mapLineItemPayload = (lineItem: LineItem, options: LineItemsMappingOptions): ZeeAddOrEditPartsRequestLineItems => {
  const result: ZeeAddOrEditPartsRequestLineItems = {
    partId: lineItem['id'],
    partNumber: lineItem[LineItemsColumn.ProductCode],
    description: lineItem[LineItemsColumn.Description],
    quantity: lineItem[LineItemsColumn.Quantity],
    hsCode: lineItem[LineItemsColumn.HsCode],
    countryOfOrigin: lineItem[LineItemsColumn.CountryOfOrigin],
    clientCurrencyInput: options.currency,
    retailMethodSalesPriceperunit: lineItem[LineItemsColumn.UnitPrice],
  };

  if (!options.calculateStoreFee) {
    result.storeFeeAvailable = false;
    result.retailMethodAmazonFeesperunit = 0;
    result.unitPrice = lineItem[LineItemsColumn.UnitPrice];
  } else if (options.hasStoreFees) {
    result.storeFeeAvailable = true;
    result.retailMethodAmazonFeesperunit = lineItem[LineItemsColumn.StoreFees];
    result.unitPrice = result.retailMethodSalesPriceperunit - result.retailMethodAmazonFeesperunit;
  } else {
    result.storeFeeAvailable = false;
    result.retailMethodAmazonFeesperunit = +((result.retailMethodSalesPriceperunit / 100) * defaultStoreFeePercentage).toFixed(1);
    result.unitPrice = result.retailMethodSalesPriceperunit - result.retailMethodAmazonFeesperunit;
  }

  return result;
};
