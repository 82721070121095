import { createAction, props } from '@ngrx/store';

// Action for Shipment-order participant :
export const loadShipmentOrderParticipants = createAction('[commonMessages] loadShipmentOrderParticipants', props<{ SOID?: string }>());
export const loadShipmentOrderParticipantsSuccess = createAction(
  '[commonMessages] loadShipmentOrderParticipantsSuccess',
  props<{ shipmentOrderParticipant: any }>()
);
export const loadShipmentOrderParticipantsError = createAction(
  '[commonMessages] loadShipmentOrderParticipantsError',
  props<{ error: string }>()
);

// Action for account-participant :
export const loadAccountParticipants = createAction('[commonMessages] loadAccountParticipants');
export const loadAccountParticipantsSuccess = createAction(
  '[commonMessages] loadAccountParticipantsSuccess',
  props<{ accountParticipant: any }>()
);
export const loadAccountParticipantsError = createAction('[commonMessages] loadAccountParticipantsError', props<{ error: string }>());

// Action for Create Message Channel Case :
export const createMessageChannelCase = createAction(
  '[commonMessages] createMessageChannelCase',
  props<{ InvoiceID?: string; SOID?: string; TaskID?: string }>()
);
export const createMessageChannelCaseSuccess = createAction(
  '[commonMessages] createMessageChannelCaseSuccess',
  props<{ messageChannelCreation: any }>()
);
export const createMessageChannelCaseError = createAction('[commonMessages] createMessageChannelCaseError', props<{ error: string }>());

// Action for Update Message Case :
export const updateMessageCases = createAction(
  '[commonMessages] updateMessageCase',
  props<{ CaseID?: string; Subject?: string; Description?: any; orgId?: string }>()
);
export const updateMessageCaseSuccess = createAction('[commonMessages] updateMessageCaseSuccess', props<{ updateMessageCase: any }>());
export const updateMessageCaseError = createAction('[commonMessages] updateMessageCaseError', props<{ error: string }>());

// Action for Delete Message Case :
export const deleteMessageCases = createAction(
  '[commonMessages] deleteMessageCases',
  props<{ CaseID?: string; sendbirdChannelFullUrl?: any }>()
);
export const deleteMessageCasesSuccess = createAction('[commonMessages] deleteMessageCasesSuccess', props<{ deleteMessageCase: any }>());
export const deleteMessageCasesError = createAction('[commonMessages] deleteMessageCasesError', props<{ error: string }>());

export const updateChannelNameSuccess = createAction('[commonMessages] updateChannelNameSuccess');
export const deleteChannelCaseSuccess = createAction('[commonMessages] deleteChannelCaseSuccess');

// For getting the updated participant list from channel info - Start
export const channelInfoForParticipantList = createAction(
  '[commonMessages] channelInfoForParticipantList',
  props<{ sendBirdChannelUrl: string }>()
);
export const channelInfoForParticipantListSuccess = createAction(
  '[commonMessages] channelInfoForParticipantListSuccess',
  props<{ updatedParticipantList: any }>()
);
export const channelInfoForParticipantListError = createAction(
  '[commonMessages] channelInfoForParticipantListError',
  props<{ error: string }>()
);
// For getting the updated participant list from channel info - End

// For remove the existing data from channel info store - Start
export const channelInfoListEmpty = createAction('[commonMessages] channelInfoListEmpty');
// For remove the existing data from channel info store - End

// action for loading tokens
export const loadAwstokens = createAction('[commonMessages] loadAwstokens');
export const loadAwstokensSuccess = createAction('[commonMessages] loadAwstokensSuccess', props<{ token: any }>());
export const loadAwstokensError = createAction('[commonMessages] loadAwstokensError', props<{ error: string }>());

export const leave = createAction('[commonMessages] leave');
