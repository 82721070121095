import { ComponentType } from '@angular/cdk/portal';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule as MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GlobalModule } from '../../global.module';
import { SelectAddress } from '../../interfaces/address/select-address.interface';
import { CommonAddressModule } from '../common-address/common-address.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { FileInputModule } from '../file-input/file-input.module';
import { FormControlModule } from '../form-control/form-control.module';
import { MessageButtonModule } from '../message-button/message-button.module';
import { MessageDialogModule } from '../message-dialog/message-dialog.module';
import { MultiAutocompleteModule } from '../multi-autocomplete/multi-autocomplete.module';
import { PackagesModule } from '../packages/packages.module';
import { SelectModule } from '../select/select.module';
import { SelectionModule } from '../selection/selection.module';
import { ShipmentStateIndicatorCirclesModule } from '../shipment-state-indicator-circles/shipment-state-indicator-circles.module';
import { SvgIconsModule } from '../svg-icons/svg-icons.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { TrackerModule } from '../tracker/tracker.module';
import { AirWaybillDialogComponent } from './components/air-waybill/air-waybill-dialog.component';
import { AirWaybillDialogService } from './components/air-waybill/air-waybill-dialog.service';
import { BatteryTableBlockComponent } from './components/battery-table-block/battery-table-block.component';
import { BeneficialOwnerDialogComponent } from './components/beneficial-owner/beneficial-owner-dialog.component';
import { BeneficialOwnerDialogService } from './components/beneficial-owner/beneficial-owner-dialog.service';
import { ShowStepsPipe } from './components/beneficial-owner/pipes/show-steps.pipe';
import { StepTextPipe } from './components/beneficial-owner/pipes/step-text.pipe';
import { DatepickerBlockComponent } from './components/datepicker-block/datepicker-block.component';
import { DownloadBlockComponent } from './components/download-block/download-block.component';
import { InputBlockComponent } from './components/input-block/input-block.component';
import { InstructionsBlockComponent } from './components/instruction-block/instructions-block.component';
import { MultiSelectBlockComponent } from './components/multi-select-block/multi-select-block.component';
import { PackagesBlockComponent } from './components/packages-block/packages-block.component';
import { PartSubCategoryTableBlockComponent } from './components/part-sub-category-table-block/part-sub-category-table-block.component';
import { PickUpAddressBlockComponent } from './components/pick-up-address-block/pick-up-address-block.component';
import { PickupPreferenceBlockComponent } from './components/pickup-preference-block/pickup-preference-block.component';
import { PreInspectionDialogComponent } from './components/pre-inspection/pre-inspection-dialog.component';
import { PreInspectionDialogService } from './components/pre-inspection/pre-inspection-dialog.service';
import { SelectBlockComponent } from './components/select-block/select-block.component';
import { ShipToAddressBlockComponent } from './components/ship-to-address-block/ship-to-address-block.component';
import { ShipmentSummaryComponent } from './components/shipment-summary/shipment-summary.component';
import { SwitchBlockComponent } from './components/switch-block/switch-block.component';
import { TaskBlocksComponent } from './components/task-blocks/task-blocks.component';
import { TasksDialogComponent } from './components/tasks-dialog/tasks-dialog.component';
import { TasksDialogService } from './components/tasks-dialog/tasks-dialog.service';
import { UploadBlockComponent } from './components/upload-block/upload-block.component';
import { TasksService } from './services/tasks.service';
import { SELECT_ADDRESS_COMPONENT_TYPE } from './tokens/select-address-component-type.token';
import { SHIPMENT_SUMMARY_COMPONENT_TYPE } from './tokens/shipment-summary-component-type.token';
import { ShipmentSummaryComponentType } from './types/shipment-summary-component-type.interface';

interface TasksModuleConfig {
  selectAddressComponentType: ComponentType<SelectAddress>;
  shipmentSummaryComponentType: ComponentType<ShipmentSummaryComponentType>;
}

@NgModule({
  imports: [
    GlobalModule,
    MessageButtonModule,
    FormControlModule,
    FileInputModule,
    SelectionModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    DropdownModule,
    MultiAutocompleteModule,
    MatCheckboxModule,
    MatButtonModule,
    PackagesModule,
    MessageDialogModule,
    MatCheckboxModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    ShipmentStateIndicatorCirclesModule,
    TrackerModule,
    TooltipModule,
    SelectModule,
    CommonAddressModule,
    TranslateModule,
    SvgIconsModule,
    ReactiveFormsModule,
    RouterModule,
    TasksDialogComponent,
    DownloadBlockComponent,
    UploadBlockComponent,
    InputBlockComponent,
    InputBlockComponent,
    MultiSelectBlockComponent,
    InstructionsBlockComponent,
    SwitchBlockComponent,
    SelectBlockComponent,
    PickUpAddressBlockComponent,
    ShipToAddressBlockComponent,
    PackagesBlockComponent,
    PreInspectionDialogComponent,
    BatteryTableBlockComponent,
    DatepickerBlockComponent,
    AirWaybillDialogComponent,
    TaskBlocksComponent,
    PartSubCategoryTableBlockComponent,
    ShipmentSummaryComponent,
    PickupPreferenceBlockComponent,
    BeneficialOwnerDialogComponent,
    ShowStepsPipe,
    StepTextPipe,
  ],
  exports: [TaskBlocksComponent],
})
export class TasksModule {
  public static forRoot(config: TasksModuleConfig): ModuleWithProviders<TasksModule> {
    return {
      ngModule: TasksModule,
      providers: [
        TasksDialogService,
        TasksService,
        PreInspectionDialogService,
        AirWaybillDialogService,
        BeneficialOwnerDialogService,
        { provide: SELECT_ADDRESS_COMPONENT_TYPE, useValue: config.selectAddressComponentType },
        { provide: SHIPMENT_SUMMARY_COMPONENT_TYPE, useValue: config.shipmentSummaryComponentType },
      ],
    };
  }
}
