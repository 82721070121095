import { Inject, Injectable, Injector } from '@angular/core';
import { GetConfigResponse, MiscDataService } from '@tecex-api/data';
import isNil from 'lodash/isNil';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, mapTo, tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { CONFIG_TOKEN } from '../config/config.token';
import { GlobalConfig } from '../config/global-config.interface';
import { User } from '../interfaces/user.interface';
import { TokenConfig } from '../interfaces/token-config.interface';
import { SourceApp } from '../enums/source-app.enum';
import { CommonMessagesService } from '../modules/common-messages/services/common-messages.service';

@Injectable({ providedIn: 'root' })
export class TokenConfigService {
  private readonly tokens$: BehaviorSubject<TokenConfig> = new BehaviorSubject(undefined);

  constructor(
    private readonly injector: Injector,
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig
  ) {}

  public updateTokens$(user: User) {
    const miscDataService = this.injector.get<MiscDataService>(MiscDataService);
    return miscDataService.getConfig({ AccessToken: user.accessToken, AppName: SourceApp.Zee }).pipe(
      map<GetConfigResponse, TokenConfig>((data) => {
        return {
          verifyAccountTask: data.VerifyAccountTask,
          userpilotToken: data.UserpilotToken,
          reCaptchaToken: data.ReCaptchaToken,
          mixpanelToken: data.MixpanelToken,
          inspectletId: data.InspectletId,
          hotjarSiteId: data.HotjarSiteId,
          handsontableToken: data.HandsontableToken,
          communityId: data.CommunityId,
          googleMapsToken: data.GoogleMapsToken,
          termsRevisionId: data.TermsRevisionId,
        };
      }),
      tap((tokens) => {
        this.tokens$.next(this.decodeTokens(tokens, user));
      }),
      mapTo(undefined)
    );
  }

  public getTokens$(): Observable<TokenConfig> {
    return this.tokens$.asObservable().pipe(first((user) => !isNil(user)));
  }

  public decodeTokens(encrypedTokens: TokenConfig, user: User): TokenConfig {
    const commonMessagesService = this.injector.get<CommonMessagesService>(CommonMessagesService);
    const decryptedTokens: any = {};
    const accessTokenKey = commonMessagesService.asciiToHex(user.accessToken.slice(0, 16));
    Object.keys(encrypedTokens).forEach((key) => {
      const iv = CryptoJS.enc.Hex.parse(commonMessagesService.base64ToHex(encrypedTokens[key]).slice(0, 32));
      const cipherText = CryptoJS.enc.Hex.parse(commonMessagesService.base64ToHex(encrypedTokens[key]).slice(32));
      decryptedTokens[key] = commonMessagesService.decrypt_core_AES_CBC(accessTokenKey, iv, cipherText);
    });
    return decryptedTokens;
  }
}
