import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginCallbackComponent } from '@global/components/login-callback/login-callback.component';
import { RouteSegment } from '@global/enums/route-segment.enum';
import { AuthGuard } from '@global/guards/auth.guard';
import { UnAuthGuard } from '@global/guards/un-auth.guard';
import { MaintenanceGuard } from '@global/modules/announcment/guards/maintenance.guard';
import { LogoutComponent } from './components/logout/logout.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';

const routes: Routes = [
  {
    path: RouteSegment.Root,
    pathMatch: 'full',
    redirectTo: RouteSegment.Dashboard,
  },
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [AuthGuard, MaintenanceGuard],
    children: [
      {
        path: RouteSegment.NewQuote,
        loadChildren: () => import('@modules/new-quote/new-quote.module').then((m) => m.NewQuoteModule),
      },
      {
        path: RouteSegment.Dashboard,
        loadChildren: () => import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },

      {
        path: RouteSegment.Messages,
        loadChildren: () => import('@modules/messages/messages.module').then((m) => m.MessagesModule),
      },
      {
        path: RouteSegment.Insights,
        loadChildren: () => import('@modules/insights/insights.module').then((m) => m.InsightsModule),
      },
      {
        path: RouteSegment.Profile,
        loadChildren: () => import('@modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      { path: RouteSegment.Tracking, loadChildren: () => import('./modules/tracking/tracking.module').then((m) => m.TrackingModule) },
      { path: RouteSegment.QuoteList, loadChildren: () => import('./modules/quote-list/quote-list.module').then((m) => m.QuoteListModule) },
      {
        path: RouteSegment.Invoices,
        loadChildren: () => import('@modules/invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: RouteSegment.ShipmentsList,
        loadChildren: () => import('@modules/shipments/shipments.module').then((m) => m.ShipmentsModule),
      },
      {
        path: RouteSegment.Logout,
        component: LogoutComponent,
      },
    ],
  },
  {
    path: RouteSegment.LoginCallback,
    component: LoginCallbackComponent,
  },
  {
    path: RouteSegment.Registration,
    canLoad: [UnAuthGuard],
    canActivate: [UnAuthGuard],
    loadChildren: () => import('./modules/registration/registration.module').then((m) => m.RegistrationModule),
  },
  {
    path: RouteSegment.PaymentCallback,
    loadChildren: () => import('./modules/payment-callback/payment-callback.module').then((m) => m.PaymentCallbackModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
