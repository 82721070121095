<div class="form-control-container" [class.with-icon]="icon" [class.without-label]="!label">
  <div *ngIf="icon" class="icon">
    <ng-content select="[appFormControlIcon]"></ng-content>
  </div>

  <div *ngIf="label || tooltip" class="top d-flex align-items-center">
    <label
      *ngIf="label"
      class="text-tiny-regular color-text-secondary label"
      [class.error]="hasError"
      [class.focused]="isFocused$ | async"
      [for]="id"
    >
      <ng-content select="[appFormControlLabel]"></ng-content>
    </label>

    <button *ngIf="tooltip" type="button" mat-icon-button class="ml-1 icon-info">
      <svg-icon key="info" [appTooltip]="tooltip.tooltip"></svg-icon>
    </button>
  </div>

  <div class="input-container text-reduced-regular" [ngClass]="!readonlyValue ? 'd-none' : 'd-inline'">
    <ng-content select="[appFormControlReadonlyValue]"></ng-content>
  </div>

  <div class="input-container" [class.error]="hasError" [ngClass]="!!readonlyValue ? 'd-none' : 'd-inline'">
    <ng-content select="[appFormControlInput]"></ng-content>
    <div *ngIf="inputSuffix" class="input-suffix">
      <ng-content select="[appFormControlInputSuffix]"></ng-content>
    </div>
  </div>

  <div *ngIf="error" class="text-micro-regular hint error" [ngClass]="!!readonlyValue ? 'd-none' : 'd-inline'">{{ error }}</div>
  <div *ngIf="!error && hint" class="text-micro-regular hint" [ngClass]="!!readonlyValue ? 'd-none' : 'd-inline'">
    <ng-content select="[appFormControlHint]"></ng-content>
  </div>
</div>
