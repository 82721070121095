import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, ComponentType, TemplatePortal, PortalModule } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ContentChild,
  EmbeddedViewRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { matSnackBarAnimations } from '@angular/material/snack-bar';
import { TOAST_MESSAGE_DATA } from './toast-mesage.tokens';
import { ToastMessageData } from './toast-message-data.interface';
import { ToastMessageIconDirective } from './toast-message-icon.directive';
import { ToastMessageType } from './toast-message-type.enum';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgTemplateOutlet, NgComponentOutlet } from '@angular/common';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [matSnackBarAnimations.snackBarState],
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, NgComponentOutlet, PortalModule, MatButtonModule, SvgIconComponent],
})
export class ToastMessageComponent extends BasePortalOutlet {
  @Input() public type: ToastMessageType;
  @Input() public closeable = true;
  @Input() public callToActionLabel: string;
  @Output() public closeClick = new EventEmitter<void>();
  @ViewChild(CdkPortalOutlet, { static: true }) private readonly portalOutlet: CdkPortalOutlet;
  @ContentChild(ToastMessageIconDirective, { read: TemplateRef }) public iconTemplate: TemplateRef<ToastMessageIconDirective>;

  @HostBinding('class') public get typeClass(): string {
    switch (this.type) {
      case ToastMessageType.Success: {
        return 'success';
      }
      case ToastMessageType.Info: {
        return 'info';
      }
      case ToastMessageType.Warning: {
        return 'warning';
      }
      case ToastMessageType.Error: {
        return 'error';
      }
    }
  }

  @HostBinding('@state') public get hostAnimation(): string {
    return this.animationState;
  }

  public animationState = 'void';
  public iconComponent: ComponentType<any>;

  constructor(@Optional() @Inject(TOAST_MESSAGE_DATA) private readonly data: ToastMessageData) {
    super();

    if (!data) {
      return;
    }

    this.type = data.config.type;
    this.closeable = data.config.closeable;
    this.iconComponent = data.config.icon;
    this.callToActionLabel = data.callToActionLabel;
  }

  public enter(): void {
    this.animationState = 'visible';
  }

  public attachComponentPortal<C>(portal: ComponentPortal<C>): ComponentRef<C> {
    return this.portalOutlet.attachComponentPortal(portal);
  }

  public attachTemplatePortal<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T> {
    return this.portalOutlet.attachTemplatePortal(portal);
  }

  public onCloseClick(): void {
    this.closeClick.emit();

    if (this.data) {
      this.data.ref.close();
    }
  }

  public onCTAClick(): void {
    this.data.ref.emitCallToAction();
  }
}
