export enum RouteSegment {
  Root = '',
  Dashboard = 'dashboard',
  NewQuote = 'new-quote',
  QuoteList = 'quotes',
  Invoices = 'invoices',
  Profile = 'profile',
  ShipmentsList = 'shipments-list',
  Messages = 'messages',
  OnBoarding = 'onboarding',
  Tracking = 'tracking',
  Registration = 'registration',
  NoMobileView = 'no-mobile',
  Insights = 'insights',
  LoginCallback = 'login/callback',
  PaymentCallback = 'payment-callback',
  Logout = 'log-out',
}
