<div class="container py-4">
  <div *ngIf="showUi">
    <h1 class="h1">Login Callback</h1>

    <table class="table mt-4">
      <tr>
        <th>Salesforce access token:</th>
        <td>{{ sfAccessToken }}</td>
      </tr>

      <tr>
        <th>NCP Access Token:</th>
        <td>{{ ncpAccessToken }}</td>
      </tr>

      <tr>
        <th>User ID:</th>
        <td>{{ userId }}</td>
      </tr>

      <tr>
        <th>Account ID:</th>
        <td>{{ accountId }}</td>
      </tr>

      <tr>
        <th>Developer Handle:</th>
        <td><input type="text" [(ngModel)]="devHandle" /></td>
      </tr>
    </table>

    <div class="text-center p-4">
      <button mat-button class="secondary-button" (click)="onContinueToDevContainerClick()">Continue to developer container</button>
      <button mat-button class="secondary-button" (click)="onContinueToLocalhostClick()">Continue to localhost</button>
      <button mat-button class="primary-button ml-2" (click)="onContinueClick()">Continue</button>
    </div>
  </div>
</div>
