<div class="dialog-container">
  <div class="dialog-content">
    <div class="d-flex justify-content-between mr-5">
      <h3 class="dialog-title mb-0" [innerHTML]="data.payload.title"></h3>
      <app-message-button [user]="data.payload.defaultTeamMember" (buttonClick)="onMessageClick()"></app-message-button>
    </div>

    <app-shipment-summary
      *ngIf="data.payload.showShipmentOrderBox && data.payload.shipmentOrder"
      [shipmentOrder]="data.payload.shipmentOrder"
      class="mt-4 mb-4"
    ></app-shipment-summary>

    <div *ngIf="data.payload.isInactive" class="text-reduced-bold color-text-secondary py-3">
      {{ 'TASKS.INACTIVE_HINT' | translate }}
    </div>

    <div class="text-reduced-regular color-text-secondary mt-4" [innerHTML]="data.payload.description"></div>
    <ng-container *ngIf="!showSecondStep">
      <p class="text-reduced-regular color-text-secondary">{{ 'TASKS.AIR_WAYBILL.CARRIER_QUESTION' | translate }}</p>
      <div class="mt-4">
        <app-selection
          [items]="[
            { value: AirWaybillCarrierType.Courier, viewValue: 'TASKS.AIR_WAYBILL.COURIER' | translate },
            { value: AirWaybillCarrierType.FreightForwarder, viewValue: 'TASKS.AIR_WAYBILL.FREIGHT_FORWARDER' | translate }
          ]"
          [formControl]="carrierControl"
          class="service-type-selection"
        ></app-selection>
      </div>
    </ng-container>
    <div *ngIf="showSecondStep" class="mt-3 steps">
      <ng-container [ngSwitch]="carrierControl.value" class="d-flex">
        <ng-container *ngSwitchCase="AirWaybillCarrierType.Courier">
          <div *ngIf="data.payload.type === AirWaybillType.WithNotifyParty" class="d-flex align-items-center">
            <p class="color-primary line-number m-0"></p>
            <p class="text-reduced-regular color-text-secondary mt-1 mb-0 ml-2">{{ 'TASKS.AIR_WAYBILL.PLEASE_NOTE_THAT' | translate }}</p>
          </div>
          <div *ngIf="data.payload.type === AirWaybillType.WithNotifyParty" class="d-flex">
            <p class="color-primary line-number m-0"></p>
            <div class="d-flex flex-column ml-2">
              <p class="text-reduced-regular color-text-secondary mt-1 mb-0">{{ 'TASKS.AIR_WAYBILL.NOTIFY_PARTY_DETAILS' | translate }}</p>
              <ng-container [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{ address: notifyPartyAddress }"></ng-container>
            </div>
          </div>
          <div class="d-flex">
            <p class="color-primary line-number m-0"></p>
            <div class="d-flex flex-column ml-2">
              <p class="text-reduced-regular color-text-secondary mt-1 mb-0">
                {{ 'TASKS.AIR_WAYBILL.WAYBILL_CONSIDERED_AS_FOLLOWS' | translate: { purpose: data.payload.courierAWBIncoterm } }}
              </p>
              <ng-container [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{ address: consigneeAddress }"></ng-container>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <p class="color-primary line-number m-0"></p>
            <p class="text-reduced-regular color-text-secondary mt-1 mb-0 ml-2">{{ 'TASKS.AIR_WAYBILL.UPLOAD_AWB' | translate }}</p>
            <ng-container [ngTemplateOutlet]="fileUpload"></ng-container>
          </div>
          <div class="d-flex align-items-center">
            <p class="color-primary line-number m-0"></p>
            <p class="text-reduced-regular color-text-secondary mt-1 mb-0 ml-2">{{ 'TASKS.AIR_WAYBILL.SUBMIT_YOUR_AWB' | translate }}</p>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="AirWaybillCarrierType.FreightForwarder">
          <div *ngIf="data.payload.type === AirWaybillType.WithNotifyParty" class="d-flex align-items-center">
            <p class="color-primary line-number m-0"></p>
            <p class="text-reduced-regular color-text-secondary mt-1 mb-0 ml-2">{{ 'TASKS.AIR_WAYBILL.PLEASE_NOTE_THAT' | translate }}</p>
          </div>
          <div *ngIf="data.payload.type === AirWaybillType.WithNotifyParty" class="d-flex">
            <p class="color-primary line-number m-0"></p>
            <div class="d-flex flex-column ml-2">
              <p class="text-reduced-regular color-text-secondary mt-1 mb-0">{{ 'TASKS.AIR_WAYBILL.NOTIFY_PARTY_DETAILS' | translate }}</p>
              <ng-container [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{ address: notifyPartyAddress }"></ng-container>
            </div>
          </div>
          <div class="d-flex">
            <p class="color-primary line-number m-0"></p>
            <div class="d-flex flex-column ml-2">
              <p class="text-reduced-regular color-text-secondary mt-1 mb-0">
                {{ 'TASKS.AIR_WAYBILL.WAYBILL_CONSIDERED_AS_FOLLOWS' | translate: { purpose: data.payload.freightForwarderAWBIncoterm } }}
              </p>
              <ng-container [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{ address: consigneeAddress }"></ng-container>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <p class="color-primary line-number m-0"></p>
            <p class="text-reduced-regular color-text-secondary mt-1 mb-0 ml-2">{{ 'TASKS.AIR_WAYBILL.UPLOAD_AWB' | translate }}</p>
            <ng-container [ngTemplateOutlet]="fileUpload"></ng-container>
          </div>
          <div class="d-flex align-items-center">
            <p class="color-primary line-number m-0"></p>
            <p class="text-reduced-regular color-text-secondary mt-1 mb-0 ml-2">{{ 'TASKS.AIR_WAYBILL.SUBMIT_YOUR_AWB' | translate }}</p>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex align-items-center justify-content-end w-100">
      <button mat-button type="button" class="secondary-button mr-3" (click)="onCancelClick()">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
      <div
        class="d-inline-block"
        [appTooltip]="data.payload.isInactive && 'TASKS.INACTIVE_TOOLTIP' | translate"
        [appTooltipStrategy]="'hover'"
      >
        <button
          *ngIf="showSecondStep; else nextButton"
          mat-button
          class="primary-button"
          (click)="onSubmitClick()"
          [disabled]="data.payload.isInactive || fileInputControl.invalid"
        >
          {{ 'COMMON.SUBMIT' | translate }}
        </button>
        <ng-template #nextButton>
          <button mat-button class="primary-button" (click)="onNextClick()" [disabled]="data.payload.isInactive || carrierControl.invalid">
            {{ 'COMMON.NEXT' | translate }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #address let-address="address">
  <div class="color-blue text-reduced-regular mt-2 address">
    <p>{{ address.company }}</p>
    <p>{{ address.addressLine1 }}</p>
    <p *ngIf="address.addressLine2">{{ address.addressLine2 }}</p>
    <p>
      {{ createAddressLine(address) }}
    </p>
    <p>{{ address.name }}</p>
    <p>
      <a class="color-secondary text-decoration-none" href="mailto:{{ address.email }}">{{ address.email }}</a>
    </p>
    <p>
      <a class="color-secondary text-decoration-none" href="tel:{{ address.phone }}">{{ address.phone }}</a>
    </p>
  </div>
</ng-template>

<ng-template #fileUpload>
  <div class="ml-2">
    <app-file-input [descriptionEnabled]="false" [formControl]="fileInputControl"></app-file-input>

    <div *ngIf="!showFileSizeError" class="text-micro-regular font-italic color-text-secondary text-right mt-1">
      {{ 'TASKS.FILE_SIZE_LIMIT_HINT' | translate: { maxFileSize: maxFileSizeInMb } }}
    </div>
    <div *ngIf="showFileSizeError" class="d-flex justify-content-end align-items-center text-micro-regular color-error mt-1">
      <div [innerHTML]="'ERROR.FILE_SIZE_LIMIT_EXCEEDED' | translate: { fileName: invalidFileName, maxFileSize: maxFileSizeInMb }"></div>
      <button mat-icon-button type="button" class="text-reduced-bold" (click)="removeInvalidFileClick()">
        <svg-icon key="close"></svg-icon>
      </button>
    </div>
    <div
      *ngIf="fileInputControl.value as file"
      class="card-container px-2 py-3 d-flex mt-2 color-secondary align-items-center justify-content-between"
    >
      <div class="d-flex align-items-center">
        <svg-icon key="doc"></svg-icon>
        <div class="ml-2 text-tiny-bold">{{ file.name }}</div>
      </div>
    </div>
  </div>
</ng-template>
