import { LineItemsColumn } from '@global/enums/line-items-column.enum';
import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { GetPartsDetailsResponsePart } from '@tecex-api/data/model/getPartsDetailsResponsePart';

export const mapLineItemDetails = (lineItem: GetPartsDetailsResponsePart): LineItem => ({
  id: lineItem.Id,
  [LineItemsColumn.ProductCode]: lineItem.Name,
  [LineItemsColumn.Description]: lineItem.DescriptionandFunctionality,
  [LineItemsColumn.Quantity]: lineItem.Quantity,
  [LineItemsColumn.UnitPrice]: lineItem.RetailMethodSalesPricePerUnit,
  [LineItemsColumn.HsCode]: lineItem.USHTSCode,
  [LineItemsColumn.CountryOfOrigin]: lineItem.CountryOfOrigin,
  [LineItemsColumn.StoreFees]: lineItem.RetailMethodAmazonFeesPerUnit,
});
