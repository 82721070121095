import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';
import { SelectBlockPayload } from '../../types/select-block-payload.interface';
import { BlockComponent } from '../block/block.component';
import { FormControlHintDirective } from '../../../form-control/directives/form-control-hint.directive';
import { NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlInputDirective } from '../../../form-control/directives/form-control-input.directive';
import { DropdownComponent } from '../../../dropdown/dropdown.component';
import { FormControlLabelDirective } from '../../../form-control/directives/form-control-label.directive';
import { FormControlComponent } from '../../../form-control/components/form-control/form-control.component';

@Component({
  selector: 'app-select-block',
  templateUrl: './select-block.component.html',
  styleUrls: ['./select-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormControlComponent,
    FormControlLabelDirective,
    DropdownComponent,
    FormControlInputDirective,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    FormControlHintDirective,
  ],
})
export class SelectBlockComponent extends BlockComponent<SelectBlockPayload, undefined> implements OnInit {
  public readonly formControl = new FormControl();

  public ngOnInit(): void {
    this.register.next(this.formControl);
  }
}
