import isNil from 'lodash/isNil';
import { ValidatorFn } from '@angular/forms';

// This is the email validator from Angular, but the TLD part made required.
// https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts#L98
const EMAIL_REGEXP =
  // eslint-disable-next-line max-len
  /^(?=.{1,254}$)(?=.{1,64}@)[\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)+$/;

export const emailWithTldValidator: ValidatorFn = (control) => {
  if (isNil(control.value) || control.value.length === 0) {
    return null; // don't validate empty values to allow optional controls
  }
  return EMAIL_REGEXP.test(control.value) ? null : { email: true };
};
