import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { User } from '../../../../interfaces/user.interface';
import { AuthService } from '../../../../services/auth.service';
// import { IntercomService } from '../../../../services/intercom.service';
import { DIALOG_DATA } from '../../../dialog/dialog.tokens';
import { DialogData } from '../../../dialog/interfaces/dialog-data.interface';
import { ProfileMenuItem } from '../../profile-menu-item.interface';
import { HelpMenuItem, NavigationItem } from '../navigation-item/navigation-item.interface';
import { AppNavDrawerVM } from './app-nav-drawer.vm';
import { TranslateModule } from '@ngx-translate/core';
import { ProfilePicturePipe } from '../../../../pipes/profile-picture.pipe';
import { MatLineModule } from '@angular/material/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NgFor, NgClass, NgIf, AsyncPipe } from '@angular/common';
import { MatLegacyListModule } from '@angular/material/legacy-list';

@Component({
  templateUrl: './app-nav-drawer.component.html',
  styleUrls: ['./app-nav-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  standalone: true,
  imports: [
    MatLegacyListModule,
    NgFor,
    RouterLinkActive,
    RouterLink,
    SvgIconComponent,
    MatLineModule,
    NgClass,
    NgIf,
    AsyncPipe,
    ProfilePicturePipe,
    TranslateModule,
  ],
})
export class AppNavDrawerComponent {
  public readonly menuItems: NavigationItem[] = [];
  public readonly helpMenuItem: HelpMenuItem = {};
  public readonly settingsMenuItems: ProfileMenuItem[] = [];
  public readonly logOutLink: ProfileMenuItem;
  public readonly profileLink: ProfileMenuItem;
  public readonly version: string;
  public readonly user$: Observable<User>;
  public readonly isSettingsRouteActive$: Observable<boolean>;
  public isSettingsMenuOpen = false;

  constructor(
    @Inject(DIALOG_DATA)
    private readonly data: DialogData<AppNavDrawerVM, AppNavDrawerVM>,
    private readonly authService: AuthService,
    private readonly router: Router // public intercom: IntercomService
  ) {
    this.menuItems = data.payload.menuItems;
    this.helpMenuItem = data.payload.helpMenuItem;
    this.settingsMenuItems = data.payload.settingsMenuItems;
    this.logOutLink = data.payload.logOutLink;
    this.profileLink = data.payload.profileLink;
    this.version = data.payload.version;

    this.user$ = this.authService.getUser$();

    // Make sure the settings menu starts open if any of the children are active
    this.isSettingsRouteActive$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(false),
      map(
        () =>
          this.settingsMenuItems
            .map((item) =>
              this.router.isActive(this.router.createUrlTree(item.link), {
                paths: 'subset',
                queryParams: 'subset',
                fragment: 'ignored',
                matrixParams: 'ignored',
              })
            )
            .filter(Boolean).length > 0
      ),
      tap((isActive) => {
        this.isSettingsMenuOpen = isActive;
      })
    );
  }

  public showHelp(): void {
    // this.intercom.launchIntercom();
    this.data.dialogRef.close();
  }

  public close(): void {
    this.data.dialogRef.close();
  }

  public toggleSettingsMenu(): void {
    this.isSettingsMenuOpen = !this.isSettingsMenuOpen;
  }
}
