import isNil from 'lodash/isNil';
import { ValidatorFn } from '@angular/forms';

export const greaterThanValidator: (min: number, canBeEqual?: boolean) => ValidatorFn =
  (min, canBeEqual = false) =>
  (control) => {
    if (isNil(control.value)) {
      return null;
    }

    const value = Number.parseFloat(control.value);

    if (canBeEqual) {
      return !Number.isNaN(value) && value >= min ? null : { greaterThan: { min, actual: control.value } };
    }

    return !Number.isNaN(value) && value > min ? null : { greaterThan: { min, actual: control.value } };
  };
