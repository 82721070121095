import { LineItemsColumn } from '@global/enums/line-items-column.enum';
import { LineItem } from '@global/modules/common-quote/interfaces/line-item.interface';
import { AddPartsResponseItem } from '@tecex-api/data';

export const mapLineItem = (lineItem: AddPartsResponseItem): LineItem => ({
  id: lineItem.Id,
  [LineItemsColumn.ProductCode]: lineItem.Name,
  [LineItemsColumn.Description]: lineItem.Description_and_Functionality__c,
  [LineItemsColumn.Quantity]: lineItem.Quantity__c,
  [LineItemsColumn.UnitPrice]: lineItem.Retail_Method_Sales_Price_per_unit__c,
  [LineItemsColumn.HsCode]: lineItem.US_HTS_Code__c,
  [LineItemsColumn.CountryOfOrigin]: lineItem.Country_of_Origin__c,
  [LineItemsColumn.StoreFees]: lineItem.Retail_Method_Amazon_Fees_per_unit__c,
});
