import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@global/services/auth.service';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  constructor(private readonly authService: AuthService) {}

  public ngOnInit(): void {
    this.authService.logout();
  }
}
