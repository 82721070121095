<app-form-control>
  <span *ngIf="payload.label" appFormControlLabel>{{ payload.label }}</span>
  <input
    appFormControlInput
    type="text"
    [matDatepicker]="datePicker"
    [formControl]="dateControl"
    class="form-control-input"
    data-hj-allow
  />
  <mat-datepicker-toggle appFormControlInputSuffix [for]="datePicker">
    <svg-icon key="date" matDatepickerToggleIcon class="date-icon"></svg-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #datePicker></mat-datepicker>
  <span *ngIf="payload.hint" appFormControlHint>{{ payload.hint }}</span>
</app-form-control>

<app-form-control *ngIf="payload.type === 'datetime'">
  <input
    appFormControlInput
    class="form-control-input"
    type="text"
    [formControl]="timeControl"
    [ngxTimepicker]="timePicker"
    [disableClick]="true"
    data-hj-allow
  />
  <ngx-material-timepicker-toggle appFormControlInputSuffix [for]="timePicker">
    <svg-icon key="time" ngxMaterialTimepickerToggleIcon></svg-icon>
  </ngx-material-timepicker-toggle>
  <ngx-material-timepicker #timePicker [appendToInput]="true"></ngx-material-timepicker>
</app-form-control>
