import { Task } from '@tecex-api/data';
import { DashboardYourTaskVM } from '../modules/common-dashboard/interfaces/dashboard-your-task.vm';
import { sortByDate } from './sort-by-date.helper';

export const mapTasks = (tasks: Task[], userId: string): DashboardYourTaskVM[] =>
  tasks
    .map((task) => ({
      id: task.Id,
      masterTaskId: task.MasterTaskId,
      subject: task.TemplateTitle,
      createdDate: task.CreatedDate,
      clientReference: task.ClientReference,
      quoteReference: task.NCPQuoteReference,
      isOwned: task.OwnerId === userId,
      ownerId: task.OwnerId,
      state: task.State,
      soCriticalError: task['SO.RE_CriticalError'],
    }))
    .sort((a, b) => sortByDate(a.createdDate, b.createdDate));
