import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@ngneat/reactive-forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from '../../../../../global/interfaces/user.interface';
import { AuthService } from '../../../../../global/services/auth.service';
import {
  optimizedToggleOpacityAnimation,
  toggleAnimation,
  toggleOpacityAnimation,
} from '../../../../animations/toggle-open-close.animation';
import { AffiliateUser, ClientContact, ClientUser, InternalUser, Participant } from '../../../../interfaces/participant.interface';
import * as commonMessageReducers from '../../reducers';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { MatButtonModule } from '@angular/material/button';
import { MessageParticipantItemComponent } from '../message-participant-item/message-participant-item.component';
import { MatRippleModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchFieldComponent } from '../../../search-field/search-field.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';

const GROUP_HEADER_HEIGHT = 32;

@Component({
  selector: 'app-message-participants-list',
  templateUrl: './message-participants-list.component.html',
  styleUrls: ['./message-participants-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toggleAnimation(GROUP_HEADER_HEIGHT), optimizedToggleOpacityAnimation, toggleOpacityAnimation],
  standalone: true,
  imports: [
    NgIf,
    SearchFieldComponent,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    NgFor,
    MessageParticipantItemComponent,
    MatButtonModule,
    SvgIconComponent,
    NgClass,
    AsyncPipe,
    TranslateModule,
  ],
})
export class MessageParticipantsListComponent implements OnChanges, OnInit {
  @Input() public title?: string;
  @Input() public teamMembers: any;
  @Input() public participants: Participant[];
  @Output() public add = new EventEmitter<Participant>();
  @Output() public remove = new EventEmitter<Participant>();
  @Output() public taggedTeamMembersList = new EventEmitter<Participant[]>();
  @Output() public guestUsersList = new EventEmitter<any>();
  @Output() public listOfACCUsers = new EventEmitter<any>();

  public teamMembersToDisplay: any = [];
  public defaultTeamMembersToDisplay: Participant[] = [];
  public taggedTeamMembersToDisplay: any[] = [];
  public availableTeamMembersToDisplay: Participant[] = [];
  public searchedTeamMembersToDisplay: Participant[] = [];

  public searchFieldControl = new FormControl<string>('');
  public cancelControl = new FormControl<string>('');
  public keyword$ = this.searchFieldControl.value$;
  public isOpenForInternalUser = false;
  public isOpenForClientUser = false;
  public isOpenForClientContact = false;
  public isOpenForThirdPartyUsers = false;
  public companyNameForClient = '';
  public concatClientUserContacts: any;

  public guestDefaultFlag: boolean;
  public guestUserEmail: any;
  public guestUserToggle = true;

  public Internal_Users__c: InternalUser[];
  public Client_Users__c: ClientUser[] | any;
  public Client_Contacts__c: ClientContact[];
  public Affiliate_Users__c: AffiliateUser[] | any;

  public internal_Users__c_New: InternalUser[];
  public client_Users__c_New: ClientUser[];
  public client_Contacts__c_New: ClientContact[];
  public affiliate_Users__c_New: AffiliateUser[];

  public user$: Observable<User>;

  public participantSectionName = 'TecEx';
  public loginUserDetail: any;
  public listOfGuestUsers: any = [];

  public onAddClickForGuestUser(): void {
    const guestUserTempObject: any = {
      id: this.guestUserEmail,
      email: this.guestUserEmail,
      nickname: this.guestUserEmail,
      isMuted: false,
      userType: 'Guest',
      userRole: null,
      userCompany: null,
      affiliationType: null,
      profileUrlNCP: `${window.location.href.split(this.router.url)[0]}/profile/my-team`,
    };

    if (this.listOfGuestUsers.length === 0 && this.guestUserEmail) {
      this.listOfGuestUsers.push(guestUserTempObject);
    } else if (this.guestUserEmail && !this.listOfGuestUsers.find((x: any) => x.id.includes(this.guestUserEmail)))
      this.listOfGuestUsers.push(guestUserTempObject);

    this.guestUsersList.emit(this.listOfGuestUsers);
    this.guestUserEmail = null;
  }

  public removeForGuestUserList(index: number): void {
    this.listOfGuestUsers.splice(index, 1);
    this.guestUsersList.emit(this.listOfGuestUsers);
  }

  // new code for shipment order participants getting :
  public listOfParticipants$: Observable<any>;

  constructor(
    private readonly router: Router,
    private readonly store$: Store<commonMessageReducers.AppState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line: early-exit
    if (changes.teamMember || changes.participants) {
      this.teamMembersToDisplay = [
        // It can happend that there are participants outside from the list,
        // so we have to make sure those are also presented on the list.
        ...this.participants.filter(
          (participant) => this.teamMembers.find((teamMember: { id: string }) => teamMember.id === participant.id) === undefined
        ),
        ...this.teamMembers,
      ];

      this.defaultTeamMembersToDisplay = this.sortParticipantsAlphabetically(
        this.teamMembersToDisplay.filter((teamMember: any) => !this.isRemovable(teamMember) && this.isAdded(teamMember))
      );

      this.taggedTeamMembersToDisplay = this.sortParticipantsAlphabetically(
        this.teamMembersToDisplay.filter((teamMember: any) => this.isRemovable(teamMember))
      );

      this.taggedTeamMembersList.emit(this.taggedTeamMembersToDisplay);

      this.availableTeamMembersToDisplay = this.sortParticipantsAlphabetically(
        this.teamMembersToDisplay.filter((teamMember: any) => !this.isRemovable(teamMember) && !this.isAdded(teamMember))
      );

      // This is for search from existing data of all participants :
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.Internal_Users__c && this.Affiliate_Users__c && this.Client_Users__c
        ? (this.teamMembersToDisplay = this.Internal_Users__c.concat(this.Affiliate_Users__c, this.Client_Users__c))
        : '';

      for (const participant of this.participants) {
        this.Internal_Users__c = this.Internal_Users__c?.filter((x) => x.id !== participant.id);
        this.Client_Users__c = this.Client_Users__c?.filter((x: any) => x.id !== participant.id);
        this.Client_Contacts__c = this.Client_Contacts__c?.filter((x) => x.id !== participant.id);
        this.Affiliate_Users__c = this.Affiliate_Users__c?.filter((x: any) => x.id !== participant.id);
      }
    }
  }

  public onToggleClickForInternalUser(): void {
    this.isOpenForInternalUser = !this.isOpenForInternalUser;
  }

  public onToggleClickForClientUser(): void {
    this.isOpenForClientUser = !this.isOpenForClientUser;
  }

  public onToggleClickForClientContact(): void {
    this.isOpenForClientContact = !this.isOpenForClientContact;
  }

  public onToggleClickForAffiliateUsers(): void {
    this.isOpenForThirdPartyUsers = !this.isOpenForThirdPartyUsers;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public ngOnInit(): void {
    this.user$ = this.authService.getUser$();

    this.user$.subscribe((value) => {
      this.loginUserDetail = value;
    });

    this.listOfParticipants$ = this.router.url.includes('dashboard')
      ? this.store$.select(commonMessageReducers.selectListOfAccountParticipant)
      : this.store$.select(commonMessageReducers.selectListOfShipmentOrderParticipant);

    // For conditional based toggle button switch : [ for invoice - flag ]
    this.guestUserToggle = this.router.url.includes('invoices') ? false : true;

    this.searchFieldControl.valueChanges.subscribe((keyword) => {
      const keywordLowerCase = keyword.toLocaleLowerCase();
      this.searchedTeamMembersToDisplay = [
        ...new Set([
          ...this.sortParticipantsAlphabetically(
            this.teamMembersToDisplay.filter((teamMember: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              teamMember.firstName?.toLocaleLowerCase().startsWith(keywordLowerCase);
            })
          ),
          ...this.sortParticipantsAlphabetically(
            this.teamMembersToDisplay.filter((teamMember: any) =>
              `${teamMember.firstName}`.toLocaleLowerCase().startsWith(keywordLowerCase)
            )
          ),
          ...this.sortParticipantsAlphabetically(
            this.teamMembersToDisplay.filter((teamMember: any) => `${teamMember.firstName}`.toLocaleLowerCase().includes(keywordLowerCase))
          ),
        ]),
      ];
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  public ngAfterViewInit(): void {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.listOfParticipants$.subscribe((response: any) => {
      let tempObj: any;

      if (response.ACTecexPeople) {
        const newAcTTecexData = response.ACTecexPeople?.map((user: any) => {
          const newObjForACTecexUsers = {};
          Object.assign(newObjForACTecexUsers, user, { id: user.UserID, firstName: user.FirstName, lastName: user.LastName });
          return newObjForACTecexUsers;
        });

        const newClientUserData = response.ClientUsers?.map((user: any) => {
          const newObjectForClientUsers = {};
          Object.assign(newObjectForClientUsers, user, {
            id: user.ContactId,
            firstName: user.FirstName,
            lastName: user.LastName,
            roleFlag: 'ClientUsers',
          });
          return newObjectForClientUsers;
        });

        const newClientContactDataForClientUsers = response.ClientContacts?.map((user: any) => {
          const newObjectForClientContacts = {};
          Object.assign(newObjectForClientContacts, user, { id: user.contactID, firstName: user.Name });
          return newObjectForClientContacts;
        });

        const newThirdPartyUsersData = response.ThirdPartyPeople?.map((user: any) => {
          const newObjForACThirdParty = {};
          Object.assign(newObjForACThirdParty, user, {
            id: user.Id,
            firstName: user.AffiliateUserName,
            nickName: user.AffiliateUserName,
            userEmail: user.ThirdPartyEmail,
          });
          return newObjForACThirdParty;
        });

        this.Internal_Users__c = this.sortInternalUserAlphabetically(newAcTTecexData);
        this.Affiliate_Users__c = this.sortThirdPartyUserAlphabetically(newThirdPartyUsersData);
        this.concatClientUserContacts = newClientUserData.concat(newClientContactDataForClientUsers);
        this.Client_Users__c = this.sortClientUserAlphabetically(this.concatClientUserContacts, null);

        // This is for search from existing data of all participants :
        this.teamMembersToDisplay = this.Internal_Users__c.concat(this.Affiliate_Users__c, this.Client_Users__c);

        for (const participant of this.participants) {
          this.Internal_Users__c = this.Internal_Users__c?.filter((x) => x.id !== participant.id);
          this.Affiliate_Users__c = this.Affiliate_Users__c?.filter((x: any) => x.id !== participant.id);
        }

        this.internal_Users__c_New = this.Internal_Users__c;
        this.client_Users__c_New = this.Client_Users__c;
        this.affiliate_Users__c_New = this.Affiliate_Users__c;

        const data = newClientUserData.find((x: any) => x.UserID === this.loginUserDetail.id);
        if (data) {
          tempObj = {
            firstName: data.FirstName ? data.FirstName : data.firstName,
            id: data.id,
            lastName: data.LastName,
            photoUrl: data.photoUrl ? data.photoUrl : '/profilephoto/005/F',
            roleOfParticipant: data.roleOfParticipant,
            DefaultMuteStatus: data.DefaultMuteStatus,
            Useremail: data.Useremail ? data.Useremail : data.Email,
            nickName: data.Name ? data.Name : data.Username,
          };
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (!this.taggedTeamMembersToDisplay.find((x) => x.id === tempObj.id)) {
          this.taggedTeamMembersToDisplay.push(tempObj);
        }

        this.add.emit(tempObj);

        // eslint-disable-next-line unused-imports/no-unused-vars
        const listOfACCUsersNew = Object.assign(
          [],
          { ACTecexPeople: response.ACTecexPeople },
          { AllTecexPeople: response.AllTecexPeople },
          { SOTecexPeople: response.SOTecexPeople },
          { ClientUsers: response.ClientUsers },
          { ClientContacts: response.ClientContacts },
          { ThirdPartyPeople: response.ThirdPartyPeople }
        );

        this.listOfACCUsers.emit(listOfACCUsersNew);

        this.companyNameForClient = this.Client_Users__c[0].CompanyName;
        this.cdr.detectChanges();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public isAdded(teamMember: any): boolean {
    return this.participants.some((participant) => participant.id === teamMember.id);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public isRemovable(teamMember: any): boolean {
    const participant = this.participants.find((item) => item.id === teamMember.id);
    if (participant === undefined) {
      return false;
    }

    return participant.isNew;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onAdd(teamMember: any): void {
    this.add.emit(teamMember);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onRemove(teamMember: any): void {
    const tempData = this.internal_Users__c_New?.find((internalUser) => internalUser.id === teamMember.id);
    if (tempData) {
      this.Internal_Users__c = this.internal_Users__c_New;
      this.Internal_Users__c = this.sortInternalUserAlphabetically(this.Internal_Users__c);
    }

    const tempData1 = this.client_Users__c_New?.find((clientUser) => clientUser.id === teamMember.id);
    if (tempData1) {
      this.Client_Users__c = this.client_Users__c_New;
      this.Client_Users__c = this.sortClientUserAlphabetically(this.Client_Users__c, null);
    }

    const tempData3 = this.affiliate_Users__c_New?.find((affiliateUser) => affiliateUser.id === teamMember.id);
    if (tempData3) {
      this.Affiliate_Users__c = this.affiliate_Users__c_New;
      this.Affiliate_Users__c = this.sortThirdPartyUserAlphabetically(this.Affiliate_Users__c);
    }

    this.remove.emit(teamMember);
  }

  public onCloseSearchClick(): void {
    this.searchFieldControl.setValue('');
  }

  public onCancleClick(): void {
    this.cancelControl.setValue('');
  }

  private sortParticipantsAlphabetically(participants: Participant[]) {
    const sortedParticipants = [...participants];

    sortedParticipants.sort((a, b) => {
      if (a.firstName?.localeCompare(b.firstName) === 0) {
        return a.lastName?.localeCompare(b.lastName);
      }

      return a.firstName?.localeCompare(b.firstName);
    });

    return sortedParticipants;
  }

  private sortInternalUserAlphabetically(internalUsers: any): InternalUser[] {
    const sortedInternalUsers = [...internalUsers];

    sortedInternalUsers.sort((a, b) => {
      if (a.FirstName?.localeCompare(b.FirstName) === 0) {
        return a.FirstName?.localeCompare(b.FirstName);
      }

      return a.FirstName?.localeCompare(b.FirstName);
    });
    return sortedInternalUsers;
  }

  private sortClientUserAlphabetically(clientUser: any, contactUser: any) {
    let sortedClientUser = [...clientUser];
    if (contactUser) {
      sortedClientUser = [...clientUser, ...contactUser];
    }
    sortedClientUser.sort((a, b) => {
      if (a.firstName?.localeCompare(b.firstName) === 0) {
        return a.name?.localeCompare(b.name);
      }

      return a.firstName?.localeCompare(b.firstName);
    });

    return sortedClientUser;
  }

  private sortThirdPartyUserAlphabetically(thirdPartyUsers: any) {
    const sortedParticipants = [...thirdPartyUsers];

    // Sort : By Third Party Company name wise :
    sortedParticipants.sort((a, b) => {
      if (a.ThirdPartyCompany?.localeCompare(b.ThirdPartyCompany) === 0) {
        return a.name?.localeCompare(b.name);
      }

      return a.ThirdPartyCompany?.localeCompare(b.ThirdPartyCompany);
    });

    return sortedParticipants;
  }

  // For guest user toggle change :
  public guestUserToggleChange(value: boolean): void {
    this.guestUserToggle = !value;
  }
}
