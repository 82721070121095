import { ActiveInactive, Address } from '@tecex-api/data';
import { AddressType } from '../enums/address-type.enum';
import { AddressCardAddressVM } from '../interfaces/address/address.vm';

export const mapAddress = (addressType: AddressType, address: Address): AddressCardAddressVM => ({
  id: address.Id,
  tag: address.Name,
  companyName: address.CompanyName__c,
  contactPerson: address.Contact_Full_Name__c,
  email: address.Contact_Email__c,
  phone: address.Contact_Phone_Number__c,
  additionalPhone: address.AdditionalContactNumber__c,
  country: address.All_Countries__c,
  state: address.Province__c,
  city: address.City__c,
  streetAddress: address.Address__c,
  streetAddressTwo: address.Address2__c,
  streetAddressThree: address.Address3__c,
  zip: address.Postal_Code__c,
  comment: address.Comments__c,
  isDefault: addressType === AddressType.Pickup ? address.Default__c : false,
  isActive: address.Address_Status__c === ActiveInactive.ACTIVE,
  pickupPreference: address.Pickup_Preference__c,
});
