import { Inject, Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CasesDataService,
  Configuration,
  FeedsonCaseItem,
  MessageSegment,
  ProfileDataService,
  SendMessageDataService,
  SendMessageRequest,
  SendMessageRequestBody,
  SendReplyRequest,
  UsersDetailsItem,
} from '@tecex-api/data';
import findLast from 'lodash/findLast';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { forkJoin, interval, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mapTo, pairwise, startWith, switchMap, tap } from 'rxjs/operators';
import { CONFIG_TOKEN } from '../../../config/config.token';
import { GlobalConfig } from '../../../config/global-config.interface';
import { sortByDate } from '../../../helpers/sort-by-date.helper';
import { MessagePayload } from '../../../interfaces/messages/message-payload.interface';
import { MessageThreadDetails } from '../../../interfaces/messages/message-thread-details.interface';
import { Participant } from '../../../interfaces/participant.interface';
import { TeamMemberLists } from '../../../interfaces/team-member-lists.interface';
import { User } from '../../../interfaces/user.interface';
import { AuthService } from '../../../services/auth.service';
import { TeamMemberService } from '../../../services/team-member.service';
import { ToastMessageType } from '../../toast-message/toast-message-type.enum';
import { ToastMessageService } from '../../toast-message/toast-message.service';
import { TeamMemberListType } from '../enums/team-member-list-type.enum';
import { TokenConfigService } from '../../../services/token-config.service';

const MESSAGE_POLL_INTERVAL = 10_000;

@Injectable()
export abstract class MessageService {
  constructor(
    private readonly ngZone: NgZone,
    protected readonly authService: AuthService,
    protected readonly profileDataService: ProfileDataService,
    protected readonly casesDataService: CasesDataService,
    private readonly sendMessageDataService: SendMessageDataService,
    private readonly teamMemberService: TeamMemberService,
    private readonly toastMessageService: ToastMessageService,
    private readonly translateService: TranslateService,
    private readonly tokenConfigService: TokenConfigService,
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig
  ) {
    this.sendMessageDataService.configuration = new Configuration({
      ...this.sendMessageDataService.configuration,
      basePath: this.config.sendMessageApiBaseUrl,
    });

    this.tokenConfigService.getTokens$().subscribe((tokens) => {
      this.communityId = tokens.communityId;
    });
  }

  public abstract createThread$(messageThreadDetailsPayload: MessageThreadDetails): Observable<MessageThreadDetails>;

  public abstract getThread$(id: string, initial?: boolean): Observable<MessageThreadDetails>;

  public abstract addParticipants$(id: string, participants: Participant[]): Observable<void>;

  public communityId = '';

  public pollThread$(messageThread: MessageThreadDetails): Observable<MessageThreadDetails> {
    return this.ngZone.runOutsideAngular(() =>
      interval(MESSAGE_POLL_INTERVAL).pipe(
        switchMap(() => this.getThread$(messageThread.id)),
        startWith(messageThread),
        pairwise(),
        filter(([previousMessageThread, loadedMessageThread]) => {
          const previousLastMessage = previousMessageThread.messages.at(-1);
          const currentLastMessage = loadedMessageThread.messages.at(-1);
          return previousLastMessage?.id !== currentLastMessage?.id;
        }),
        map(([_, loadedMessageThread]) => this.ngZone.run(() => loadedMessageThread))
      )
    );
  }

  public getTeamMembers$(shipmentOrderId?: string, listType?: TeamMemberListType): Observable<TeamMemberLists> {
    return this.teamMemberService.getTeamMembers$(shipmentOrderId, listType);
  }

  public sendMessage$(messageThreadDetails: MessageThreadDetails, teamMembers: Participant[], message: MessagePayload): Observable<void> {
    const lastFeedItem = findLast(messageThreadDetails.messages, (item) => !item.isReply);

    const allParticipatingTeamMembers = teamMembers.filter((teamMember) =>
      [].concat(messageThreadDetails.participants, message.participants).some((participant) => participant.id === teamMember.id)
    );

    const outOfOfficeTeamMembers = allParticipatingTeamMembers.filter((teamMember) => teamMember.isOutOfOffice);
    const newStandByTeamMembers = teamMembers.filter(
      (teamMember) =>
        outOfOfficeTeamMembers.some((participant) => participant.standByPersonId === teamMember.id) &&
        allParticipatingTeamMembers.every((participant) => participant.id !== teamMember.id)
    );

    const allNewParticipants = [].concat(message.participants, newStandByTeamMembers);

    return this.authService.getUser$().pipe(
      first(),
      switchMap((user) => {
        const files$ = message.attachments ? this.uploadAttachment$(user, message.attachments) : of([]);

        return files$.pipe(
          switchMap((fileIds) =>
            allNewParticipants.length === 0
              ? of(fileIds)
              : this.addParticipants$(messageThreadDetails.id, allNewParticipants).pipe(mapTo(fileIds))
          ),
          switchMap((fileIds) => {
            let body = this.constructMessageBody(teamMembers, message.body);
            body = this.mentionParticipants(body, message.participants);
            body = this.mentionStandByTeamMembers(body, newStandByTeamMembers);

            if (isNil(lastFeedItem)) {
              const payload: SendMessageRequest = {
                feedElementType: 'FeedItem',
                visibility: 'AllUsers',
                subjectId: messageThreadDetails.id,
                body,
                capabilities:
                  fileIds.length === 0
                    ? undefined
                    : {
                        files: {
                          items: fileIds.map((id) => ({ id })),
                        },
                      },
              };

              return this.sendMessageDataService.sendMessage(this.communityId, payload).pipe(mapTo(undefined));
            } else {
              const payload: SendReplyRequest = {
                body,
                capabilities:
                  fileIds.length === 0
                    ? undefined
                    : {
                        content: {
                          contentDocumentId: fileIds[0],
                        },
                      },
              };

              return this.sendMessageDataService.sendReply(this.communityId, lastFeedItem.id, payload).pipe(mapTo(undefined));
            }
          })
        );
      }),
      tap(() => {
        if (outOfOfficeTeamMembers.length === 0) {
          return;
        }
        const toastMessage = outOfOfficeTeamMembers
          .filter((teamMember) => newStandByTeamMembers.some((standByTeamMember) => standByTeamMember.id === teamMember.standByPersonId))
          .reduce(
            (accToastMessage, teamMember) =>
              `${accToastMessage}${this.translateService.instant('MESSAGES.OUT_OFFICE_PARTICIPANT', {
                firstName: teamMember.firstName,
                lastName: teamMember.lastName,
                outOfOfficeText: teamMember.outOfOfficeText,
              })}`,
            ''
          );
        this.toastMessageService.open(toastMessage, { type: ToastMessageType.Info });
      })
    );
  }

  protected getReplies$(feedItems: FeedsonCaseItem[]): Observable<FeedsonCaseItem[]> {
    if (isNil(feedItems)) {
      return of([]);
    }

    const feedItemsWithReplies = feedItems.filter((feedItem) => !isNil(feedItem.CommentCount) && feedItem.CommentCount > 0);

    if (feedItemsWithReplies.length === 0) {
      return of(feedItems);
    }

    return this.authService.getUser$().pipe(
      switchMap((user) =>
        forkJoin(
          feedItemsWithReplies.map((feedItem) =>
            this.casesDataService.getCaseFeedReplies({
              Accesstoken: user.accessToken,
              CasefeedID: feedItem.Id,
            })
          )
        )
      ),
      map((replies) =>
        feedItems
          .concat(
            flatten(replies).map<FeedsonCaseItem>((reply) => ({
              Id: reply.Id,
              ParentId: reply.FeedItemId,
              InsertedById: reply.CreatedbyID,
              Body: reply.CommentBody,
              createddate: reply.createddate,
              ContentDocId: reply.ContentDocumentDetails,
            }))
          )
          .sort((a, b) => sortByDate(b.createddate, a.createddate))
      )
    );
  }

  private uploadAttachment$(user: User, files: { name: string; body: string }[]): Observable<string[]> {
    return forkJoin(
      files.map((item) =>
        this.casesDataService.attachCaseDocuments({
          Accesstoken: user.accessToken,
          Atts: [
            {
              filename: item.name,
              filebody: item.body,
            },
          ],
        })
      )
    ).pipe(map(([response]) => response.map((item) => item.ContentDocumentId)));
  }

  private constructMessageBody(teamMembers: Participant[], body: string): SendMessageRequestBody {
    const mentions = teamMembers.reduce<{ id: string; start: number; end: number }[]>((accumulator, teamMember) => {
      const regex = new RegExp(`@${teamMember.firstName} ${teamMember.lastName}`, 'g');

      let match: RegExpExecArray;
      while ((match = regex.exec(body)) !== null) {
        const end = match.index + match[0].length;

        const mentionAtSamePosition = accumulator.find((item) => item.start === match.index);
        if (mentionAtSamePosition) {
          if (mentionAtSamePosition.end > end) {
            return accumulator;
          }

          accumulator = accumulator.filter((item) => item !== mentionAtSamePosition);
        }

        accumulator = accumulator.concat({ id: teamMember.id, start: match.index, end });
      }

      return accumulator;
    }, []);

    if (mentions.length === 0) {
      return {
        messageSegments: [
          {
            type: 'Text',
            text: body.replace('\n', '<br>'),
          },
        ],
      };
    }

    return {
      messageSegments: mentions.reduce<MessageSegment[]>((messageSegments, mention, index) => {
        const previousMention = mentions[index - 1];

        if (mention.start !== 0) {
          const preTextStart = previousMention?.end || 0;
          const preTextEnd = mention.start;

          messageSegments = messageSegments.concat({
            type: 'Text',
            text: body.slice(preTextStart, preTextEnd),
          });
        }

        messageSegments = messageSegments.concat({
          type: 'Mention',
          id: mention.id,
        });

        const isLastMention = index === mentions.length - 1;
        if (isLastMention) {
          messageSegments = messageSegments.concat({
            type: 'Text',
            text: body.slice(mention.end),
          });
        }

        return messageSegments;
      }, []),
    };
  }

  private mentionParticipants(body: SendMessageRequestBody, newParticipants: Participant[]): SendMessageRequestBody {
    if (newParticipants.length === 0) {
      return body;
    }

    // Filter out possible mention duplicates from the text.
    const participantsToMention = newParticipants.filter((newParticipant) =>
      body.messageSegments.every((messageSegment) => messageSegment.type !== 'Mention' || (messageSegment as any).id !== newParticipant.id)
    );
    if (participantsToMention.length === 0) {
      return body;
    }

    return {
      ...body,
      messageSegments: [
        ...body.messageSegments,
        ...flatten(
          participantsToMention.map((participant) => [
            {
              type: 'Text',
              text: ' ',
            },
            {
              type: 'Mention',
              id: participant.id,
            },
          ])
        ),
      ],
    };
  }

  private mentionStandByTeamMembers(body: SendMessageRequestBody, standByTeamMembers: Participant[]): SendMessageRequestBody {
    if (standByTeamMembers.length === 0) {
      return body;
    }

    const newMessageSegments = standByTeamMembers.reduce<MessageSegment[]>(
      (messageSegments, participant, index) => [
        ...messageSegments,
        {
          type: 'Text',
          text: index === 0 ? '' : ' ',
        },
        {
          type: 'Mention',
          id: participant.id,
        },
      ],
      [
        {
          type: 'Text',
          text: '<br><br>',
        },
      ]
    );

    newMessageSegments.push({
      type: 'Text',
      text: ` ${this.translateService.instant('MESSAGES.AUTO_TAGGED', { count: standByTeamMembers.length })}`,
    });

    return {
      ...body,
      messageSegments: body.messageSegments.concat(newMessageSegments),
    };
  }

  protected getUsersFromMessages$(messages: FeedsonCaseItem[]): Observable<UsersDetailsItem[]> {
    if (!messages?.length) {
      return of([]);
    }

    // We need to collect all the unique user ids from the messages,
    // so we can do another request to get the profile picture and name for them.
    const userIds = [
      ...new Set(messages.filter((item) => item.Body !== null || !isEmpty(item.ContentDocId)).map((item) => item.InsertedById)),
    ];

    if (userIds.length === 0) {
      return of([]);
    }

    return this.authService
      .getUser$()
      .pipe(
        switchMap((user) =>
          this.profileDataService.getUsersDetails({
            Accesstoken: user.accessToken,
            UserIds: userIds.map((userId) => ({ UserId: userId })),
          })
        )
      )
      .pipe(catchError(() => of([])));
  }

  protected updateClientViewedTime$(id: string): Observable<void> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.casesDataService.updateClientViewedTime({
          Accesstoken: user.accessToken,
          RecordID: id,
        })
      ),
      catchError(() => of()),
      mapTo(undefined)
    );
  }
}
