<div class="dialog-container">
  <div class="d-flex header align-items-center">
    <button type="button" class="color-secondary mr-2" mat-icon-button (click)="onBackIconClick()">
      <svg-icon key="arrow-left"></svg-icon>
    </button>
    <h5>{{ (isInEdit ? 'ADDRESS.EDIT_ADDRESS' : 'ADDRESS.NEW_ADDRESS') | translate }}</h5>
  </div>
  <div class="content">
    <div *ngIf="isLoading" class="d-flex h-100 justify-content-center align-items-center">
      <app-loading-indicator></app-loading-indicator>
    </div>
    <div [hidden]="isLoading">
      <div class="card-container mb-3 pt-3 px-3 pb-2">
        <app-form-control class="col-10 px-0" [error]="tagError$ | async">
          <span appFormControlLabel>{{ 'ADDRESS.ADDRESS_TAG' | translate }}</span>
          <svg-icon key="id" appFormControlIcon></svg-icon>
          <input [formControl]="tagControl" type="text" class="form-control-input" appFormControlInput />
          <label *ngIf="shouldShowIsDefaultControl" appFormControlHint class="d-flex align-items-center pt-2">
            <mat-checkbox [formControl]="isDefaultControl">
              <span class="text-tiny-bold color-text-primary ml-2">{{ 'ADDRESS.SET_DEFAULT' | translate }}</span>
            </mat-checkbox>
          </label>
        </app-form-control>
        <app-form-control *ngIf="showPickupPreference" class="col-10 px-0 mt-40" [error]="pickupPreferenceError$ | async">
          <div class="icon-placeholder" appFormControlIcon></div>
          <span appFormControlLabel>{{ 'ADDRESS.PICKUP_PREFERENCE' | translate }}</span>
          <app-select
            [formControl]="pickupPreferenceControl"
            [data]="pickupPreferenceData"
            appFormControlInput
            class="form-control-input"
          ></app-select>
        </app-form-control>
        <app-form-control class="col-10 px-0" [ngClass]="showPickupPreference ? 'mt-3' : 'mt-40'" [error]="companyNameError$ | async">
          <span appFormControlLabel>{{ 'ADDRESS.COMPANY_NAME' | translate }}</span>
          <svg-icon key="company" appFormControlIcon></svg-icon>
          <input [formControl]="companyNameControl" type="text" class="form-control-input" appFormControlInput />
        </app-form-control>
      </div>
      <div class="card-container mb-3 pt-3 px-3 pb-2">
        <app-form-control class="col-10 px-0" [error]="streetAddressError$ | async">
          <span appFormControlLabel>{{ 'ADDRESS.STREET_ADDRESS' | translate }}</span>
          <svg-icon key="location" appFormControlIcon></svg-icon>
          <input
            appFormControlInput
            appGoogleMapAutocomplete
            type="text"
            class="form-control-input"
            [formControl]="streetAddressControl"
            [country]="countryControl.value?.value"
            (addressSelect)="onAddressSelect($event)"
          />
        </app-form-control>
        <app-form-control class="col-10 px-0" [error]="streetAddressTwoError$ | async">
          <span appFormControlLabel>{{ 'ADDRESS.ADDRESS_LINE_2' | translate }}</span>
          <div class="icon-placeholder" appFormControlIcon></div>
          <input appFormControlInput type="text" class="form-control-input" [formControl]="streetAddressTwoControl" />
        </app-form-control>
        <app-form-control class="col-10 px-0" [error]="streetAddressThreeError$ | async">
          <span appFormControlLabel>{{ 'ADDRESS.ADDRESS_LINE_3' | translate }}</span>
          <div class="icon-placeholder" appFormControlIcon></div>
          <input appFormControlInput type="text" class="form-control-input" [formControl]="streetAddressThreeControl" />
        </app-form-control>
        <div class="d-flex col-10 px-0 mt-3">
          <app-form-control class="col-6 pl-0" [error]="cityError$ | async">
            <span appFormControlLabel>{{ 'ADDRESS.CITY' | translate }}</span>
            <div class="icon-placeholder" appFormControlIcon></div>
            <input [formControl]="cityControl" type="text" class="form-control-input" appFormControlInput />
          </app-form-control>
          <app-form-control class="col-6 pr-0">
            <span appFormControlLabel>{{ 'ADDRESS.PROVINCE' | translate }}</span>
            <div class="icon-placeholder" appFormControlIcon></div>
            <input [formControl]="stateControl" type="text" class="form-control-input" appFormControlInput />
          </app-form-control>
        </div>
        <div class="d-flex col-10 px-0 mt-3">
          <app-form-control class="col-6 pl-0" [error]="countryError$ | async">
            <div class="icon-placeholder" appFormControlIcon></div>
            <span [class.color-text-tertiary]="countryControl.disabled" appFormControlLabel>{{ 'ADDRESS.COUNTRY' | translate }}</span>
            <app-dropdown [formControl]="countryControl" [data]="countries" appFormControlInput class="form-control-input"></app-dropdown>
          </app-form-control>
          <app-form-control class="col-6 pr-0" [error]="zipError$ | async">
            <span appFormControlLabel>{{ 'ADDRESS.POSTAL_OR_ZIP' | translate }}</span>
            <div class="icon-placeholder" appFormControlIcon></div>
            <input [formControl]="zipControl" type="text" class="form-control-input" appFormControlInput />
          </app-form-control>
        </div>
      </div>
      <div class="card-container pt-3 px-3 pb-2">
        <app-form-control class="col-10 px-0" [error]="contactPersonError$ | async">
          <span appFormControlLabel>{{ 'ADDRESS.CONTACT_PERSON' | translate }}</span>
          <svg-icon key="id" appFormControlIcon></svg-icon>
          <input [formControl]="contactPersonControl" type="text" class="form-control-input" appFormControlInput />
        </app-form-control>
        <app-form-control class="col-10 px-0 mt-3" [error]="emailError$ | async">
          <span appFormControlLabel>{{ 'ADDRESS.EMAIL' | translate }}</span>
          <svg-icon key="mail" appFormControlIcon></svg-icon>
          <input [formControl]="emailControl" type="text" class="form-control-input" appFormControlInput />
        </app-form-control>
        <div class="d-flex col-10 px-0 mt-3">
          <app-form-control class="col-6 pl-0" [error]="phoneError$ | async">
            <span appFormControlLabel>{{ 'ADDRESS.PHONE_NUMBER' | translate }}</span>
            <svg-icon key="phone" appFormControlIcon></svg-icon>
            <input appPhoneMask [formControl]="phoneControl" type="text" class="form-control-input" appFormControlInput />
          </app-form-control>
          <app-form-control class="col-6 pr-0" [error]="additionalPhoneError$ | async">
            <span appFormControlLabel>{{ 'ADDRESS.ADDITIONAL_PHONE_NUMBER' | translate }}</span>
            <svg-icon key="phone" appFormControlIcon></svg-icon>
            <input appPhoneMask [formControl]="additionalPhoneControl" type="text" class="form-control-input" appFormControlInput />
          </app-form-control>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex justify-content-end align-items-center button-container py-3">
      <button mat-button class="secondary-button mr-3" (click)="onCancelClick()">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
      <button
        mat-button
        *ngIf="isInEdit"
        class="delete-button text-link sm-dark d-flex align-items-center"
        (click)="onDeleteAddressClick()"
      >
        <div class="d-flex align-items-center">
          <svg-icon key="bin" class="mr-1"></svg-icon>
          {{ 'ADDRESS.DELETE_ADDRESS' | translate }}
        </div>
      </button>
      <button type="button" mat-button class="primary-button" [disabled]="addressControl.invalid" (click)="onSaveAddressClick()">
        {{ 'ADDRESS.SAVE_ADDRESS' | translate }}
      </button>
    </div>
  </div>
</div>
