<div
  class="selection-container"
  [class.error]="hasError$ | async"
  [class.disabled]="isDisabled"
  [class.highlighted]="isHighlighted && (hasError$ | async) === false"
>
  <div
    *ngFor="let item of items"
    class="text-tiny-bold select"
    [class.color-text-tertiary]="item !== selectedItem"
    [class.active]="item === selectedItem"
    (click)="onItemClick(item)"
  >
    <svg-icon key="check" class="icon"></svg-icon>
    <span class="label">{{ getLabel(item) }}</span>
  </div>
</div>
